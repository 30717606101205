import { FormKit, FormKitMessages } from "@formkit/vue"
import { TabDef, Tabs } from "src/components/UserInterface/Tabs"
import { assertTruthy, FK_nodeRef, forceCheckedIndexedAccess, nextOpaqueVueKey, parseIntOr, parseIntOrFail, requireNonNull, sortBy, TailwindBreakpoint, UiOption, UiOptions, useWindowSize, vReqT } from "src/helpers/utils"
import { Guid, Integerlike, SeasonTriple } from "src/interfaces/InleagueApiV1"
import { Ref, computed, defineComponent, nextTick, onMounted, ref, watch } from "vue"
import { coachBlurbForTeamName, CompDivAuthZ, CreateFieldBlockForm, CreateGameForm, EditFieldBlockForm, EditGameForm, GameCalendarUiElement, k_POOL_ALL, k_TEAM_TBD } from "./GameScheduler.shared"
import dayjs from "dayjs"
import { Btn2, btn2_defaultEnabledClasses, btn2_disabledButLooksEnabledClasses, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise"
import { ANY, authZ_perAction } from "./R_GameSchedulerCalendar.route"
import { LayoutNode } from "./CalendarLayout"
import { GameForGameSchedulerView, GetCompDependentCrudGameOptionsResponse, GetCompDivSeasonDependentCrudGameOptionsResponse } from "src/composables/InleagueApiV1.GameScheduler"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"
import { Round } from "src/composables/InleagueApiV1.Matchmaker"
import { SoccerBall } from "src/components/SVGs"
import { Client } from "src/store/Client"
import { dayjsOr } from "src/helpers/formatDate"

const roundsEmits = {
  "update:rounds_seasonUID": (_seasonUID: Guid) => true,
  "update:rounds_competitionUID": (_competitionUID: Guid) => true,
  "update:rounds_divID": (_divID: Guid) => true,
  "update:rounds_poolID": (_poolID: "ALL" | Integerlike) => true,
  "update:rounds_roundID": (_roundID: Guid) => true,
  "createRound": (_: {competitionUID: Guid, seasonUID: Guid, divID: Guid, poolID: "ALL" | Integerlike}) => true,
  bulkAssignRounds: () => true,
} as const

const startStopBulkSelectEmits = {
  startBulkSelect: (_: "generic" | "rounds") => true,
  cancelBulkSelect: () => true,
} as const

export const Actions = defineComponent({
  props: {
    /** null if user doesn't have permission to create games */
    createGameForm: vReqT<CreateGameForm | null>(),
    /** null if user doesn't have permission to create field blocks */
    createFieldBlockForm: vReqT<CreateFieldBlockForm | null>(),
    competitionOptions: vReqT<UiOption[]>(),
    fieldOptions: vReqT<UiOption[]>(),
    compDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>>(),
    // we thought we could get away with creating the options here, but really this is trending towards
    // needing to be a straight-up UiOption[], or something that contains one.
    compDivSeasonDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>>(),
    rounds_compDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>>(),
    rounds_compDivSeasonDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>>(),
    rounds_selections: vReqT<{season: null | SeasonTriple, competitionUID: Guid | "", divID: Guid | "", poolID: "ALL" | Integerlike, roundID: Guid | ""}>(),
    clearGameAssignmentsState: vReqT<ClearGameTeamAssignmentsState>(),
    selectedTabId: vReqT<Ref<TabID>>(),
    bulkSelectMode: vReqT<null | {type: "generic" | "rounds", selectedNodes: readonly LayoutNode<GameCalendarUiElement>[]}>(),
    rounds: vReqT<ReactiveReifiedPromise<Round[]>>(),
  },
  emits: {
    createGames: () => true,
    createBlocks: () => true,
    "update:seasonUID": (_seasonUID: Guid) => true,
    "update:competitionUID": (_competitionUID: Guid) => true,
    "update:divID": (_divID: Guid) => true,
    deleteBulkSelected: () => true,
    bulkUpdateFields: (_fieldUID: Guid) => true,
    ...startStopBulkSelectEmits,
    ...roundsEmits,
  },
  setup(props, {emit}) {
    const {
      timeStartHourOptions,
      timeStartMinuteOptions,
      gameLengthMinutesOptions,
      fieldBlockLengthOptions,
    } = commonTimeOptions()

    const bulk_selectedFieldUID = ref<"" | Guid>("")
    watch(() => props.fieldOptions, () => {
      if (!props.fieldOptions.find(opt => opt.value /*not strict*/ == bulk_selectedFieldUID.value)) {
        bulk_selectedFieldUID.value = forceCheckedIndexedAccess(props.fieldOptions, 0)?.value || ""
      }
    }, {immediate: true})

    const howManyGamesToCreateOptions = (() => {
      const result : UiOption[] = []
      for (let i = 1; i <= 10; i++) {
        result.push({label: i.toString(), value: i.toString()})
      }
      return result;
    })()

    const repeatWeeksOptions = (() => {
      // repeat 0 weeks === "just create this week"
      // repeat 1 weeks === "create this week and next week"
      // ...
      const result : UiOption[] = [{label: "Start date only", value: "0"}]
      for (let i = 1; i <= 13; i++) {
        // "repeat for 2 weeks" means "create on start date and 1 week after start date"
        result.push({label: `${i+1} weeks`, value: i.toString()})
      }
      return result;
    })()


    const seasonUID = {
      get value() { return props.createGameForm?.season.seasonUID || "" },
      set value(v) { emit("update:seasonUID", v) }
    }

    const competitionUID = {
      get value() { return props.createGameForm?.competitionUID || "" },
      set value(v) { emit("update:competitionUID", v) }
    }

    const divID = {
      get value() { return props.createGameForm?.divID || "" },
      set value(v) { emit("update:divID", v) }
    }

    const createGames_smLayout = cssNamedGridAreas(
      "datetime datetime",
      "compdiv  compdiv",
      "radio    radio",
      "comment  comment",
    )

    const createGames_mdLayout = cssNamedGridAreas(
      "datetime datetime",
      "compdiv  radio",
      "comment  comment",
    )

    const createGames_lgLayout = cssNamedGridAreas(
      "datetime compdiv radio",
      "comment  comment comment",
    )

    const createFieldBlocks_smLayout = cssNamedGridAreas(
      "date time",
      "comment comment",
    )

    const createFieldBlocks_mdLayout = createFieldBlocks_smLayout;

    const createFieldBlocks_lgLayout = cssNamedGridAreas(
      "date time comment",
    )

    const windowSize = useWindowSize()
    const createGames_gridLayout = computed(() => {
      return windowSize.width < TailwindBreakpoint.lg ? createGames_smLayout
        : windowSize.width < TailwindBreakpoint["2xl"] ? createGames_mdLayout
        : createGames_lgLayout;
    })
    const createFieldBlock_gridLayout = computed(() => {
      return windowSize.width < TailwindBreakpoint.lg ? createFieldBlocks_smLayout
        : windowSize.width < TailwindBreakpoint["2xl"] ? createFieldBlocks_mdLayout
        : createFieldBlocks_lgLayout;
    })

    const seasonOptions = computed(() => {
      return maybeGetSeasonOptions(props.compDependentOptions)
    })

    const divisionOptions = computed(() => {
      return maybeGetDivisionOptions(props.compDependentOptions)
    })

    const poolOptions = computed(() => {
      return maybeGetPoolOptions(props.compDivSeasonDependentOptions)
    })

    const createGamesTab = () => {
      if (!props.createGameForm) {
        return null
      }
      return (
        <div data-test="createGames">
          <FormKit type="form" onSubmit={() => emit("createGames")} actions={false}>
            <div style={`max-wdith:2000px; display:grid; grid-template-areas:${createGames_gridLayout.value}; grid-gap:.5em 1em; --fk-padding-input:${fkPaddingInput};`} class="text-sm">
              <div style="grid-area: datetime;">
                <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap:.5em; align-items:center;">
                  <div class="font-medium text-sm">Date</div>
                  <div class="max-w-64">
                    <FormKit type="date" v-model={props.createGameForm.startDate} data-test="startDate"/>
                  </div>
                  <div class="font-medium text-sm">Time</div>
                  <div class="flex gap-2 max-w-64">
                    <div class="flex-grow"><FormKit type="select" v-model={props.createGameForm.startHour} options={timeStartHourOptions} data-test="startHour"/></div>
                    <div class="flex-grow"><FormKit type="select" v-model={props.createGameForm.startMinute} options={timeStartMinuteOptions} data-test="startMinute"/></div>
                  </div>
                  <div style="grid-column:1/-1;">
                    <div style="display:grid; grid-template-columns: min-content 1fr; grid-gap: .5em 1em;">
                      <span class="font-medium flex justify-end">Create</span>
                      <div class="flex items-center gap-2 justify-start">
                        <span class="w-1/3"><FormKit type="select" v-model={props.createGameForm.slotCount} options={howManyGamesToCreateOptions} data-test="slotCount"/></span>
                        <span class="">game slot(s)</span>
                      </div>
                      <span class="flex justify-end">of</span>
                      <div class="flex items-center justify-start gap-2">
                        <span class="w-1/3"><FormKit type="select" v-model={props.createGameForm.slotGameDurationMinutes} options={gameLengthMinutesOptions} data-test="slotGameDurationMinutes"/></span>
                        <span class="">minutes</span>
                      </div>
                    </div>
                  </div>
                  <div style="grid-column:1/-1;" class="flex items-center gap-2" >
                    <span class="font-medium">Repeat this schedule for</span>
                    <span class="flex-grow"><FormKit type="select" v-model={props.createGameForm.repeatWeeks} options={repeatWeeksOptions} data-test="repeatWeeks"/></span>
                  </div>
                </div>
              </div>

              <div style="grid-area:comment;">
                <div style="--fk-max-width-input: none;">
                  <div class="font-medium text-sm">Comments</div>
                  <FormKit type="textarea" v-model={props.createGameForm.comment}/>
                </div>
              </div>

              <div style="grid-area: compdiv">
                <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap: .5em 1em; align-items:start;">
                  <div class="font-medium text-sm">Program</div>
                  <FormKit type="select" options={props.competitionOptions} v-model={competitionUID.value} validation={[["required"]]} validationLabel="Program" data-test="competitionUID"/>
                  <div class="font-medium text-sm">Season</div>
                  <FormKit type="select" disabled={seasonOptions.value.disabled} options={seasonOptions.value.options} v-model={seasonUID.value} validation={[["required"]]} validationLabel="Season" data-test="seasonUID"/>
                  <div class="font-medium text-sm">Division</div>
                  <FormKit type="select" disabled={divisionOptions.value.disabled} options={divisionOptions.value.options} v-model={divID.value} validation={[["required"]]} validationLabel="Division" data-test="divID"/>
                  <div class="font-medium text-sm">Pool</div>
                  <FormKit type="select" disabled={poolOptions.value.disabled} options={poolOptions.value.options} v-model={props.createGameForm.poolID} validation={[["required"]]} validationLabel="Pool" data-test="poolID"/>
                  <div class="font-medium text-sm">Field</div>
                  <FormKit type="select" options={props.fieldOptions} v-model={props.createGameForm.fieldUID} validation={[["required"]]} validationLabel="Field" data-test="fieldUID"/>
                </div>
              </div>

              <div style="grid-area: radio">
                <BooleanRadioOptions form={props.createGameForm}/>
              </div>
            </div>
            <div class="flex">
              <Btn2 type="submit" class="mt-2 px-1 py-2">Create Slots</Btn2>
            </div>
          </FormKit>
        </div>
      )
    }

    const createFieldBlocksTab = () => {
      if (!props.createFieldBlockForm) {
        return null
      }
      return (
        <div>
          <FormKit type="form" actions={false} onSubmit={() => emit("createBlocks")}>
            <div class="text-sm" style={`display:grid; grid-template-areas: ${createFieldBlock_gridLayout.value}; grid-gap:1em; --fk-max-width-input:none; --fk-padding-input:${fkPaddingInput};`}>
              <div style="grid-area:date;">
                <div class="text-sm font-medium">Date</div>
                <FormKit type="date" v-model={props.createFieldBlockForm.startDate}/>
                <div class="text-sm font-medium">Field</div>
                <FormKit type="select" options={props.fieldOptions} v-model={props.createFieldBlockForm.fieldUID}/>
                <div class="text-sm font-medium">Repeat</div>
                <FormKit type="select" options={repeatWeeksOptions} v-model={props.createFieldBlockForm.repeatWeeks}/>
              </div>

              <div style="grid-area:time;">
                <div class="flex gap-2 items-center">
                  <input type="checkbox" v-model={props.createFieldBlockForm.blockEntireDay}/>
                  <span>Block entire day</span>
                </div>
                <div style="display:grid; grid-template-columns: max-content max-content; grid-gap:1em; position:relative; padding:2px;">
                  <div>Time</div>
                  <div class="flex items-center gap-2">
                    <span class="flex-grow max-w-24">
                      <FormKit type="select"
                        options={props.createFieldBlockForm.blockEntireDay ? [{label: "12 am", value: ""}] : timeStartHourOptions}
                        disabled={props.createFieldBlockForm.blockEntireDay}
                        v-model={props.createFieldBlockForm.startHour}
                        />
                    </span>
                    <span>:</span>
                    <span class="flex-grow max-w-36">
                      <FormKit
                        type="select"
                        options={props.createFieldBlockForm.blockEntireDay ? [{label: "00", value: ""}] : timeStartMinuteOptions}
                        disabled={props.createFieldBlockForm.blockEntireDay}
                        v-model={props.createFieldBlockForm.startMinute}
                        />
                      </span>
                  </div>
                  <div>Length</div>
                  <span>
                    <FormKit
                      type="select"
                      options={props.createFieldBlockForm.blockEntireDay ? [{label: "24 hours", value: ""}] : fieldBlockLengthOptions}
                      disabled={props.createFieldBlockForm.blockEntireDay}
                      v-model={props.createFieldBlockForm.lengthMinutes}
                      />
                  </span>
                  {props.createFieldBlockForm.blockEntireDay
                    ? <div class="absolute top-0 left-0 h-full w-full bg-white opacity-50"></div>
                    : null
                  }

                </div>

              </div>

              <div style="grid-area:comment;">
                <div class="text-sm font-medium">Comments</div>
                <FormKit type="textarea" v-model={props.createFieldBlockForm.comment}/>
              </div>
            </div>
            <div class="flex mt-4">
              <Btn2 type="submit" class="mt-2 px-1 py-2">Block Time</Btn2>
            </div>
          </FormKit>
        </div>
      )
    }

    const bulkSelect = {
      get value() { return !!props.bulkSelectMode },
      set value(v) {
        if (v) {
          emit("startBulkSelect", "generic")
        }
        else {
          emit("cancelBulkSelect")
        }
      }
    }

    const bulkSelectTab = () => {
      return <div>
        <div class="flex items-center gap-2">
          <FormKit {...{type: "toggle" as any}} v-model={bulkSelect.value}/>
          Bulk Selected Mode
          {props.bulkSelectMode
            ? <div>{props.bulkSelectMode.selectedNodes.length} selected item{props.bulkSelectMode.selectedNodes.length === 1 ? "" : "s"}</div>
            : null
          }
        </div>
        <div class="relative">
          <div class="flex gap-3">
            <div class="border rounded-md my-2">
              <div class="px-2 py-1 bg-gray-200 text-sm">Bulk Delete</div>
              <div class="p-2">
                <Btn2 class="py-1 px-2" disabled={(props.bulkSelectMode?.selectedNodes ?? []).length === 0} onClick={() => emit("deleteBulkSelected")}>Delete selected</Btn2>
              </div>
            </div>
            <div class="border rounded-md my-2">
              <div class="px-2 py-1 bg-gray-200 text-sm">Bulk Update Field</div>
              <div class="p-2">
                <select v-model={bulk_selectedFieldUID.value} style="all: revert; display:block; padding:">
                  {props.fieldOptions.map(opt => <option value={opt.value}>{opt.label}</option>)}
                </select>
                <Btn2 class="mt-2 py-1 px-2" disabled={(props.bulkSelectMode?.selectedNodes ?? []).length === 0} onClick={() => emit("bulkUpdateFields", bulk_selectedFieldUID.value)}>Update</Btn2>
              </div>
            </div>
          </div>
          {!props.bulkSelectMode
            ? <div class="absolute top-0 left-0 w-full h-full bg-white opacity-75"></div>
            : null
          }
        </div>
      </div>
    }

    const fkNodeRef_clearGameTeamAssignmentsTabDateAck = FK_nodeRef()
    const clearGameTeamAssignmentsTab = () => {
      return (
        <div style={`--fk-padding-input:${fkPaddingInput};`} data-test="ClearGameTeamAssignments">
          <div>Remove team assignments from game slots for the specified competition, division, and date range.</div>
          <FormKit type="form" actions={false} onSubmit={() => props.clearGameAssignmentsState.submit()}>
            <div class="relative" style="padding:2px;">
              <div class="my-2">
                <div class="font-medium text-sm">Program</div>
                <FormKit type="select"
                  disabled={props.clearGameAssignmentsState.competitionOptions.disabled}
                  options={props.clearGameAssignmentsState.competitionOptions.options}
                  v-model={props.clearGameAssignmentsState.selectedCompetitionUID}
                  onChange={() => props.clearGameAssignmentsState.refreshDivisionOptions()}
                  validation={[["required"]]}
                  validationLabel="Program"
                  data-test="competitionUID"
                />
              </div>
              <div class="my-2">
                <div class="font-medium text-sm">Division</div>
                <FormKit type="select"
                  disabled={props.clearGameAssignmentsState.divisionOptions.disabled}
                  options={props.clearGameAssignmentsState.divisionOptions.options}
                  v-model={props.clearGameAssignmentsState.selectedDivID}
                  validation={[["required"]]}
                  validationLabel="Division"
                  data-test="divID"
                />
              </div>
              <div class="my-2">
                <div class="font-medium text-sm">Date From</div>
                <FormKit
                  type="date"
                  v-model={props.clearGameAssignmentsState.dateFromInclusive}
                  validation={[["required"]]}
                  validationLabel={"'Date From'"}
                  data-test="dateFromInclusive"
                />
              </div>
              <div class="my-2">
                <div class="font-medium text-sm">Date To</div>
                <FormKit
                  type="date"
                  v-model={props.clearGameAssignmentsState.dateToInclusive}
                  validation={[["required"]]}
                  validationLabel={"'Date To'"}
                  data-test="dateToInclusive"
                />
              </div>
              {props.clearGameAssignmentsState.busy
                ? <div class="absolute top-0 left-0 h-full w-full bg-white opacity-75">{/*busy overlay*/}</div>
                : null
              }
            </div>
            {dayjsOr(props.clearGameAssignmentsState.dateFromInclusive)?.isBefore(dayjs(), "day")
              ? <div class="ml-1 my-2">
                  <div class="flex items-center gap-2" style="--fk-margin-decorator:none;">
                    <FormKit type="checkbox" validation={[["accepted"]]} validationLabel="date from alert" ref={fkNodeRef_clearGameTeamAssignmentsTabDateAck}/>
                    <div class="text-sm">Acknowledge that the 'date from' is in the past.</div>
                  </div>
                  <FormKitMessages node={fkNodeRef_clearGameTeamAssignmentsTabDateAck.value?.node}/>
              </div>
              : null
              }
            <Btn2 type="submit" class="my-2 px-2 py-1" disabled={props.clearGameAssignmentsState.busy} disabledClasses={btn2_disabledButLooksEnabledClasses}>
              {props.clearGameAssignmentsState.busy
                ? <div class="flex items-center gap-2">
                  <SoccerBall color={Client.value.clientTheme.color} width="1.25em" height="1.25em" timeForOneRotation="2s"/>
                  Working on it...
                </div>
                : "Submit"}
            </Btn2>
          </FormKit>
        </div>
      )
    }

    const roundsTab = () => {
      return <Rounds
        competitionOptions={props.competitionOptions}
        rounds_compDependentOptions={props.rounds_compDependentOptions}
        rounds_compDivSeasonDependentOptions={props.rounds_compDivSeasonDependentOptions}
        rounds_selections={props.rounds_selections}
        selectedTabId={props.selectedTabId}
        bulkSelectMode={props.bulkSelectMode}
        rounds={props.rounds}
        onUpdate:rounds_seasonUID={(...args) => emit("update:rounds_seasonUID", ...args)}
        onUpdate:rounds_competitionUID={(...args) => emit("update:rounds_competitionUID", ...args)}
        onUpdate:rounds_divID={(...args) => emit("update:rounds_divID", ...args)}
        onUpdate:rounds_poolID={(...args) => emit("update:rounds_poolID", ...args)}
        onUpdate:rounds_roundID={(...args) => emit("update:rounds_roundID", ...args)}
        onCreateRound={(...args) => emit("createRound", ...args)}
        onBulkAssignRounds={(...args) => emit("bulkAssignRounds", ...args)}
        onStartBulkSelect={(...args) => emit("startBulkSelect", ...args)}
        onCancelBulkSelect={(...args) => emit("cancelBulkSelect", ...args)}

      />
    }

    const tabDefs = computed<(TabDef & {id: TabID})[]>(() => {
      const tabs : (TabDef & {id:TabID})[] = []

      const canCrudGames = authZ_perAction.canCrudGames({competitionUID: ANY, divID: ANY})
      const canEditTeams = authZ_perAction.canEditGameTeams({competitionUID: ANY, divID: ANY})

      if (canCrudGames) {
        tabs.push({
          id: TabID.createGames,
          "data-test": "createGamesTab",
          label: "Create Slots",
          render: createGamesTab,
        })
      }

      const canCrudFieldBlocks = authZ_perAction.canCrudFieldBlocks()

      if (canCrudFieldBlocks) {
        tabs.push({
          id: TabID.createFieldBlocks,
          label: "Block Time",
          render: createFieldBlocksTab,
        })
      }

      if (canCrudGames && canCrudFieldBlocks) {
        tabs.push({
          id: TabID.bulkSelect,
          label: "Bulk Select",
          render: bulkSelectTab
        })
      }

      if (canEditTeams) {
        tabs.push({
          id: TabID.clearGameTeamAssignments,
          "data-test": "clearGameTeamAssignmentsTab",
          label: "Clear Team Assignments",
          render: clearGameTeamAssignmentsTab,
        })
      }

      if (canCrudGames) {
        tabs.push({
          id: TabID.rounds,
          "data-test": "roundsTab",
          label: "Rounds",
          render: roundsTab,
        })
      }

      if (tabs.length === 0) {
        tabs.push({
          id: TabID.nil,
          label: "No actions available",
          render: () => <div>No actions currently available.</div>
        })
      }

      return tabs
    })

    const tabIdByIdx = computed(() => {
      const result : Record<number, TabID> = {}
      for (let i = 0; i < tabDefs.value.length; i++) {
        result[i] = tabDefs.value[i].id
      }
      return result
    })

    const tabIdxByID = computed(() => {
      const result : {[K in TabID]?: number} = {}
      for (let i = 0; i < tabDefs.value.length; i++) {
        const id = tabDefs.value[i].id
        result[id] = i
      }
      return result
    })

    return () => {
      return (
        <div class="il-webkit-select-with-text-sm-line-height-kludge">
          <Tabs
            onChangeSelectedIndex={idx => {
              props.selectedTabId.value = tabIdByIdx.value[idx]
              if (props.selectedTabId.value === TabID.bulkSelect) {
                emit("startBulkSelect", "generic")
              }
              else {
                emit("cancelBulkSelect")
              }
            }}
            selectedIndex={tabIdxByID.value[props.selectedTabId.value]}
            tabDefs={tabDefs.value}
          />
        </div>
      )
    }
  }
})

export interface ClearGameTeamAssignmentsState {
  readonly competitionOptions: UiOptions,
  readonly divisionOptions: UiOptions,
  busy: boolean,
  selectedCompetitionUID: "" | Guid,
  selectedDivID: "" | Guid,
  dateFromInclusive: "" | string,
  dateToInclusive: "" | string,
  readonly refreshDivisionOptions: () => void,
  readonly submit: () => Promise<void>
}

export const EditGame = defineComponent({
  props: {
    pristine: vReqT<GameForGameSchedulerView>(),
    editGameForm: vReqT<EditGameForm>(),
    competitionOptions: vReqT<UiOption[]>(),
    fieldOptions: vReqT<UiOption[]>(),
    compDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>>(),
    compDivDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>>(),
    authZ: vReqT<CompDivAuthZ>(),
    busyUpdating: vReqT<boolean>(),
  },
  emits: {
    updateGame: () => true,
    cancel: () => true,
    "update:seasonUID": (_seasonUID: Guid) => true,
    "update:competitionUID": (_competitionUID: Guid) => true,
    "update:divID": (_divID: Guid) => true,
  },
  setup(props, {emit}) {
    const {
      timeStartHourOptions,
      timeStartMinuteOptions,
      gameLengthMinutesOptions
    } = commonTimeOptions()

    const seasonUID = {
      get value() { return props.editGameForm.season.seasonUID || "" },
      set value(v) { emit("update:seasonUID", v) }
    }

    const competitionUID = {
      get value() { return props.editGameForm.competitionUID },
      set value(v) { emit("update:competitionUID", v) }
    }

    const divID = {
      get value() { return props.editGameForm.divID },
      set value(v) { emit("update:divID", v) }
    }

    const seasonOptions = computed(() => {
      return maybeGetSeasonOptions(props.compDependentOptions)
    })

    const divisionOptions = computed(() => {
      return maybeGetDivisionOptions(props.compDependentOptions)
    })

    const poolOptions = computed(() => {
      return maybeGetPoolOptions(props.compDivDependentOptions)
    })

    const teamOptions = computed(() => {
      if (props.compDivDependentOptions.underlying.status === "error") {
        throw props.compDivDependentOptions.underlying.error
      }
      else {
        return props.compDivDependentOptions.underlying.status === "resolved"
          ? [{label: "TBD", value: k_TEAM_TBD}, ...props.compDivDependentOptions.underlying.data.teams.map(v => {
            const coachLabel = coachBlurbForTeamName(v.coaches) || "No coaches for selected season"
            return {label: `${v.teamDesignation} (${coachLabel})`, value: v.teamID}
          })]
          : null;
      }
    });

    watch(() => teamOptions.value, () => {
      if (!teamOptions.value) {
        return
      }
      if (!teamOptions.value.find(opt => opt.value === props.editGameForm.homeTeamID)) {
        props.editGameForm.homeTeamID = k_TEAM_TBD
      }
      if (!teamOptions.value.find(opt => opt.value === props.editGameForm.visitorTeamID)) {
        props.editGameForm.visitorTeamID = k_TEAM_TBD
      }
    }, {immediate: true})

    const disableSaveButton = computed(() => {
      return !teamOptions.value || props.busyUpdating
    })

    const disableCancelButton = computed(() => {
      return props.busyUpdating
    })

    return () => {
      return (
        <div>
          <FormKit type="form" onSubmit={() => emit("updateGame")} actions={false}>
            <div style={`--fk-padding-input:${fkPaddingInput};`} class="text-sm flex flex-col gap-2 il-webkit-select-with-text-sm-line-height-kludge">
              <div>
                <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap:.5em; align-items:center;">
                  <div class="font-medium text-sm">Date</div>
                  <FormKit disabled={!props.authZ.canEditGameTimes} type="date" v-model={props.editGameForm.startDate}/>
                  <div class="font-medium text-sm">Time</div>
                  <div class="flex gap-2">
                    <div class="flex-grow"><FormKit disabled={!props.authZ.canEditGameTimes} type="select" v-model={props.editGameForm.startHour} options={timeStartHourOptions}/></div>
                    <span>:</span>
                    <div class="flex-grow"><FormKit disabled={!props.authZ.canEditGameTimes} type="select" v-model={props.editGameForm.startMinute} options={timeStartMinuteOptions}/></div>
                  </div>
                  <div class="font-medium text-sm">Game length</div>
                  <div class="flex items-center gap-2">
                      <div class="flex-grow"><FormKit disabled={!props.authZ.canEditGameTimes} type="select" v-model={props.editGameForm.gameDurationMinutes} options={gameLengthMinutesOptions}/></div>
                      <div>minutes</div>
                  </div>
                </div>
              </div>

              <div class="border-b"></div>

              <div>
                {/*max-width here -- <select>s can get pretty big if we have a team name + lots of coach names*/}
                <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap: .5em 1em; align-items:center; --fk-max-width-input:20em;">
                  <div class="font-medium text-sm">Home Team</div>
                  <FormKit disabled={!props.authZ.canEditGameTeams || !teamOptions.value} type="select" options={teamOptions.value ?? [{label: "Loading team options...", value: ""}]} v-model={props.editGameForm.homeTeamID}/>
                  <div class="font-medium text-sm">Visitor Team</div>
                  <FormKit disabled={!props.authZ.canEditGameTeams || !teamOptions.value} type="select" options={teamOptions.value ?? [{label: "Loading team options...", value: ""}]} v-model={props.editGameForm.visitorTeamID}/>
                </div>
              </div>

              <div class="border-b"></div>

              <div>
                <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap: .5em 1em; align-items:start;">
                  <div class="font-medium text-sm">Season</div>
                  <FormKit disabled={!props.authZ.canCrudGames || seasonOptions.value.disabled} type="select" options={seasonOptions.value.options} v-model={seasonUID.value} validation={[["required"]]} validationLabel="Season"/>
                  <div class="font-medium text-sm">Program</div>
                  <FormKit disabled={!props.authZ.canCrudGames} type="select" options={props.competitionOptions} v-model={competitionUID.value} validation={[["required"]]} validationLabel="Program"/>
                  <div class="font-medium text-sm">Division</div>
                  <FormKit disabled={!props.authZ.canCrudGames || divisionOptions.value.disabled} type="select" options={divisionOptions.value.options} v-model={divID.value} validation={[["required"]]} validationLabel="Division"/>
                  <div class="font-medium text-sm">Pool</div>
                  <FormKit type="select" disabled={!props.authZ.canCrudGames || poolOptions.value.disabled} options={poolOptions.value.options} v-model={props.editGameForm.poolID} validation={[["required"]]} validationLabel="Pool"/>
                  <div class="font-medium text-sm">Field</div>
                  <FormKit disabled={!props.authZ.canEditGameFields} type="select" options={props.fieldOptions} v-model={props.editGameForm.fieldUID} validation={[["required"]]} validationLabel="Field"/>
                </div>
              </div>

              <div class="border-b"></div>

              <div class="relative">
                <BooleanRadioOptions form={props.editGameForm}/>
                {/* "disable" the radio buttons by putting a overlay on top of them  */}
                {!props.authZ.canCrudGames
                  ? <div class="rounded-md absolute top-0 left-0 w-full h-full bg-white opacity-50"></div>
                  : null}
              </div>

              <div class="border-b"></div>

              <div class="-my-1">
                <div class="font-medium text-sm">Comments</div>
                <FormKit disabled={!props.authZ.canCrudGames} type="textarea" v-model={props.editGameForm.comment}/>
              </div>

              <div class="flex justify-end gap-2">
                <Btn2
                  disabled={disableCancelButton.value}
                  enabledClasses={btn2_redEnabledClasses}
                  onClick={() => emit("cancel")}
                  class="mt-2 px-2 py-1">Cancel</Btn2>
                <Btn2
                  disabled={disableSaveButton.value}
                  type="submit"
                  class="mt-2 px-2 py-1">Save changes</Btn2>
              </div>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

const BooleanRadioOptions = defineComponent({
  props: {
    form: vReqT<{pointsCount: boolean, genderNeutral: boolean, playoff: boolean, blockFromMatchmaker: boolean}>(),
  },
  setup(props) {
    // should be unique per component instance;
    // this is component is shared across create and edit forms, which can be displayed at the same time,
    // we want the radios across instances to NOT share a name. Probably we'll be in separate <form> elements
    // where this isn't necessary but why risk it.
    const radioPrefix = `gs-${nextOpaqueVueKey()}`

    const tooltipMsg_doPointsCount = ""
    const tooltipMsg_isGenderAgnostic = ""
    const tooltipMsg_playoffGame = ""
    const tooltipMsg_hideFromMatchmaker = ""

    // conditional rendering of tooltip ... can be inlined when we have definite values for the tooltip messages
    const maybeTooltip = (content: string) => content ? <FontAwesomeIcon icon={faQuestionCircle} v-tooltip={{content}}/> : null

    return () => {
      return (
        <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap:1em; align-items:center;">
          <div class="font-medium text-sm flex gap-1 items-center">
            {maybeTooltip(tooltipMsg_doPointsCount)}
            <div>Do points count?</div>
          </div>
          <div class="flex items-center gap-3">
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/PointsCount`} class="rounded-lg transition duration-100 ease-out" value={false} v-model={props.form.pointsCount}/>
              No
            </div>
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/PointsCount`} class="rounded-lg transition duration-100 ease-out" value={true} v-model={props.form.pointsCount}/>
              Yes
            </div>
          </div>

          <div class="font-medium text-sm flex gap-1 items-center">
            {maybeTooltip(tooltipMsg_isGenderAgnostic)}
            <div>Gender-agnostic slot?</div>
          </div>
          <div class="flex items-center gap-3">
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/GenderAgnostic`} class="rounded-lg transition duration-100 ease-out" value={false} v-model={props.form.genderNeutral}/>
              No
            </div>
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/GenderAgnostic`} class="rounded-lg transition duration-100 ease-out" value={true} v-model={props.form.genderNeutral}/>
              Yes
            </div>
          </div>

          <div class="font-medium text-sm flex gap-1 items-center">
            {maybeTooltip(tooltipMsg_playoffGame)}
            <div>Is this a playoff game?</div>
          </div>
          <div class="flex items-center gap-3">
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/PlayoffGame`} class="rounded-lg transition duration-100 ease-out" value={false} v-model={props.form.playoff}/>
              No
            </div>
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/PlayoffGame`} class="rounded-lg transition duration-100 ease-out" value={true} v-model={props.form.playoff}/>
              Yes
            </div>
          </div>

          <div class="font-medium text-sm flex gap-1 items-center">
            {maybeTooltip(tooltipMsg_hideFromMatchmaker)}
            <div>Hide from matchmaker?</div>
          </div>
          <div class="flex items-center gap-3">
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/hidden`} class="rounded-lg transition duration-100 ease-out" value={false} v-model={props.form.blockFromMatchmaker}/>
              No
            </div>
            <div class="flex items-center gap-1">
              <input type="radio" name={`${radioPrefix}/hidden`} class="rounded-lg transition duration-100 ease-out" value={true} v-model={props.form.blockFromMatchmaker}/>
              Yes
            </div>
          </div>
        </div>
      )
    }
  }
})

export const EditFieldBlock = defineComponent({
  props: {
    editFieldBlockForm: vReqT<EditFieldBlockForm>(),
    fieldOptions: vReqT<UiOption[]>(),
    busyUpdating: vReqT<boolean>(),
  },
  emits: {
    updateFieldBlock: () => true,
    cancel: () => true,
  },
  setup(props, {emit}) {
    const {
      timeStartHourOptions,
      timeStartMinuteOptions,
      fieldBlockLengthOptions,
    } = commonTimeOptions()

    const disableSaveButton = computed(() => {
      return props.busyUpdating
    })

    const disableCancelButton = computed(() => {
      return props.busyUpdating
    })

    return () => {
      return (
        <div>
          <FormKit type="form" actions={false} onSubmit={() => emit("updateFieldBlock")}>
            <div class="text-sm flex flex-col gap-2 il-webkit-select-with-text-sm-line-height-kludge" style={`--fk-padding-input:${fkPaddingInput};`}>
              <div>
                <div class="text-sm font-medium">Date</div>
                <FormKit type="date" v-model={props.editFieldBlockForm.startDate}/>
                <div class="text-sm font-medium">Field</div>
                <FormKit type="select" options={props.fieldOptions} v-model={props.editFieldBlockForm.fieldUID}/>
              </div>

              <div class="border-b"></div>

              <div>
                <div class="flex gap-2 items-center">
                  <input type="checkbox" v-model={props.editFieldBlockForm.blockEntireDay}/>
                  <span>Block entire day</span>
                </div>
                <div style="display:grid; grid-template-columns: max-content max-content; grid-gap:1em; position:relative; padding:2px;">
                  <div>Time</div>
                  <div class="flex items-center gap-2">
                    <span class="flex-grow max-w-24">
                      <FormKit type="select"
                        options={props.editFieldBlockForm.blockEntireDay ? [{label: "12 am", value: ""}] : timeStartHourOptions}
                        disabled={props.editFieldBlockForm.blockEntireDay}
                        v-model={props.editFieldBlockForm.startHour}
                        />
                    </span>
                    <span>:</span>
                    <span class="flex-grow max-w-36">
                      <FormKit
                        type="select"
                        options={props.editFieldBlockForm.blockEntireDay ? [{label: "00", value: ""}] : timeStartMinuteOptions}
                        disabled={props.editFieldBlockForm.blockEntireDay}
                        v-model={props.editFieldBlockForm.startMinute}
                        />
                      </span>
                  </div>
                  <div>Length</div>
                  <span>
                    <FormKit
                      type="select"
                      options={props.editFieldBlockForm.blockEntireDay ? [{label: "24 hours", value: ""}] : fieldBlockLengthOptions}
                      disabled={props.editFieldBlockForm.blockEntireDay}
                      v-model={props.editFieldBlockForm.lengthMinutes}
                      />
                  </span>
                  {props.editFieldBlockForm.blockEntireDay
                    ? <div class="absolute top-0 left-0 h-full w-full bg-white opacity-50"></div>
                    : null
                  }

                </div>
              </div>

              <div class="border-b"></div>

              <div>
                <div class="text-sm font-medium">Comments</div>
                <FormKit type="textarea" v-model={props.editFieldBlockForm.comment}/>
              </div>
            </div>

            <div class="flex justify-end gap-2">
              <Btn2
                disabled={disableCancelButton.value}
                enabledClasses={btn2_redEnabledClasses}
                onClick={() => emit("cancel")}
                class="mt-2 px-2 py-1">Cancel</Btn2>
              <Btn2
                disabled={disableSaveButton.value}
                type="submit"
                class="mt-2 px-2 py-1">Save changes</Btn2>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

const fkPaddingInput = ".325em";

function commonTimeOptions() {
  const timeStartHourOptions = (() => {
    const result : UiOption[] = []
    for (let i = 7; i <= 20; i++) {
      result.push({label: dayjs().hour(i).format("h a"), value: i.toString()})
    }
    return result;
  })()

  const timeStartMinuteOptions = (() => {
    const result : UiOption[] = []
    for (let i = 0; i < 60; i += 5) {
      result.push({label: i.toString().padStart(2, "0"), value: i.toString()})
    }
    return result;
  })()

  const gameLengthMinutesOptions = (() => {
    const result : UiOption[] = []
    for (let i = 5; i <= 120; i += 5) {
      result.push({label: i.toString(), value: i.toString()})
    }
    return result;
  })()

  const fieldBlockLengthOptions = (() => {
    const result : UiOption[] = []
    for (let i = 30; i < 120; i += 5) {
      result.push({label: `${i} minutes`, value: i.toString()})
    }
    for (let i = 120; i <= 12 * 60; i += 60) {
      result.push({label: `${i / 60} hours`, value: i.toString()})
    }
    return result;
  })()

  return {
    timeStartHourOptions,
    timeStartMinuteOptions,
    gameLengthMinutesOptions,
    fieldBlockLengthOptions,
  }
}

/**
 * e.g. cssNamedGridAreas("a1 a1", "a2 a3") -> `"a1 a1"\n"a2 a3"`,
 * which is suitable for use
 * as a css grid area value.
 */
function cssNamedGridAreas(...vs: string[]) {
  if (process.env.NODE_ENV === "development") {
    // sanity check row element counts. A def like
    //
    // c0 c1 c2
    // c3    c4
    //
    // is invalid, because all rows should have the same number of elements
    assertTruthy(vs.length > 0)
    let singleLen = -1
    for (const len of vs.map(v => v.split(/\s+/).length)) {
      if (singleLen === -1) {
        singleLen = len
      }
      assertTruthy(singleLen === len, "all rows must have the same number of elements (denote 'empty' cells with '.')");
    }
  }

  return vs.map(v => `"${v}"`).join("\n")
}

function maybeGetSeasonOptions(v: ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>) : UiOptionsConfig & {raw: SeasonTriple[]} {
  if (v.underlying.status === "error") {
    throw v.underlying.error
  }
  else {
    if (v.underlying.status === "resolved") {
      return {
        disabled: false,
        options: v.underlying.data.seasons.map(v => ({label: v.seasonName, value: v.seasonUID})),
        raw: v.underlying.data.seasons
      }
    }
    else {
      return {
        disabled: true,
        options: [{label: "Loading seasons...", value: ""}],
        raw: []
      }
    }
  }
}

function maybeGetDivisionOptions(v: ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>) : UiOptionsConfig {
  if (v.underlying.status === "error") {
    throw v.underlying.error
  }
  else {
    if (v.underlying.status === "resolved") {
      if (v.underlying.data.divisions.length === 0) {
        return {disabled: true, options: [{label: "No available options", value: ""}]}
      }
      return {disabled: false, options: v.underlying.data.divisions.map(v => ({label: v.displayName, value: v.divID}))}
    }
    else {
      return {disabled: true, options: [{label: "Loading divisions...", value: ""}]}
    }
  }
}

function maybeGetPoolOptions(v: ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>) : UiOptionsConfig {
  if (v.underlying.status === "error") {
    throw v.underlying.error
  }
  else {
    if (v.underlying.status === "resolved") {
      if (v.underlying.data.pools.length === 0) {
        return {
          disabled: false,
          options: [{label: "ALL", value: k_POOL_ALL}]
        }
      }
      else {
        return {
          disabled: false,
          options: [
            {label: "ALL", value: k_POOL_ALL},
            ...v.underlying.data.pools.map((v) => ({label: v.poolName, value: v.poolID.toString()}))
          ]
        }
      }
    }
    else {
      return {
        disabled: true,
        options: [{label: "Loading pools...", value: "" as any}]
      }
    }
  }
}

type UiOptionsConfig<T = string> = {disabled: boolean, options: UiOption<T>[]}

export enum TabID {
  createGames = "createGames",
  createFieldBlocks = "createFieldBlocks",
  bulkSelect = "bulkSelect",
  clearGameTeamAssignments = "clearGameTeamAssignments",
  rounds = "rounds",
  nil = "nil",
}

export const Rounds = defineComponent({
  props: {
    competitionOptions: vReqT<UiOption[]>(),
    rounds_compDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>>(),
    rounds_compDivSeasonDependentOptions: vReqT<ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>>(),
    rounds_selections: vReqT<{season: null | SeasonTriple, competitionUID: Guid | "", divID: Guid | "", poolID: "ALL" | Integerlike, roundID: Guid | ""}>(),
    selectedTabId: vReqT<Ref<TabID>>(),
    bulkSelectMode: vReqT<null | {type: "generic" | "rounds", selectedNodes: readonly LayoutNode<GameCalendarUiElement>[]}>(),
    rounds: vReqT<ReactiveReifiedPromise<Round[]>>(),
  },
  emits: {
    ...startStopBulkSelectEmits,
    ...roundsEmits,
  },
  setup(props, {emit}) {
    const options = (() => {
      const seasons = computed(() => {
        return maybeGetSeasonOptions(props.rounds_compDependentOptions)
      })

      const divisions = computed(() => {
        return maybeGetDivisionOptions(props.rounds_compDependentOptions)
      })

      const pools = computed(() => {
        return maybeGetPoolOptions(props.rounds_compDivSeasonDependentOptions)
      })

      const competitions = computed(() => props.competitionOptions)

      const rounds = computed<{disabled: boolean, options: UiOption[]}>(() => {
        if (props.rounds.underlying.status === "resolved") {
          const rounds = props.rounds.underlying.data
          if (rounds.length === 0) {
            return {disabled: true, options: [{label: "No available options.", value: ""}]}
          }
          else {
            return {disabled: false, options: [...rounds].sort(sortBy(_ => parseIntOr(_.roundNum, _.roundNum))).filter(v => !v.excluded).map((v,i) => ({label: `Round ${v.roundNum}`, value: v.roundID}))}
          }
        }
        else {
          return {disabled: true, options: [{label: "Loading options...", value: ""}]}
        }
      })

      return {
        seasons,
        competitions,
        divisions,
        pools,
        rounds
      }
    })();

    const fullSelection = computed(() => {
      if (selected.seasonUID.value && selected.competitionUID.value && selected.divID.value && selected.poolID.value) {
        return {
          competitionUID: selected.competitionUID.value,
          divID: selected.divID.value,
          seasonUID: selected.seasonUID.value,
          poolID: selected.poolID.value,
        }
      }
      return null
    })

    const selected = {
      competitionUID: {
        get value() { return props.rounds_selections.competitionUID },
        set value(v) {
          if (v !== this.value) {
            emit("update:rounds_competitionUID", v)
          }
        }
      },
      seasonUID: {
        get value() { return props.rounds_selections.season?.seasonUID ?? "" },
        set value(v) {
          if (v !== this.value) {
            emit("update:rounds_seasonUID", v)
          }
        }
      },
      divID: {
        get value() { return props.rounds_selections.divID },
        set value(v) {
          if (v !== this.value) {
            emit("update:rounds_divID", v)
          }
        }
      },
      poolID: {
        get value() { return props.rounds_selections.poolID },
        set value(v) {
          if (v !== this.value) {
            emit("update:rounds_poolID", v)
          }
        }
      },
      roundID: {
        get value() { return props.rounds_selections.roundID },
        set value(v) {
          if (v !== this.value) {
            emit("update:rounds_roundID", v)
          }
        }
      },
      isInBulkRoundsMode: {
        get value() { return props.bulkSelectMode?.type === "rounds" },
        set value(v) {
          if (v) {
            emit("startBulkSelect", "rounds")
          }
          else {
            emit("cancelBulkSelect")
          }
        }
      }
    }

    onMounted(async () => {
      //
      // try to "prime the pump", to get some options
      // This is kind of not great, we need to not be relying on emit and then waiting on emit to do things that we then also await on.
      // We are super coupled to root implementation
      //
      const competitionUID = forceCheckedIndexedAccess(props.competitionOptions, 0)?.value ?? ""
      if (!competitionUID) {
        // shouldn't happen
        return;
      }

      emit("update:rounds_competitionUID", competitionUID)
      await props.rounds_compDependentOptions.getResolvedOrFail() // running (or possibly completed) by virtue of emit; expected to succeed

      // these values should be updated by virtue of updating competitionUID and re-constraining, way up in the parent
      if (selected.competitionUID.value && selected.divID.value && selected.seasonUID.value) {
        emit("update:rounds_divID", selected.divID.value)
        await props.rounds_compDivSeasonDependentOptions.getResolvedOrFail() // running (or possibly completed) by virtue of emit; expected to succeed
      }
      else {
        // shouldn't happen
      }
    })

    return () => {
      return (
        <div style="--fk-padding-input:.5em; --fk-margin-outer:none;" data-test="Rounds">
          <div>Competition</div>
          <FormKit
            type="select"
            options={options.competitions.value}
            v-model={selected.competitionUID.value}
            data-test="competitionUID"
          />
          <div>Season</div>
          <FormKit
            type="select"
            disabled={options.seasons.value.disabled}
            options={options.seasons.value.options}
            v-model={selected.seasonUID.value}
            data-test="seasonUID"
          />
          <div>Division</div>
          <FormKit
            type="select"
            disabled={options.divisions.value.disabled}
            options={options.divisions.value.options}
            v-model={selected.divID.value}
            data-test="divID"
          />
          <div>Pool</div>
          <FormKit
            type="select"
            disabled={options.pools.value.disabled}
            options={options.pools.value.options}
            v-model={selected.poolID.value}
            data-test="poolID"
          />
          <div>Round</div>
          <div class="flex gap-2" style="max-width: var(--fk-max-width-input)">
            <div class="flex-grow">
              <FormKit
                type="select"
                disabled={options.rounds.value.disabled}
                options={options.rounds.value.options}
                v-model={selected.roundID.value}
                data-test="roundID"
              />
            </div>
            <Btn2 class="px-2 py-1" disabled={!selected.roundID.value}>Delete</Btn2>
          </div>
          <div class="flex gap-2 items-start my-2">
            <div class="py-1">
              <Btn2 class="px-2 py-1" disabled={!fullSelection} data-test="createRound" onClick={() => {
                emit("createRound", requireNonNull(fullSelection.value))
              }}>Create new</Btn2>
            </div>
            <div class="p-1 border rounded-md">
              <div class="flex items-center gap-2">
                <div style="translate: 0 2px">
                  <FormKit {...{type: "toggle" as any}} delay={0} v-model={selected.isInBulkRoundsMode.value} disabled={!selected.roundID.value} data-test="bulkRoundsToggle"/>
                </div>
                {selected.roundID.value
                  ? <span class="text-sm">Bulk Assign Selected Round</span>
                  : <span class="text-sm">Select a round to enable bulk assign</span>
                }

              </div>
              {props.bulkSelectMode?.type === "rounds"
                ? <Btn2 class="mt-2 px-2 py-1"
                  disabled={props.bulkSelectMode.selectedNodes.length === 0}
                  data-test="saveBulkRounds"
                  onClick={() => emit("bulkAssignRounds")}>Save</Btn2>
                : null
              }
            </div>
          </div>
        </div>
      )
    }
  }
})
