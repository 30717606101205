import { RouteLocationRaw } from "vue-router";
import { isAuthorizedToManageSomeTournament } from "./TournamentTeamUtils";

export const RouteNames = {
  main: "TournamentRefManager.main"
} as const;

export function routeDetailToRouteLocation() : RouteLocationRaw {
  return {name: RouteNames.main}
}

export function hasRoutePermission() : boolean {
  return isAuthorizedToManageSomeTournament()
}
