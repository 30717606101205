import authService from "src/helpers/authService";
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { User } from "src/store/User";
import { RouteLocationNormalizedLoaded, RouteLocationRaw } from "vue-router";
import { Phase1_CreateRounds } from "./MatchmakerElems";
import { parseIntOr, parseIntOrFail, CheckedOmit } from "src/helpers/utils";
import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { k_DateLike, k_GUID, queryBool, queryIntegerlike } from "src/boot/TypeBoxSetup";
import { k_POOL_ALL } from "../calendar/GameScheduler.shared";

export const RouteNames = {
  main: "Matchmaker",
  phase1: "Matchmaker.phase1",
  phase2: "Matchmaker.phase2",
  phase3: "Matchmaker.phase3",
} as const;

export function hasSomeRoutePermission() {
  const hasSomeRole = authService(User.value.roles, "gameScheduler", "webmaster")

  const isRelevantCompManager = !!User.userData?.competitionsMemento.find(v => v.isGameScheduler)

  const isRelevantDivManager = !!Client.value.instanceConfig.ddcanschedule
    && !!Client.value.instanceConfig.ddcaneditmatches
    && !!Client.value.instanceConfig.ddcanedittimes
    && !!Client.value.instanceConfig.ddcaneditfields
    && !!User.userData?.divisionsMemento.find(v => v.writeAccess)

  return hasSomeRole || isRelevantCompManager || isRelevantDivManager
}

export type Phase1Query = {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  includeGamesWithExistingMatchups?: boolean,
  startDate?: Datelike,
  endDate?: Datelike,
  numRoundRobinCycles?: Integerlike,
  roundLengthInDays?: Integerlike,
  poolID?: "ALL" | Integerlike,
  // if truthy, is a token that we can sync up with the the global "justSaved" link
  // TODO: should be a boolean, or a base64 of json or something so we don't need to rely on
  // out-of-band app state
  justSaved?: string,
}

export type Phase2Query = Phase1_CreateRounds

export type Phase3Query = {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike,
  roundIDs: Guid[],
  balanceHomeAndAway: boolean,
  byePosition: "first" | "last",
  includeGamesWithTeams: boolean,
  startDate: Datelike,
  endDate: Datelike,
  numRoundRobinCycles: Integerlike,
  roundLengthInDays: Integerlike,
}

export function routeDetailToRouteLocation(name: typeof RouteNames.main) : RouteLocationRaw;
export function routeDetailToRouteLocation(name: typeof RouteNames.phase1, query: CheckedOmit<Phase1Query, "justSaved">) : RouteLocationRaw;
export function routeDetailToRouteLocation(name: typeof RouteNames.phase2, query: Phase2Query) : RouteLocationRaw;
export function routeDetailToRouteLocation(name: typeof RouteNames.phase3, query: Phase3Query) : RouteLocationRaw;
export function routeDetailToRouteLocation(name: string, query?: any) : RouteLocationRaw {
  return {name, query}
}

export function mungePhase1QueryParams(currentRoute: RouteLocationNormalizedLoaded) : null | Phase1Query {
  if (currentRoute.name !== RouteNames.phase1) {
    return null
  }

  const q = currentRoute.query

  if (Value.Check(schema(), q)) {
    return {
      competitionUID: q.competitionUID,
      seasonUID: q.seasonUID,
      divID: q.divID,
      includeGamesWithExistingMatchups: q.includeGamesWithExistingMatchups ? !!parseIntOr(q.includeGamesWithExistingMatchups, false) : undefined,
      startDate: q.startDate,
      endDate: q.endDate,
      numRoundRobinCycles: parseIntOr(q.numRoundRobinCycles, undefined),
      roundLengthInDays: parseIntOr(q.roundLengthInDays, undefined),
      justSaved: q.justSaved,
      poolID: parseIntOr(q.poolID, k_POOL_ALL),
    }
  }
  else {
    return null
  }

  function schema() {
    return t.Object({
      competitionUID: t.String({format: k_GUID}),
      seasonUID: t.String({format: k_GUID}),
      divID: t.String({format: k_GUID}),
      includeGamesWithExistingMatchups: t.Optional(queryBool()),
      startDate: t.Optional(t.String({format: k_DateLike})),
      endDate: t.Optional(t.String({format: k_DateLike})),
      numRoundRobinCycles: t.Optional(queryIntegerlike()),
      roundLengthInDays: t.Optional(queryIntegerlike()),
      justSaved: t.Optional(t.String()),
      poolID: t.Optional(t.Union([t.Literal(k_POOL_ALL), queryIntegerlike()]))
    })
  }
}

export function mungePhase2QueryParams(currentRoute: RouteLocationNormalizedLoaded) : null | Phase2Query {
  if (currentRoute.name !== RouteNames.phase2) {
    return null
  }

  const q = currentRoute.query

  if (Value.Check(schema(), q)) {
    return {
      competitionUID: q.competitionUID,
      seasonUID: q.seasonUID,
      divID: q.divID,
      poolID: q.poolID as "ALL" | Integerlike,
      includeGamesWithTeams: !!parseIntOrFail(q.includeGamesWithTeams),
      startDate: q.startDate,
      endDate: q.endDate,
      numRoundRobinCycles: {
        value: parseIntOrFail(q.numRoundRobinCycles),
        source: {type: "user"}
      },
      roundLengthInDays: parseIntOrFail(q.roundLengthInDays),
    }
  }
  else {
    return null
  }

  function schema() {
    return t.Object({
      competitionUID: t.String({format: k_GUID}),
      seasonUID: t.String({format: k_GUID}),
      divID: t.String({format: k_GUID}),
      poolID: t.Union([t.Literal("ALL"), t.String({pattern: t.PatternNumberExact})]),
      includeGamesWithTeams: t.String({pattern: "^0|1$"}),
      startDate: t.String({format: k_DateLike}),
      endDate: t.String({format: k_DateLike}),
      numRoundRobinCycles: t.String({pattern: t.PatternNumberExact}),
      roundLengthInDays: t.String({pattern: t.PatternNumberExact}),
    })
  }
}

export function mungePhase3QueryParams(currentRoute: RouteLocationNormalizedLoaded) : null | Phase3Query {
  if (currentRoute.name !== RouteNames.phase3) {
    return null
  }

  const q = currentRoute.query

  if (Value.Check(schema(), q)) {
    return {
      competitionUID: q.competitionUID,
      seasonUID: q.seasonUID,
      divID: q.divID,
      poolID: parseIntOr(q.poolID, "ALL"),
      roundIDs: Array.isArray(q.roundIDs) ? q.roundIDs : [q.roundIDs],
      balanceHomeAndAway: !!parseIntOrFail(q.balanceHomeAndAway),
      byePosition: q.byePosition,
      includeGamesWithTeams: !!parseIntOrFail(q.includeGamesWithTeams),
      startDate: q.startDate,
      endDate: q.endDate,
      numRoundRobinCycles: parseIntOrFail(q.numRoundRobinCycles),
      roundLengthInDays: parseIntOrFail(q.roundLengthInDays),
    }
  }
  else {
    return null
  }

  function schema() {
    return t.Object({
      competitionUID: t.String({format: k_GUID}),
      seasonUID: t.String({format: k_GUID}),
      divID: t.String({format: k_GUID}),
      poolID: t.Union([t.Literal("ALL"), t.String({pattern: t.PatternNumberExact})]),
      balanceHomeAndAway: t.String({pattern: "^0|1$"}),
      byePosition: t.Union([t.Literal("first"), t.Literal("last")]),
      includeGamesWithTeams: t.String({pattern: "^0|1$"}),
      roundIDs: t.Union([
        t.String({format: k_GUID}),
        t.Array(t.String({format: k_GUID}))
      ]),
      startDate: t.String({format: k_DateLike}),
      endDate: t.String({format: k_DateLike}),
      numRoundRobinCycles: queryIntegerlike(),
      roundLengthInDays: queryIntegerlike(),
    })
  }
}
