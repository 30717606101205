import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router'
import routes from './routes'
import type { RouteRecordExtension } from './routes';
import { RouterHistoryTracker } from 'src/store/EventuallyPinia.RouterHistoryTracker';
import { accountSetupBlockadeState } from "src/store/EventuallyPinia"
import * as RouterScrollBehavior from "./RouterScrollBehavior"

import * as CycleBreaker from "./CycleBreaker"
import { bigModeSideBarOpen } from 'src/store/Sidebar';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 *
 * We used to be using webpack, and would receive the vue_router_mode and vue_router_base in process.env
 * Vite exposes env vars on process.env, but does not seem to expose a vue_router_mode
 * It does not appear that we ever explicitly set it using an env file, so we were relying on tooling setting it appropriately
 * With the Vite setup, we know we are using the "not-hash-based" history
 */
const BUILDTOOL: "webpack" | "vite" = "vite";
const createHistory = BUILDTOOL === "vite"
  ? createWebHistory
  : process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

const routeArray: RouteRecordRaw[] = []
for (const i in routes) {
  if (routes[i].disableTopLevelAutoNameInjection) {
    // no-op
  }
  else {
    routes[i].name = i
  }
  routeArray.push(routes[i])
}

const router = createRouter({
  scrollBehavior: (to, _from, _savedPos) => {
    RouterHistoryTracker.push(to);

    //
    // https://github.com/vuejs/vue-router/issues/2921
    // Really would like an option configurable at the <RouterLink/> level for this, rather than at the route level.
    // For router pushes, we can wrap such pushes in a temporary scroll behavior disablement block, but for a <RouterLink/>,
    // we can only rely on route configuration. There's also cases where we'd like to scroll to top on primary route entry, but
    // then from child routes, if we go "back" to the parent route, to retain the current position, so scroll behavior there would be conditional on
    // "was this navigation a backwards navigation from a child route", which currently we don't support.
    //
    // Browser HistoryState is apparently a recommended place to store information like this.
    //
    if (to.meta.noScroll || RouterScrollBehavior.getScrollOnRouteChangeIsDisabled()) {
      return false;
    }
    else {
      return { left: 0, top: 0 }
    }
  },
  routes: routeArray,
  history: createHistory(process.env.VUE_ROUTER_BASE)
})

CycleBreaker.__setters__.setRouter(router);

const actionAlias = {CONTINUE_TO_NEXT: true, CANCEL_NAVIGATION: false} as const;

router.afterEach((to) => {
  if (!(to.meta as RouteRecordExtension["meta"]).allowBigModeSidebarCollapse) {
    // navigating to a route that does not allow sidebar to be collapsed, so expand it
    bigModeSideBarOpen.value = true;
  }
})

router.beforeEach((_to, _from) => {

  if (accountSetupBlockadeState.requiresCollectGender) {
    const targetPath = process.env.MODE === "spa" ? "/" : "/mobile-landing"
    // No navigation should be possible until this blockade has been removed.
    // Just reroute to home if the user is doing something like messing with devtools or whatever.
    // We shouldn't ever trigger this on our own.
    if (_to.path === targetPath) {
      // recursive base case
      return actionAlias.CONTINUE_TO_NEXT
    }
    else {
      return {path: targetPath}
    }
  }
  return actionAlias.CONTINUE_TO_NEXT;
})

/**
 * do not use this anywhere except for providing it to quasar as part of its "boot files" phase.
 * If you need to get the global router singleton from outside of a vue context, use `CycleBreaker.getRouter()`
 */
export default route(function (/* { store, ssrContext } */) {
  return router
})
