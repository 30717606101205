import { arrayFindOrFail, vReqT } from "src/helpers/utils";
import { ColName, JsxQuasarColumnRenderShim, AugmentedGamelikeForSchedule, ColDef } from "./page/schedules.ilx"
import { defineComponent } from "vue";

export const GamelikeScheduleTableSmall = defineComponent({
  props: {
    columns: vReqT<ColDef[]>(),
    rows: vReqT<AugmentedGamelikeForSchedule[]>(),
  },
  setup(props) {
    const coldDef = {
      fieldID: arrayFindOrFail(props.columns, col => col.name === ColName.fieldID),
      gameStart: arrayFindOrFail(props.columns, col => col.name === ColName.gameStart),
      Home: arrayFindOrFail(props.columns, col => col.name === ColName.Home),
      Visitor: arrayFindOrFail(props.columns, col => col.name === ColName.Visitor),
      Comment: arrayFindOrFail(props.columns, col => col.name === ColName.Comment),
      scoreInfo: arrayFindOrFail(props.columns, col => col.name === ColName.scoreInfo),
      refs: arrayFindOrFail(props.columns, col => col.name === ColName.refs),
    } as const;

    return () => {
      return (
        <div>
          {
            props.rows.map(row => {
              return (
                <div class="p-1 border rounded-md bg-white my-4 shadow-md" data-test={`gameID=${row.gameID}`}>
                  <div style="display: grid; grid-template-columns: min-content 1fr;">
                    <div class="m-1">
                      <div class="text-xs font-medium">Field</div>
                      <JsxQuasarColumnRenderShim fieldResult={coldDef.fieldID.field(row)}/>
                    </div>
                    <div class="m-1">
                      <div class="text-xs font-medium">Date</div>
                      <JsxQuasarColumnRenderShim fieldResult={coldDef.gameStart.field(row)}/>
                    </div>
                  </div>
                  <div class="border-b"/>
                  <div class="m-1">
                    <div class="text-xs font-medium">Teams</div>
                    <div class="p-1">
                      <div class="text-xs">Home</div>
                      <JsxQuasarColumnRenderShim fieldResult={coldDef.Home.field(row)}/>
                    </div>
                    <div class="p-1">
                      <div class="text-xs">Visitor</div>
                      <JsxQuasarColumnRenderShim fieldResult={coldDef.Visitor.field(row)}/>
                    </div>
                  </div>
                  <div class="border-b"/>
                  <div class="m-1">
                    <div class="text-xs font-medium">Refs</div>
                    <JsxQuasarColumnRenderShim fieldResult={coldDef.refs.field(row)}/>
                  </div>
                  <div class="border-b"/>
                  <div class="m-1">
                    <div class="text-xs font-medium">Comment</div>
                    <div class="ml-1">
                      <span>{row.comment || "<none>"}</span>
                    </div>
                  </div>
                  {
                    row.shouldDisplayScoreInfo
                      ? (
                        <>
                          <div class="border-b"/>
                          <div class="m-1">
                            <div class="text-xs font-medium">Score info</div>
                            <div class="ml-1">
                              <JsxQuasarColumnRenderShim fieldResult={coldDef.scoreInfo.field(row)}/>
                            </div>
                          </div>
                        </>
                      )
                      : null
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
  }
})
