import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosInstance } from "src/boot/AxiosInstances";
import { computed, defineComponent, ref } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { accentAwareCaseInsensitiveCompare, arrayFindIndexOrFail, copyViaJsonRoundTrip, sortByDayJS, sortByMany, vReqT } from "src/helpers/utils";
import { AxiosInstance } from "axios";

import { RefereeRosterImpl } from "./TournamentTeamConfigurator.referees.impl";
import { RefMutablesFormData } from "./TournamentTeamConfigurator.shared"
import { TournamentTeam, TournamentTeamFromListTournamentTeamsEndpoint, TournamentTeamOfficial } from "src/composables/InleagueApiV1.Tournament";
import { User } from "src/store/User";
import { directionsToPlayingFieldURL } from "src/helpers/GoogleMaps";
import { DAYJS_FORMAT_IL_FULL_1 } from "src/helpers/formatDate";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { Guid, Integerlike, WithDefinite } from "src/interfaces/InleagueApiV1";

export const RefereeRoster = defineComponent({
  props: {
    tournTeamOrUndefinedIfUnaffiliatedOrMultiple: vReqT<TournamentTeam | undefined>(),
    targetBinding: vReqT<iltournament.TournamentTeamOfficialBinding>(),
    mut_existingOfficials: vReqT<iltournament.TournamentTeamOfficial[]>(),
    canMakeEdits: vReqT<boolean>(),
  },
  emits: {
    addedRef: (_: WithDefinite<TournamentTeamOfficial, "refAssignments">) => true,
    removedRef: (_: {tournamentTeamOfficialID: Integerlike}) => true,
  },
  setup(props, ctx) {
    const mostRecentCandidateSearchResult = ref<
      | "no-search-yet"
      | "nothing-found"
      | iltournament.TournamentTeamOfficialCandidate
    >("no-search-yet")

    const doCandidacyLookupByStackSid = async (axios: AxiosInstance, args: {stackSID: string, lastName: string}) : Promise<void> => {
      try {
        // TODO: axiosInstance that does not error toast on 404?
        const playerCandidate = await iltournament.getTournamentTeamOfficialCandidateByStackSID(
          axios, {
            binding: props.targetBinding,
            stackSID: args.stackSID,
            lastName: args.lastName,
            officialType: iltournament.TournamentTeamOfficialType.REFEREE
        });

        if (playerCandidate) {
          mostRecentCandidateSearchResult.value = playerCandidate;
        }
        else {
          mostRecentCandidateSearchResult.value = "nothing-found";
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddRef = async (
      axios: AxiosInstance,
      stackInfo: {stackSID: string, lastName: string},
      refForm: RefMutablesFormData,
      selectedUserIdIfMatchingInleagueUser: undefined | iltypes.Guid,
      selectedRegionIfWillBeGeneratingUser: undefined | iltypes.Integerlike,
    ) : Promise<{ok:boolean}> => {
      try {
        const fresh = await iltournament.createTournamentTeamOfficialByStackSID(
          axios, {
            binding: props.targetBinding,
            stackSID: stackInfo.stackSID,
            lastName: stackInfo.lastName,
            officialType: iltournament.TournamentTeamOfficialType.REFEREE,
            maxCR: refForm.maxCR === "" ? undefined : refForm.maxCR,
            maxAR: refForm.maxAR === "" ? undefined : refForm.maxAR,
            ref_badgeLevel: refForm.ref_badgeLevel.text,
            comments: refForm.comments.text,
            selectedUserIdIfMatchingInleagueUser,
            selectedRegionIfWillBeGeneratingUser
          });
        props.mut_existingOfficials.unshift(fresh); // new to front
        mostRecentCandidateSearchResult.value = "no-search-yet"; // "zero out" the current search, we assume here we've just "consumed" it

        // TODO: lift his all up a level, we should be doing IO in the route root component
        ctx.emit("addedRef", copyViaJsonRoundTrip({...fresh, refAssignments: []}))

        return {ok:true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const doUpdateRef = async (axios: AxiosInstance, official: iltournament.TournamentTeamOfficial, form: RefMutablesFormData) : Promise<{ok: boolean}> => {
      try {
        const freshOfficial = await iltournament.updateTournamentTeamOfficial(axios, {
          tournamentTeamOfficialID: official.tournamentTeamOfficialID,
          officialType: iltournament.TournamentTeamOfficialType.REFEREE,
          comments: form.comments.text,
          maxAR: form.maxAR === "" ? null : form.maxAR,
          maxCR: form.maxCR === "" ? null : form.maxCR,
          ref_badgeLevel: form.ref_badgeLevel.text,
        })

        const targetIdx = arrayFindIndexOrFail(props.mut_existingOfficials, _ => _.tournamentTeamOfficialID === official.tournamentTeamOfficialID)
        props.mut_existingOfficials.splice(targetIdx, 1, freshOfficial);
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }
    const doRemoveRef = async (axios: AxiosInstance, official: iltournament.TournamentTeamOfficial) : Promise<void> => {
      try {
        await iltournament.deleteTournamentTeamOfficialByTournamentTeamOfficialID(axiosInstance, {tournamentTeamOfficialID: official.tournamentTeamOfficialID});
        const idx = arrayFindIndexOrFail(props.mut_existingOfficials, v => v.stackSID === official.stackSID);
        const obj = props.mut_existingOfficials[idx]

        props.mut_existingOfficials.splice(idx, 1);
        // TODO: lift his all up a level, we should be doing IO in the route root component
        ctx.emit("removedRef", obj)
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return () => <RefereeRosterImpl
      shouldDisplayAddRef={props.canMakeEdits}
      tournTeamOrUndefinedIfUnaffiliatedOrMultiple={props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple}
      mostRecentCandidateSearchResult={mostRecentCandidateSearchResult.value}
      mut_existingRefs={props.mut_existingOfficials.filter(isReferee)}
      doCandidacyLookupByStackSid={doCandidacyLookupByStackSid}
      doAddRef={doAddRef}
      doUpdateRef={doUpdateRef}
      doRemoveRef={doRemoveRef}
      canMakeEdits={props.canMakeEdits}
    />
  }
})

function isReferee(v: iltournament.TournamentTeamOfficial) {
  switch (v.type) {
    case iltournament.TournamentTeamOfficialType.REFEREE: return true;
    default: return false;
  }
}

export const RefereeAssignments = defineComponent({
  props: {
    refOfficials: vReqT<TournamentTeamFromListTournamentTeamsEndpoint["refTournTeamOfficials"]>()
  },
  setup(props) {
    const refOfficials = computed(() => {
      // Any initial filtering or sorting here
      // We're deeply sorting so we make a paranoiac copy
      return copyViaJsonRoundTrip(props.refOfficials).sort(sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(l.lastName, r.lastName),
        (l,r) => accentAwareCaseInsensitiveCompare(l.firstName, r.firstName)
      )).map(v => {
        v.refAssignments.sort(sortByDayJS(v => v.gameStart))
        return v;
      })
    })

    return () => {
      return (
        <div data-test="RefereeAssignments">
          {refOfficials.value.length === 0
            ? <div>No current refs for this team.</div>
            : null
          }
          {refOfficials.value.map((refOfficial, i, a) => {
            const isLastOfUsers = i === a.length - 1
            return (
              <div key={refOfficial.tournamentTeamOfficialID} data-test={`userID=${refOfficial.userID}`}>
                <div>{refOfficial.firstName} {refOfficial.lastName}</div>
                {refOfficial.refAssignments.length === 0
                  ? <div class="pl-1 text-sm">No current ref assignments.</div>
                  : null
                }
                {refOfficial.refAssignments.map((assignment, i, a) => {
                  const isLastAssignmentForUser = i === a.length - 1
                  const googleMapUrl = directionsToPlayingFieldURL(User.value.userAddress, assignment.field)
                  return <div key={assignment.assignmentID}>
                    <div class="pl-1 text-sm">{dayjs(assignment.gameStart).format(DAYJS_FORMAT_IL_FULL_1)}, Game {assignment.gameNum}, {assignment.positionName}</div>
                    <div class="pl-1 text-sm">
                      <a class="il-link inline-flex gap-2 items-center" href={googleMapUrl} target="_blank">
                        <FontAwesomeIcon icon={faMapMarkedAlt}/>
                        <span>{assignment.field.fieldAbbrev} ({assignment.field.fieldName})</span>
                      </a>
                    </div>
                    <div class="pl-1 text-sm">{assignment.comments}</div>
                    {isLastAssignmentForUser ? null : <div class="pl-1 border-b border-dashed border-gray-300 my-1 "></div>}
                  </div>
                })}
                {isLastOfUsers ? null : <div class="border-b my-1 border-black"></div>}
              </div>
            )
          })}
        </div>
      )
    }
  }
})
