import dayjs, { Dayjs } from "dayjs";
import { bestUpcomingOrCurrentCompetitionStartDayOfWeek } from "src/helpers/Competition";
import { createWeekOptions } from "src/helpers/formatDate";
import { arrayFindOrFail, assertTruthy, max } from "src/helpers/utils";
import { Competition, CompetitionSeason, Datelike } from "src/interfaces/InleagueApiV1";

/**
 * Create a map of (datelike -> uilabel),
 * mapping "dates" to the ui version of that date,
 * representing all available weeks in some particular season.
 */
export function createWeekOptionsForCompetitionSeason(
  comp: Competition,
  compSeason: CompetitionSeason,
  weeksCount = compSeason.seasonWeeks
) : {[startOfWeek: Datelike]: /* ui label */ string} {
  return createWeekOptions(
    new Date(compSeason.seasonStart),
    comp.startDayOfWeek,
    weeksCount,
    new Date(compSeason.seasonStart)
  );
}

//
// Choose "current week" out of a list of available weeks, where the list of available weeks is expected to contain "current week"
//
// Given competition.startDayOfWeek of N (which defaults to Saturday, or 7)
// If today's day of the week is equal to N or N+1 (which in the above case is 7 or 1, for Sunday)
//   then show the 'current' week (starting on the Saturday, which is either 'the next Saturday' or if today is Sunday, 'yesterday')
// If today's day of the week is any other day
//   default to the next N (e.g. today is Monday 4/24 so show N == Saturday, 4/29)
//
export function chooseCurrentWeekFromAvailableWeeks(
  comp: Pick<Competition, "startDayOfWeek" | "competitionUID">,
  availableWeeks: Datelike[],
  today = dayjs()
) : Datelike | undefined {
  const target = bestUpcomingOrCurrentCompetitionStartDayOfWeek(comp.startDayOfWeek, today)

  //
  // scan through and compare based on dayjs-parsed values;
  // but note that what we return to caller is the literally provided string datelike value from the provided list,
  // which gives some wiggle room for caller-provided formats, as long as they are parseable by dajs
  //
  const ret = availableWeeks.find(someDatelike => dayjs(someDatelike).isSame(target, "day"))

  if (ret === undefined) {
    //
    // we might want to actually throw or at least log this to sentry. We shouldn't ever get here.
    // throw `couldn't find startDayOfWeek, competitionUID=${comp.competitionUID}, (jsday)today=${todaysDayOfWeek}, (jsday)startDayOfWeek=${N}, target=${target.toISOString()}`
    //
    return undefined;
  }
  else {
    return ret;
  }
}

export function getAge(dob: Dayjs | Datelike) : number | undefined {
  const d = dayjs(dob)
  if (!d.isValid()) {
    return undefined
  }
  else {
    return dayjs().diff(d, "years", /*include fractional result*/ true)
  }
}

export function isYouthReferee(dob: Dayjs | Datelike) {
  const age = getAge(dob)
  if (typeof age !== "number") {
    // if dob's not valid, we can't really answer this question
    return false
  }
  else {
    return age < 18;
  }
}

/**
 * only generic to aid testing
 */
export function getRefSlotConfigDisjointnessInfo<T>(refSlots: {refPosNames: string[], sourceRefConfig: T}[]) {
  if (refSlots.length === 0 || refSlots.every(v => v.refPosNames.length === 0)) {
    return undefined
  }

  // if any of the refslots don't share a common a prefix of pos names,
  // we say "some are disjoint",
  // e.g.
  // (a,b) and (a,b,c,d) and (a) are not disjoint
  // (a,b) and (a,x,c) are disjoint
  const someRefSlotConfigsAreDisjoint : boolean = (() => {
    const sharedPrefix : string[] = []
    for (const {refPosNames} of refSlots) {
      for (let i = 0; i < refPosNames.length; i++) {
        if (i > sharedPrefix.length - 1) {
          sharedPrefix.push(refPosNames[i])
          continue;
        }
        else {
          if (refPosNames[i] !== sharedPrefix[i]) {
            return true;
          }
          else {
            continue;
          }
        }
      }
    }

    return false;
  })()

  const maxRefPosCount = max(refSlots.map(v => v.refPosNames.length))

  // we return null earlier if everything is empty;
  // otherwise, we have at least one non-empty source array and we should get at least a maxPrefixCount of 1
  assertTruthy(maxRefPosCount > 0);

  return {
    maxRefPosCount, // app probably doesn't need to know this but it's nice for testing
    someRefSlotConfigsAreDisjoint,
    firstSourceHavingMaxRefPosCount: arrayFindOrFail(refSlots, v => v.refPosNames.length === maxRefPosCount).sourceRefConfig,
  }
}
