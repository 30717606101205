<template lang="pug">
#q-app
  MainLayout
  .bg-red-800.bg-red-300.bg-red-700.border-red-700(v-if="false")
  //-
  //- global drag handle element,
  //- renders out the drag handle element (if one is present) near the current mouse pos
  //-
  GlobalDragHandleElement
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  watch,
  getCurrentInstance,
  computed,
  ref,
  watchEffect,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import MainLayout from 'layouts/MainLayout.vue'
import 'src/css/tailwind.scss'
import '@formkit/themes/genesis'

import { Capacitor, Plugins } from '@capacitor/core'
const { Keyboard } = Plugins
import 'v-tooltip/dist/v-tooltip.css'
import authService from 'src/helpers/authService'
import {RouteWithInleagueMetadata, routeAuthPredicateRunner} from 'src/router/routes'
import { System } from './store/System'
import { User } from "src/store/User"
import { Client } from './store/Client'
import { getOnAppMountErrorMessage } from './composables/InleagueApiV1'
import { freshNoToastLoggedInAxiosInstance } from './boot/axios'
import { useIziToast } from './helpers/utils'
import { GlobalDragHandleElement } from './GlobalDragHandle'


export default defineComponent({
  name: 'App',
  components: {
    MainLayout,
    GlobalDragHandleElement,
  },
  setup() {
    // data
    const localInstance = getCurrentInstance()
    const route = useRoute() as RouteWithInleagueMetadata
    const loggedInUser = ref('')
    const router = useRouter()
    // iOS Keyboard won't close fix
    // if (Capacitor.getPlatform() === 'ios') {
    //   Keyboard.setScroll({ isDisabled: false })
    //   $(document).on('click', e => {
    //     // console.log('e.target.classList',e.target.classList.value.split(' '),  e.target.classList)
    //     if (
    //       !e.target.classList.value.split(' ').some((r: string) =>
    //         ['t-input', 't-select'].includes(r)
    //       )
    //     ) {
    //       Keyboard.hide()
    //     }
    //   })
    // }

    const iziToast = useIziToast();

    // customizing app for league
    Client.updateDOMThemingAttrs()

    if (process.env.MODE === 'capacitor') {
      System.directCommit_setIsMobile(true)
      System.directCommit_setDevicePlatform(Capacitor.getPlatform())
    }

    //! PASS `?mobileTest=1` to enable mobile test mode (needed for Cypress)
    const url = new URL(window.location.href)
    if (url.searchParams.get('mobileTest')) {
      System.directCommit_setIsMobile(true)
      System.directCommit_setDevicePlatform('ios')
    }

    const recaptchaBadge = () => {
      localInstance?.appContext.config.globalProperties?.$recaptchaInstance?._rawValue.hideBadge()
    }

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const userID = computed(() => {
      return User.value.userID
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const getRoles = computed(() => {
      return User.value.roles
    })

    watch(route, async route => {
      // why would it be falsy?
      if (route.meta) {
        if (isLoggedIn.value) {
          if (route.meta?.requiresNoAuth) {
            await router.push({ name: 'home' })
          }

          const hasRequiredAuth = await routeAuthPredicateRunner(route.meta);

          if (!hasRequiredAuth) {
            await router.push({ name: 'forbidden' })
          }
        }
      }

      // why
      recaptchaBadge()
    })

    // // watchers
    // watch(route, async route => {
    //   const auth = route?.meta?.auth as string[] | undefined
    //   if (
    //     isLoggedIn.value &&
    //     auth?.length &&
    //     !authService(getRoles.value, ...auth) &&
    //     !(await route?.meta?.alternateAuthRolesCheck?.(store))
    //   ) {
    //     await router.push({ name: 'forbidden' })
    //   }
    //   if (isLoggedIn.value && route.meta?.requiresNoAuth) {
    //     await router.push({ name: 'home' })
    //   }
    //   recaptchaBadge()
    // })

    watch(userID, () => {
      if (userID.value && userID.value != loggedInUser.value)
        loggedInUser.value = userID.value
    })

    watchEffect(async () => {
      if (!User.isLoggedIn) {
        return;
      }

      const ERROR_MSG_KEY = "errorMessage";
      const encodedMsg = route.query[ERROR_MSG_KEY];

      if (!encodedMsg || typeof encodedMsg !== "string") {
        return;
      }

      {
        //
        // order is important here, do this after having check if encodedMsg is the type we want
        // it's possible the router hasn't fully initialized yet, and if it hasn't, we'll receive nothing,
        // even though the query param is "pending" somewhere in the router, and we don't want to replace the querystring
        // with "nothing", otherwise the route that MAY have included the query params with the string we want,
        // which will resolve when the router is ready, will be discarded, and when the router is finally ready,
        // we'll have deleted the actual message.
        //
        // TLDR: do this after checking that encodedMsg was a string, for router lifecycle purposes.
        //
        const queryCopy = Object.assign({}, route.query);
        delete queryCopy[ERROR_MSG_KEY]
        await router.replace({query: queryCopy})
      }

      const message = await getOnAppMountErrorMessage(freshNoToastLoggedInAxiosInstance(), encodedMsg);
      if (message) {
        iziToast.error({message});
      }
    })

    onMounted(async () => {
      if (isMobile.value) {
        // These don't seem to be actually available, at least on android, or some androids,
        // but it's not immediately clear that we can simply/safely remove Keyboard import.
        // We'll punt by making these calls optional.
        // https://inleague-llc.sentry.io/issues/3769950327
        Keyboard?.setAccessoryBarVisible?.({ isVisible: true })
        Keyboard?.setScroll?.({ isDisabled: false })
      }
      if(isMobile.value && !isLoggedIn.value) {
        await router.push({name: 'mobile-landing'})
      }

      await Client.maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser()
    })

    return {
      userID,
      isMobile,
    }
  },
})
</script>

<style>
:root {
  --fk-color-primary: var(--seven);
}
.removeShadow {
  box-shadow: none !important;
}
.noMargin {
  margin: 0 !important;
}
.textBlack {
  color: rgb(0 0 0) !important;
}
</style>
./GlobalDragHandle