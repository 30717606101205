import { Dialog, DialogTitle, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { ExtractPropTypes, PropType, computed, defineComponent, reactive, ref, watchEffect } from 'vue';
import { X, SoccerBall } from '../SVGs';
import { ExtractEmitsHandlers, ExtractOnEmitsHandlers, OmitDefaultsFromPropsDefs, useWindowSize, vOptT } from 'src/helpers/utils';
import { Client } from 'src/store/Client';

export interface Slots<T = any> {
  title?: (v?: T) => JSX.Element | null
  content?: (v?: T) => JSX.Element | null
}

export type TinyController<T> = {
  open: (v:T) => void,
  close: () => void,
}

const propsDef = {
  isOpen: {
    required: true,
    type: Boolean
  },
  // would be nice to have a separate option for either/or
  closeOnClickOutsideOrPressEscape: {
    required: false,
    type: Boolean,
    default: true,
  },
  withXButton: {
    required: false,
    type: Boolean,
    default: true
  },
  /**
   * This value is forwarded to slot func invocations.
   * This doesn't seem amenable to typing. It's like "argtype of (Slots<T>.{title, content})".
   */
  internal_slotFuncArgs: {
    required: false,
    type: null
  }
} as const;

export type Props = ExtractPropTypes<OmitDefaultsFromPropsDefs<typeof propsDef>>

const emitsDef = {
  close: () => true,
  onAfterLeave: () => true, // fixme: "afterLeave" not "onAfterLeave", "on" will be prepended elsewhere
} as const;

export type Emits = ExtractEmitsHandlers<typeof emitsDef>;
export type OnEmits = ExtractOnEmitsHandlers<typeof emitsDef>;

export const Modal = defineComponent({
  props: propsDef,
  inheritAttrs: false,
  emits: emitsDef,
  setup(props, {emit, slots, attrs}) {
    // uh, sorta trying to default _part_ of the class attrs ...
    // if caller says "max-w-<something>" then we don't add anything;
    // but if they omit that from their class string, the default is "max-w-md"
    const classes = computed<string>(() => {
      if (attrs.class) {
        if (/max-w-\w+/.test(attrs.class as any)) {
          return attrs.class as string
        }
        else {
          return `max-w-md ${attrs.class}`
        }
      }
      return "max-w-md"
    })

    const attrsNoClass = computed(() => {
      if (attrs.class) {
        const v = {...attrs}
        delete v.class;
        return v;
      }
      else {
        return attrs;
      }
    })

    return () => (
      <>
        <TransitionRoot appear show={props.isOpen} as="template">
          <Dialog as="div" style="z-index:99999;" class="relative" onClose={() => { props.closeOnClickOutsideOrPressEscape ? emit("close") : void 0; }}>
            <TransitionChild
              as="template"
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              onAfterLeave={ () => emit("onAfterLeave") }
            >
              <div class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
              <div class="flex min-h-full items-center justify-center p-4 text-center">
                <TransitionChild
                  as="template"
                  enter="ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {/* outer attrs can go here, seems fine. Good for <Modal data-test="..."/> and it'll end up here. */}
                  <DialogPanel {...attrsNoClass.value} class={`${classes.value} w-full transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                      {
                        props.withXButton
                          ? (
                            <div
                              style="position:absolute; top:0;right:0; margin:.25em; padding:.125em;" class="rounded-md cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                              onClick={ () => emit("close") }
                            >
                              <X penWidth={13}/>
                            </div>
                          )
                          : null
                      }
                      <DialogTitle
                        as="h3"
                        class={modal_defaultTitleClasses}
                      >
                        {(slots as Slots).title?.(props.internal_slotFuncArgs)}
                      </DialogTitle>
                        {/*
                          invisible button does nothing here, except to resolve
                          ```
                          focus-trap.js:1 There are no focusable elements inside the <FocusTrap />
                          ```

                          see: https://github.com/tailwindlabs/headlessui/issues/265

                          This error can happen if we mount a dialog with just text content, which maybe we want to do sometimes.
                          The warning is trying to guide us to "hey you need a button to let the user close this thing",
                          which is sort of reasonable, although clicking outside of the modal or pressing escape closes it, too.

                          By going before `slots.content()`, focus automatically falls onto the hidden button, since it seems focus goes to the first focusable item in DOM.
                          Sometimes autofocus on the first content focusable item is nice; sometimes it's not what we want.
                        */}
                        <button type="button" style="height:0px; width:0px; position:fixed; top:0; left:0;"/>
                        {(slots as Slots).content?.(props.internal_slotFuncArgs)}
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </>
    )
  }
});

/**
 * Exploring an API for a "default" controller that maintains a lot of flexibility regarding props and slots and etc.
 * If it's not flexible enough it can serve at least as a template from which to create other slightly tweaked hardcoded
 * versions of itself.
 *
 * Caller almost certainly wants to wrap the result of this in a `reactive(...)`
 *
 * @param options.onOpenCB -- only provides a notification that the modal was opened, with the data provided to the `open` call; provides no mechanism
 * to directly alter the modal's lifecycle from the callbacks arguments or return value. Is basically "onMounted" for the modal opening
 *
 * @deprecated use DefaultModalController_r, which just wraps the result in a reactive, which we 100% of the time want to do.
 */
export function DefaultModalController<T>(modalSlots: Slots<T>, options?: {closeOnClickOutsideOrPressEscape?: boolean, withXButton?: boolean, onOpenCB?: (_: T) => void, onCloseCB?: (doClose: () => void) => void}) {
  enum Mode {open, closing, closed}
  type Data<T> =
    | {readonly mode: Mode.closed}
    | {readonly mode: Mode.open | Mode.closing, readonly v: T}

  const data_ = ref<Data<T>>({mode: Mode.closed})

  const open = (v: T) => {
    options?.onOpenCB?.(v);
    data_.value = {
      mode: Mode.open,
      v: v as any // vue typings with Ref<T> somehow lose the ability to prove `T <: T` here; the assertion is sound
    }
  }

  const closeImpl = () => {
    if (data_.value.mode === Mode.closed) {
      return; // already closed
    }
    // transition to "closing", but retain data to keep alive the modal DOM content, until close animations complete
    data_.value = {
      mode: Mode.closing,
      v: data_.value.v
    }
  }

  const close = () => {
    if (options?.onCloseCB) {
      // if we have a callback, run it; the caller can choose to invoke `closeImpl()` or not
      options.onCloseCB(closeImpl)
    }
    else {
      // no such callback, immediately invoke `closeImpl()`
      closeImpl();
    }
  }

  const onCloseCompleteHandler = () => {
    // transition from "closing" to "closed"
    data_.value = {mode: Mode.closed}
  }

  const isOpen_ = computed(() => {
    return data_.value.mode === Mode.open
  })

  const modalPropsAndHandlers : Props & OnEmits = {
    get isOpen() { return isOpen_.value },
    closeOnClickOutsideOrPressEscape: options?.closeOnClickOutsideOrPressEscape ?? true,
    withXButton: options?.withXButton ?? true,
    onClose: close,
    onOnAfterLeave: onCloseCompleteHandler,
    get internal_slotFuncArgs() { return data_.value.mode === Mode.closed ? undefined : data_.value.v; }
  }

  return {
    open,
    close,
    modalPropsAndHandlers,
    modalSlots,
  } as const
}

export type DefaultModalController<T> = ReturnType<typeof DefaultModalController<T>>

/**
 * "always-wrapped-in-reactive" version of DefaultModalController. @see DefaultModalController
 */
export const DefaultModalController_r = <T,>(...args: Parameters<typeof DefaultModalController<T>>) => reactive(DefaultModalController(...args));

/**
 * Mount some modal using a provided "default" modal controller.
 * TODO: rename to "ControlledModal"
 */
export const AutoModal = defineComponent({
  name: "AutoModal",
  props: {
    controller: {
      required: true,
      type: Object as PropType<DefaultModalController<any>>
    }
  },
  setup(props) {
    //
    // why doesn't `<Modal {...props.controller.modalPropsAndHandlers}>{props.controller.modalSlots}</Modal>` work?
    //                                                                  ^^ not a spread here, why is that important?
    //
    return () => <Modal {...props.controller.modalPropsAndHandlers}>{{...props.controller.modalSlots}}</Modal>
  }
})

export const DefaultTinySoccerballBusyOverlay = defineComponent({
  props: {
    color: vOptT(() => Client.value.clientTheme.color)
  },
  setup(props) {
    return () => (
      <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
        <div class="w-full h-full" style="background-color: rgba(255,255,255,0.5)">&nbsp;</div>
        <div style="position: absolute; top: 0; left: 0; margin-left:4px; margin-top:4px">
          <SoccerBall color={props.color} width=".2in" height=".2in" timeForOneRotation="1.25s"/>
        </div>
      </div>
    )
  }
})

export function useDefaultNoCloseModalIfBusy() {
  const busy = ref(false);

  const onCloseCB = (cb: () => void) => {
    if (busy.value) {
      return;
    }
    else {
      cb();
    }
  }

  return {
    busy,
    onCloseCB
  }
}

export const modal_defaultTitleClasses = "text-lg font-medium leading-6 text-gray-900"