import { arrayFindIndexOrFail, exhaustiveCaseGuard, parseIntOr, sortBy, sortByMany, vReqT } from "src/helpers/utils";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { MenuTree, payloadsForCompleteSelection } from "../UserInterface/MenuTree";
import { CandidateSearchResult, RefereeRosterImpl } from "./TournamentTeamConfigurator.referees.impl";
import { axiosInstance } from "src/boot/AxiosInstances";
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { AxiosInstance } from "axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { RefMutablesFormData } from "./TournamentTeamConfigurator.shared"
import { TournamentTeam, TournamentTeamOfficial } from "src/composables/InleagueApiV1.Tournament";
import { tournamentTeamStore } from "./Store/TournTeamStore";
import { Integerlike, isMenuSentinel } from "src/interfaces/InleagueApiV1";

type Menu_t = iltournament.TournTeamRefManagerMenu

export default defineComponent({
  setup() {
    const menu = ref<null | Menu_t>(null)

    onMounted(async () => {
      menu.value = await iltournament.getTournTeamRefManagerMenu(axiosInstance);
    })

    return () => {
      return menu.value ? <Impl menu={menu.value}/> : null;
    }
  }
})

const Impl = defineComponent({
  props: {
    menu: vReqT<Menu_t>(),
  },
  setup(props) {
    const menuSelections = ref({seasonUID: "", competitionUID: "", tournamentTeamID: ""})

    // consider: merge `resolvedWorkingData` and `selectionPayloadsIfCompleteSelection` so they are always atomically, together, either "something" or "nothing"
    const selectionPayloadsIfCompleteSelection = computed(() => payloadsForCompleteSelection(props.menu, menuSelections.value))
    const resolvedWorkingData = ref<{
      refList: TournamentTeamOfficial[],
      tournTeamOrUndefinedIfUnaffiliatedOrMultiple: TournamentTeam | undefined,
      canMakeEdits: boolean,
    }|null>(null)

    const getCurrentBindingTypeOrFail = () : iltournament.TournamentTeamOfficialBinding | {type: "all-affiliated", tournamentID: Integerlike<number>} => {
      if (!selectionPayloadsIfCompleteSelection.value) {
        throw Error("illegal state")
      }
      const menuPayload = selectionPayloadsIfCompleteSelection.value.for_tournamentTeamID
      if (isMenuSentinel(props.menu, menuPayload.entityID, "unaffiliated")) {
        const tournamentID = menuPayload.tournamentID;
        if (tournamentID === undefined) {
          throw Error("bad 'unaffiliated' menu payload")
        }

        return {
          type: "tournament",
          tournamentID: tournamentID,
        }
      }
      else if (isMenuSentinel(props.menu, menuPayload.entityID, "all-affiliated")) {
        const tournamentID = menuPayload.tournamentID;
        if (tournamentID === undefined) {
          throw Error("bad 'all' menu payload")
        }

        return {
          type: "all-affiliated",
          tournamentID: tournamentID,
        }
      }
      else {
        return {
          type: "tournamentTeam",
          tournamentTeamID: menuPayload.entityID as iltypes.Integerlike
        }
      }
    }

    const mostRecentCandidateSearchResult = ref<CandidateSearchResult>("no-search-yet")

    watch(() => selectionPayloadsIfCompleteSelection.value, async () => {
      mostRecentCandidateSearchResult.value = "no-search-yet";
      if (!selectionPayloadsIfCompleteSelection.value) {
        resolvedWorkingData.value = null;
      }
      else {
        const bindingType = getCurrentBindingTypeOrFail()
        if (bindingType.type === "all-affiliated") {
          resolvedWorkingData.value = {
            refList: await iltournament
              .listTournamentTeamOfficials(axiosInstance, {allAffiliatedRefs: true, tournamentID: bindingType.tournamentID})
              .then(officials => officials
                  .filter(official => official.type === iltournament.TournamentTeamOfficialType.REFEREE)
                  .sort(
                    sortByMany(
                      sortBy(_ => _.lastName),
                      sortBy(_ => _.firstName),
                      // we expect to have all the teamDesignation components here, but the types don't say it
                      sortBy(_ => parseIntOr(_.teamDesignationComponents?.divNum, -1)),
                      sortBy(_ => _.teamDesignationComponents?.divGender),
                      sortBy(_ => parseIntOr(_.teamDesignationComponents?.teamRegion, -1)),
                      sortBy(_ => parseIntOr(_.teamDesignationComponents?.teamNumber, -1)),
                  ))
                ),
            tournTeamOrUndefinedIfUnaffiliatedOrMultiple: undefined,
            canMakeEdits: (await iltournament.getCanMakeTournamentTeamEdits(axiosInstance, {tournamentID: bindingType.tournamentID})).canMakeEdits
          }
        }
        else {
          resolvedWorkingData.value = {
            refList: await iltournament
              .listTournamentTeamOfficials(axiosInstance, {binding: bindingType})
              .then(officials => officials.filter(official => official.type === iltournament.TournamentTeamOfficialType.REFEREE)),
            tournTeamOrUndefinedIfUnaffiliatedOrMultiple: bindingType.type === "tournament"
              ? undefined
              : await tournamentTeamStore.getTournamentTeamOrFail(bindingType.tournamentTeamID),
            canMakeEdits: (await iltournament.getCanMakeTournamentTeamEdits(
              axiosInstance,
              bindingType.type === "tournament" ? {tournamentID: bindingType.tournamentID}
                : bindingType.type === "tournamentTeam" ? {tournamentTeamID: bindingType.tournamentTeamID}
                : exhaustiveCaseGuard(bindingType)
            )).canMakeEdits
          }
        }
      }
    }, {immediate: true})

    const doCandidacyLookupByStackSid = async (axios: AxiosInstance, args: {stackSID: string, lastName: string}) => {
      const bindingType = getCurrentBindingTypeOrFail()
      if (bindingType.type === "all-affiliated") {
        throw Error("not supported")
      }

      try {
        const result = await iltournament.getTournamentTeamOfficialCandidateByStackSID(
          axios,
          {
            binding: bindingType,
            stackSID: args.stackSID,
            lastName: args.lastName,
            officialType: iltournament.TournamentTeamOfficialType.REFEREE,
          }
        )
        mostRecentCandidateSearchResult.value = result || "nothing-found"
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddRef = async (
      axios: AxiosInstance,
      stackInfo: {stackSID: string, lastName: string},
      form: RefMutablesFormData,
      selectedUserIdIfMatchingInleagueUser: undefined | iltypes.Guid,
      selectedRegionIfWillBeGeneratingUser: undefined | iltypes.Integerlike,
    ) : Promise<{ok: boolean}> => {
      if (!resolvedWorkingData.value) {
        // potentially this can change out from under us during the await,
        // but that absolutely shouldn't happen
        throw Error("Illegal state")
      }

      const bindingType = getCurrentBindingTypeOrFail();
      if (bindingType.type === "all-affiliated") {
        throw Error("not supported");
      }

      try {
        const fresh = await iltournament.createTournamentTeamOfficialByStackSID(axios, {
          binding: bindingType,
          officialType: iltournament.TournamentTeamOfficialType.REFEREE,
          stackSID: stackInfo.stackSID,
          lastName: stackInfo.lastName,
          maxCR: form.maxCR === "" ? undefined : form.maxCR,
          maxAR: form.maxAR === "" ? undefined : form.maxAR,
          ref_badgeLevel: form.ref_badgeLevel.text,
          comments: form.comments.text,
          selectedUserIdIfMatchingInleagueUser: selectedUserIdIfMatchingInleagueUser,
          selectedRegionIfWillBeGeneratingUser,
        })

        resolvedWorkingData.value.refList.unshift(fresh);
        mostRecentCandidateSearchResult.value = "no-search-yet"
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const doUpdateRef = async (axios: AxiosInstance, target: iltournament.TournamentTeamOfficial, form: RefMutablesFormData) : Promise<{ok: boolean}> => {
      try {
        await iltournament.updateTournamentTeamOfficial(axios, {
          tournamentTeamOfficialID: target.tournamentTeamOfficialID,
          officialType: iltournament.TournamentTeamOfficialType.REFEREE,
          maxCR: form.maxCR === "" ? null : form.maxCR,
          maxAR: form.maxAR === "" ? null : form.maxAR,
          comments: form.comments.text,
          ref_badgeLevel: form.ref_badgeLevel.text
        })

        // This assumes that `target` is a ref to some obj in `resolvedWorkingData.value?.refList`
        target.ref_maxAR = form.maxAR
        target.ref_maxCR = form.maxCR
        target.comments = form.comments.text;

        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const doRemoveRef = async (axios: AxiosInstance, target: iltournament.TournamentTeamOfficial) : Promise<void> => {
      if (!resolvedWorkingData.value) {
        // potentially this can change out from under us during the await,
        // but that absolutely shouldn't happen
        throw Error("Illegal state")
      }

      try {
        await iltournament.deleteTournamentTeamOfficialByTournamentTeamOfficialID(axios, {tournamentTeamOfficialID: target.tournamentTeamOfficialID})
        const idx = arrayFindIndexOrFail(resolvedWorkingData.value.refList, v => v.tournamentTeamOfficialID.toString() === target.tournamentTeamOfficialID.toString())
        resolvedWorkingData.value.refList.splice(idx, 1);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    return () => {
      return (
        <div>
          <div class="hidden sm:block max-w-6xl mx-auto" style="--fk-bg-input: white;">
            <div class="my-4 bg-gray-200">
              <MenuTree class="p-2 max-w-sm mx-auto" menuDef={props.menu} mut_selections={menuSelections.value}/>
            </div>
          </div>
          {
            selectionPayloadsIfCompleteSelection.value && resolvedWorkingData.value
              ? <>
                  <div class="rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none p-2">
                    <RefereeRosterImpl
                        shouldDisplayAddRef={
                          // cannot add refs if we are looking at "all tourn teams", because we wouldn't know what team to add them to
                          getCurrentBindingTypeOrFail().type !== "all-affiliated"
                            && resolvedWorkingData.value.canMakeEdits
                        }
                        tournTeamOrUndefinedIfUnaffiliatedOrMultiple={resolvedWorkingData.value.tournTeamOrUndefinedIfUnaffiliatedOrMultiple}
                        mostRecentCandidateSearchResult={mostRecentCandidateSearchResult.value}
                        mut_existingRefs={resolvedWorkingData.value?.refList}
                        doCandidacyLookupByStackSid={doCandidacyLookupByStackSid}
                        doAddRef={doAddRef}
                        doRemoveRef={doRemoveRef}
                        doUpdateRef={doUpdateRef}
                        canMakeEdits={resolvedWorkingData.value.canMakeEdits}
                    />
                  </div>
              </>
              : null
          }
        </div>
      )
    }
  }
})
