import { AxiosInstance } from "axios"
import { DateTimelike, Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1"

export type Conflict =
  {
    type: 'game',
    entityID: /*gameID*/ string,
    start: DateTimelike,
    end: DateTimelike,
    fieldUID: Guid
  }
  | {
    type: 'field-block',
    entityID: string,
    start: DateTimelike,
    end: DateTimelike,
    fieldUID: Guid
  }
  | {
    // for separate requests that overlap eachother
    type: 'intra-request',
    /**
     * index of the other request this conflicts with.
     * The "other" should have a mirrored intra-request conflict pointing to this (they conflict with each other).
     */
    otherIndex: number,
  }

export interface DryRunResult {
  perExpandedRequest: {
    // the row from the originating request
    // a single row can expand to multiple game requests based on slotCount and repeatWeeks
    originatingRequestIndex: number,
    competitionUID: Guid,
    seasonUID: Guid,
    divID: Guid,
    fieldUID: Guid,
    slotCount: Integerlike,
    dateTime: DateTimelike,
    slotGameDurationMinutes: Integerlike,
    repeatWeeks: Integerlike,
    comment: string,
    playoff: boolean,
    pointsCount: boolean,
    genderNeutral: boolean,
    blockFromMatchmaker: boolean,
    poolID: "ALL" | Integerlike,
    tags: string[],
    homeTeamID: "" | Guid,
    visitorTeamID: "" | Guid,
  },
  ui: UiCentricGameInfo,
  sheetRequestedDisregardConflicts: boolean,
  conflicts: Conflict[]
}

export interface UiCentricGameInfo {
  /**
   * A "createGame" dryrun will have no generated {gameID, gameNum}
   */
  gameID: Guid | null,
  gameNum: number | null,
  competition: string,
  competitionUID: Guid,
  seasonUID: Guid,
  seasonName: string,
  division: string,
  divID: Guid,
  /**
   * name of field
   */
  field: string,
  fieldUID: Guid,
  gameStart: DateTimelike,
  gameEnd: DateTimelike,
  /**
   * TODO: rename, this is actually "homeTeamDesignation"
   */
  homeTeamName: string,
  homeTeamID: Guid,
  /**
   * TODO: rename, this is actually "visitorTeamDesignation"
   */
  visitorTeamName: string,
  visitorTeamID: Guid,
}

/**
 * "drop-request" means the same thing for a request having no conflicts and a request having some conflicts
 * "ignore-and-create" probably needs a better name, or a 3rd option -- for a request having conflicts, it's
 * name is sensical. For a request having no conflicts, it's the "default" option, and means essentially
 * "cool, that's nice, there were no conflicts, OK."
 */
export type ConflictAck = "drop-request" | "ignore-and-create"

export interface FormAugmentedDryRunResult extends DryRunResult {
  conflictAck:
    | null // initial "no value" form value
    | ConflictAck
}

export async function createGamesDryRunFromXlsx(ax: AxiosInstance, args: {file: Blob}) : Promise<DryRunResult[]> {
  const formData = new FormData()
  formData.append('uploadDocument', args.file)
  const response = await ax.post(
    `/v1/gameScheduler/xlsx/games/dry-run`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
  return response.data.data;
}

/**
 * These are implemented in terms of "just generate the URL" (rather than "accept an axios instance and get the thing")
 * because we might want to use it as a URL in an <a> tag. However, the user experience is not great when the user's
 * permissions are expired and they click such a link -- the browser just reports that the download failed, and we
 * won't get any indication about why (like "oh hey we should offer to refresh their credentials or log back in").
 */
export const xlsxDownloadURLs = {
  xlsxCreateGamesTemplateUrl: (urlBase_schemeHostPortPath: string) => {
    return `${urlBase_schemeHostPortPath}/v1/gameScheduler/xlsx/games/template`
  },
  xlsxUpdateGameTeamAssignmentsTemplateUrl: (
    urlBase_schemeHostPortPath: string, args: {
      competitionUID: Guid,
      divIDs: Guid[],
      dateFromInclusive?: Datelike,
      dateToInclusive?: Datelike
    }) => {
    const queryBuilder : string[] = [
      `competitionUID=${args.competitionUID}`,
      args.divIDs.map(divID => `divIDs[]=${divID}`).join("&"),
    ]

    if (args.dateFromInclusive) {
      queryBuilder.push(`dateFromInclusive=${args.dateFromInclusive}`);
    }
    if (args.dateToInclusive) {
      queryBuilder.push(`dateToInclusive=${args.dateToInclusive}`);
    }

    const query = queryBuilder.join("&")

    return `${urlBase_schemeHostPortPath}/v1/gameScheduler/teamAssignments/xlsx/template?${query}`
  }
} as const;

export async function updateGameTeamAssignmentsDryRunFromXlsx(ax: AxiosInstance, args: {file: Blob}) : Promise<UiCentricGameInfo[]> {
  const options = new FormData()
  options.append('uploadDocument', args.file)
  const response = await ax.post(`v1/gameScheduler/teamAssignments/xlsx/dry-run`, options);
  return response.data.data;
}

export async function updateGameTeamAssignmentsFromXlsx(ax: AxiosInstance, args: {file: Blob}) : Promise<UiCentricGameInfo[]> {
  const options = new FormData()
  options.append('uploadDocument', args.file)
  const response = await ax.post(`v1/gameScheduler/teamAssignments/xlsx`, options);
  return response.data.data;
}

export interface UpdateGameTeamAssignments {
  competitionUID: Guid,
  divID: Guid,
  gameID: Guid,
  homeTeamTeamID: Guid | null,
  visitorTeamTeamID: Guid | null
}
