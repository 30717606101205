import { SelectMany } from "src/components/RefereeSchedule/SelectMany";
import { DefaultModalController, AutoModal } from "src/components/UserInterface/Modal";
import { vReqT, UiOption, sortBy, arrayFindIndexOrFail, unsafe_objectKeys } from "src/helpers/utils";
import { RegistrationQuestion, Guid } from "src/interfaces/InleagueApiV1";
import { defineComponent, Ref, computed, ref } from "vue";
import { SelectedQuestions } from "./TeamAssignments.shared";
import { Client } from "src/store/Client";

export const SelectRegistrationQuestionsModal = defineComponent({
  props: {
    customRegistrationQuestions: vReqT<RegistrationQuestion[]>(),
    selectedQuestions: vReqT<SelectedQuestions>(),
    out_controller: vReqT<Ref<null | {open: () => void, close: () => void}>>()
  },
  setup(props) {
    type WellKnownOpts = Exclude<keyof SelectedQuestions["wellKnown"], "playerName">
    const variousByNameOptions = computed(() => {
      return [
        {label: "Rating avg", value: "ratingAvg"},
        {label: "Newest rating", value: "newestRating"},
        {label: "DOB", value: "dob"},
        {label: "Registration date", value: "regDate"},
        {label: "Height", value: "height"},
        {label: "Weight", value: "weight"},
        {label: "Grade", value: "grade"},
        {label: "School", value: "school"},
        {label: "Waitlist Status", value: "waitlisted"},
        {label: "City", value: "city"},
        {label: "Zip code", value: "zip"},
        {label: "Street", value: "street"},
        {label: "Member of coach's family", value: "memberOfCoachesFamily"},
        ...!!Client.value.instanceConfig.regnopractice
          ? [{label: "No practice day", value: "noPracticeDay" as const}]
          : [],
        {label: "Comments", value: "comments"}
      ] satisfies UiOption<WellKnownOpts>[]
    })

    const customRegQuestionOptions = computed<UiOption[]>(() => {
      return props.customRegistrationQuestions.map(q => ({label: q.shortLabel, value: q.id}))
    })

    const selectedNames = computed(() => {
      return Object
        .entries(props.selectedQuestions.wellKnown)
        .filter(([name, isSelected] : Entry) => {
          const isValidOption = !!variousByNameOptions.value.find(v => v.value === name)
          return isValidOption && !!isSelected
        })
        .map(([name] : Entry) => name)

      type Entry = [string, boolean | undefined]
    })

    const selectedCustomQuestionIDs = computed(() => {
      return props.selectedQuestions.customQuestionIDs
    });

    const controller = ref(DefaultModalController<void>({
      title: () => (
        <>
          <div>Registration questions</div>
          <div class="border-b border-gray-200 mb-4"/>
        </>
      ),
      content: () => {
        return (
          // Usually we want no margin for fk-margin-outer, but the tiny margin here fixes up
          // horizontal clipping of the checkbox box in the overflow-y case
          // (i.e. when there is a vertical scrollbar)
          <div style="--fk-margin-outer:2px;">
            <div class="overflow-y-auto" style="max-height: 75vh;">
              <div class="bg-white">
                <div class="p-1 bg-gray-200">
                  Standard Player Data
                </div>
                <div class="px-1">
                  <SelectMany
                    selectedKeys={selectedNames.value}
                    options={variousByNameOptions.value}
                    offerAllOption={true}
                    onCheckedAll={checked => {
                      for (const opt of variousByNameOptions.value) {
                        props.selectedQuestions.wellKnown[opt.value] = checked
                      }
                    }}
                    onCheckedOne={(questionID, checked) => {
                      props.selectedQuestions.wellKnown[questionID as keyof SelectedQuestions["wellKnown"]] = checked;
                    }}
                  />
                </div>
              </div>
              <div class="bg-white">
                <div class="p-1 bg-gray-200">
                  League-Specific Registration Questions
                </div>
                <div class="px-1">
                  <SelectMany
                    selectedKeys={selectedCustomQuestionIDs.value}
                    options={customRegQuestionOptions.value}
                    offerAllOption={true}
                    onCheckedAll={checked => {
                      props.selectedQuestions.customQuestionIDs.splice(0);
                      if (checked) {
                        for (const questionID of props.customRegistrationQuestions.map(v => v.id)) {
                          props.selectedQuestions.customQuestionIDs.push(questionID)
                        }
                      }
                    }}
                    onCheckedOne={(questionID, checked) => {
                      if (checked) {
                        props.selectedQuestions.customQuestionIDs.push(questionID);
                        props.selectedQuestions.customQuestionIDs.sort(
                          sortBy(_ => {
                            const questionIdx = arrayFindIndexOrFail(props.customRegistrationQuestions, v => v.id === _)
                            return questionIdx
                          })
                        );
                      }
                      else {
                        const idx = arrayFindIndexOrFail(props.selectedQuestions.customQuestionIDs, _ => _ === questionID);
                        props.selectedQuestions.customQuestionIDs.splice(idx, 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
    }))

    props.out_controller.value = controller.value;

    return () => <AutoModal controller={controller.value}/>
  }
})
