import { faFileExport, faClipboard, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { EscapedRegExp, Reflike, UiOption, accentAwareCaseInsensitiveCompare, arrayFindIndexOrFail, downloadFromObjectURL, exhaustiveCaseGuard, forceCheckedIndexedAccess, maybePrettifyUSPhoneNumber, requireNonNull, sortBy, sortByDayJS, sortByMany, sortByMap } from "src/helpers/utils";
import { vReqT, parseIntOr } from "src/helpers/utils";
import { ColDef, freshSortState, sortDefsAsSorter, SortArrow, getColDefHtml, rowsToXlsxSimpleSheetDef, rowsToCsv, BasicTable, typedBasicTableProps, getFilterableStringForCell } from "src/modules/TableUtils";
import { defineComponent, computed, ref, Fragment, reactive, onMounted } from "vue";

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Paginated } from "src/modules/PaginationUtils";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import { multiSheetXlsxFromSheetDefs } from "src/modules/XlsxUtils";
import { DefaultMenuButton, Menu, MenuItem, MenuItemSlots } from "./Menu";
import { isAuthorizedToManageTournament, teamDesignation, tournTeamStatusUiString } from "./TournamentTeamUtils";
import { dayjsFormatOr, dayjsOr } from "src/helpers/formatDate";
import dayjs, { OpUnitType } from "dayjs";
import { TournamentTeam, TournamentTeamOfficialType, TournamentTeamOverviewReport, TournamentTeamOverviewReport_Coach, TournamentTeamOverviewReport_Ref, TournamentTeamOverviewReport_Team } from "src/composables/InleagueApiV1.Tournament";
import { DivID, Division, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { FilterManager, TournTeamDisplayFilter, computeTournTeamStatusForFiltering } from "./TournTeamDisplayFilter";
import { Client } from "src/store/Client";
import { SelectManyPane, SlotProps as SelectManySlotProps } from "../RefereeSchedule/SelectManyPane";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FormKit } from "@formkit/vue";
import { User } from "src/store/User";
import { getRefBadgeInfo } from "./TournamentTeamConfigurator.shared";
import { TabDef, Tabs } from "../UserInterface/Tabs";
import { Btn2 } from "../UserInterface/Btn2";
import { X } from "../SVGs";

export const TournamentOverviewReport = defineComponent({
  props: {
    seasonName: vReqT<string>(),
    competitionName: vReqT<string>(),
    competitionUID: vReqT<Guid>(),
    report: vReqT<iltournament.TournamentTeamOverviewReport>(),
    openRefEditModal: vReqT<(_: AugmentedRefRow) => void>(),
  },
  setup(props) {
    const groupedByTeamConfig = (() => {
      const itemsPerPage = ref<iltypes.Integerlike | "ALL">(25)
      const itemsPerPageOptions : UiOption[] = [
        {label: "25", value: "25"},
        {label: "50", value: "50"},
        {label: "All", value: "ALL"}
      ]

      const htmlTeamColDefs = freshTeamColDefs("html")

      const teamSortState = freshSortState(htmlTeamColDefs)
      teamSortState.reconfigure([
        {colID: TeamColName.division, dir: "asc"},
        {colID: TeamColName.region, dir: "asc"},
      ])

      const applySort = (rows: readonly iltournament.TournamentTeamOverviewReport_Team[]) => {
        return [...rows].sort(sortDefsAsSorter(teamSortState.sortList))
      }

      const sortedRows = computed(() => applySort(props.report.tournamentTeams));

      const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

      return {
        htmlTeamColDefs,
        pagination,
        teamSortState,
        itemsPerPage,
        itemsPerPageOptions
      }
    })()

    const filenamePrefix = computed(() => `TournamentTeam_${props.seasonName}_${props.competitionName}`.replaceAll(/[ \\\/]/g, "_"));

    const downloadAsXlsx = async () : Promise<void> => {
      const bytes = await multiSheetXlsxFromSheetDefs([
        tabularLayoutTableConfigs.teamInfo.currentXlsxSheetDef(),
        tabularLayoutTableConfigs.coaches.currentXlsxSheetDef(),
        tabularLayoutTableConfigs.referees.currentXlsxSheetDef(),
        tabularLayoutTableConfigs.registrationAnswers.currentXlsxSheetDef(),
      ])

      downloadFromObjectURL(bytes, filenamePrefix.value + ".xlsx")
    }

    const downloadAsCsv = {
      teamInfo: () : void => {
        const csv = tabularLayoutTableConfigs.teamInfo.currentCsvData()
        downloadFromObjectURL(csv, filenamePrefix.value + "_team.csv")
      },
      coaches: () => {
        const csv = tabularLayoutTableConfigs.coaches.currentCsvData()
        downloadFromObjectURL(csv, filenamePrefix.value + "_coaches.csv")
      },
      referees: () : void => {
        const csv = tabularLayoutTableConfigs.referees.currentCsvData()
        downloadFromObjectURL(csv, filenamePrefix.value + "_refs.csv")
      },
      registrationAnswers: () : void => {
        const csv = tabularLayoutTableConfigs.registrationAnswers.currentCsvData()
        downloadFromObjectURL(csv, filenamePrefix.value + "_answers.csv")
      },
    } as const;

    const copyToClipboardAsCsv = {
      teamInfo: () : void => {
        const csv = tabularLayoutTableConfigs.teamInfo.currentCsvData()
        navigator.clipboard.writeText(csv)
      },
      coaches: () : void => {
        const csv = tabularLayoutTableConfigs.coaches.currentCsvData()
        navigator.clipboard.writeText(csv)
      },
      referees: () : void => {
        const csv = tabularLayoutTableConfigs.referees.currentCsvData()
        navigator.clipboard.writeText(csv)
      },
      registrationAnswers: () : void => {
        const csv = tabularLayoutTableConfigs.registrationAnswers.currentCsvData()
        navigator.clipboard.writeText(csv)
      },
    } as const;

    const selectedTableSections = ref({
      [TableSection.registrationAnswers]: false,
      [TableSection.referees]: false,
      [TableSection.unaffiliatedRefs]: false,
      [TableSection.coaches]: false,
    })

    const groupedByTeam = () => {
      return (
        <div>
          <div class="max-w-7xl w-full border border-slate-100 shadow-md bg-white p-1">
            <div class="flex p-2">
              <div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.registrationAnswers]}/>
                  <div>Registration Answers</div>
                </div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.referees]}/>
                  <div>Referees</div>
                </div>
                <div class={`flex items-center gap-1 ${props.report.unaffiliatedRefs.length === 0 ? "opacity-50" : ""}`} >
                  <input type="checkbox" v-model={selectedTableSections.value[TableSection.unaffiliatedRefs]} disabled={props.report.unaffiliatedRefs.length === 0}/>
                  <div>Unaffiliated referees {props.report.unaffiliatedRefs.length === 0 ? "(none)" : ""}</div>
                </div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.coaches]}/>
                  <div>Coaches</div>
                </div>
              </div>
            </div>
          </div>
          {
            selectedTableSections.value[TableSection.unaffiliatedRefs]
              ? (
                <div class="max-w-7xl w-full overflow-x-auto border border-slate-100 shadow-md bg-white p-1 mt-4">
                  {
                    props.report.unaffiliatedRefs.length === 0
                      ? <div>No unaffiliated referees</div>
                      : (
                        <div class="p-2 w-full">
                          <NestedObjLayout_RefsLayout
                            openRefEditModal={props.openRefEditModal}
                            refs={props.report.unaffiliatedRefs.map((ref) : AugmentedRefRow => ({
                              teamDesignation: "Unafilliated",
                              teamName: "Unaffiliated",
                              refAssignmentsByTeamByUser: props.report.refAssignmentsByUserID,
                              ...ref
                            }))}
                            teamDesignation={"Unaffiliated"}
                            competitionUID={props.competitionUID}
                          />
                        </div>
                      )
                  }
                </div>
              )
              : null
          }
          <div class="max-w-7xl w-full overflow-x-auto border border-slate-100 shadow-md bg-white p-1 mt-4">
            <table class="w-full">
              <tr>
                {
                  groupedByTeamConfig.htmlTeamColDefs.map(colDef => {
                    return (
                      <th class="p-2 align-top text-left">
                        <div class="flex items-center text-sm">
                          <span>{colDef.label}</span>
                          {
                            ((sorter?: typeof groupedByTeamConfig.teamSortState.sortersByColID["<someid>"]) => {
                              return sorter
                                ? (
                                  <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                    <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                  </span>
                                )
                                : null
                            })(groupedByTeamConfig.teamSortState.sortersByColID[colDef.id])
                          }
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
              {
                props.report.tournamentTeams.length === 0
                  ? <tr><td colspan="999" class="p-4">No tournament teams found</td></tr>
                  : null
              }
              {
                groupedByTeamConfig.pagination.value.pageData.itemsThisPage.map((row, i) => {
                  const bgColor = i % 2 ? "bg-gray-50" : ""

                  const trMaybeBottomBorderClass = (rowID: "first" | TableSection) => {
                    // uh there's probably a nicer css way to do this,
                    // and this isn't robust if we reorder the actual layout (need to remember to reorder this, too)
                    const order : [rowID: "first" | TableSection, isVisible: () => boolean][] = [
                      ["first", () => true],
                      [TableSection.registrationAnswers, () => selectedTableSections.value[TableSection.registrationAnswers]],
                      [TableSection.referees, () => selectedTableSections.value[TableSection.referees]],
                      [TableSection.coaches, () => selectedTableSections.value[TableSection.coaches]],
                    ]

                    const idx = arrayFindIndexOrFail(order, v => v[0] === rowID)

                    for (let i = idx+1; i < order.length; i++) {
                      if (order[i][1]()) {
                        // rows "after" the current row exist, so return no class
                        return "";
                      }
                    }

                    return "border-b border-slate-400";
                  }

                  return (
                    <Fragment key={row.tournamentTeamID}>
                      <tr class={`${bgColor} ${trMaybeBottomBorderClass("first")}`}>
                        {
                          groupedByTeamConfig.htmlTeamColDefs.map(colDef => <td class={`p-2 align-top text-left`}>{getColDefHtml(colDef, row)}</td>)
                        }
                      </tr>
                      {
                        selectedTableSections.value[TableSection.registrationAnswers]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.registrationAnswers)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <div class="text-sm font-medium">Question answers</div>
                                {
                                  row.tournamentQuestionLinks.map(q => {
                                    return (
                                      <div class="pl-2 flex items-center">
                                        <div class="text-sm">{q.label}&nbsp;&mdash;&nbsp;</div>
                                        {
                                          q.answers.map(_ => _.answer).join(",") || "N/A"
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </td>
                            </tr>
                          )
                          : null
                      }
                      {
                        selectedTableSections.value[TableSection.referees]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.referees)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <NestedObjLayout_RefsLayout
                                  refs={extractAugmentedRowsForRefRowify(props.report.refAssignmentsByUserID, row)}
                                  teamDesignation={teamDesignation(row)}
                                  openRefEditModal={props.openRefEditModal}
                                  competitionUID={props.competitionUID}
                                />
                              </td>
                            </tr>
                          )
                          : null
                      }
                      {
                        selectedTableSections.value[TableSection.coaches]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.coaches)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <NestedObjLayout_CoachesLayout row={row}/>
                              </td>
                            </tr>
                          )
                          : null
                      }
                    </Fragment>
                  )
                })
              }
            </table>
            <div>
              {
                props.report.tournamentTeams.length > 0
                  ? (
                    <div class="flex">
                      <div class="ml-auto p-2">
                        <SimplePaginationNav mut_pagination={groupedByTeamConfig.pagination.value} mut_itemsPerPage={groupedByTeamConfig.itemsPerPage} itemsPerPageOptions={groupedByTeamConfig.itemsPerPageOptions}/>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>
      )
    }

    const tabularLayoutTableConfigs = (() => {
      const teamInfo = (() => {
        const filters : Partial<Record<TeamColName, FilterLike>> = {
          [TeamColName.teamDesignation]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.division]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.region]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.teamName]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.status]: (() => {
            const value = ref("")
            const filter = computed(() => simpleListExactMatchFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.submitterName]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [TeamColName.dateCreated]: (() => {
            const value = ref("")
            const filter = computed(() => simpleDatetimeFilter(value.value.trim(), "day"))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
        };

        const colDefs = ref(freshTeamColDefs("html", filters));
        const unfilteredSource = computed(() => props.report.tournamentTeams)
        const filteredData = computed(() => filterRunner(unfilteredSource.value, filters, colDefs.value));

        const sortState = ref(freshSortState(colDefs.value))
        sortState.value.reconfigure([
          {colID: TeamColName.teamDesignation, dir: "asc"}
        ])

        const sortedData = computed(() => [...filteredData.value].sort(sortState.value.asSorter()));

        const tableProps = computed(() => typedBasicTableProps({
          colDefs: colDefs.value,
          noData: () => <div class="p-2">No data</div>,
          rowData: sortedData.value,
          rowKey: v => v.teamID,
          sortState: sortState.value,
        }))

        const unfilteredSorted = computed(() => [...unfilteredSource.value].sort(sortState.value.asSorter()))

        return {
          colDefs,
          sortState,
          sortedData,
          get tableProps() { return tableProps.value },
          currentXlsxSheetDef: () => rowsToXlsxSimpleSheetDef(freshTeamColDefs("xlsx"), unfilteredSorted.value, "TeamInfo"),
          currentCsvData: () => rowsToCsv(freshTeamColDefs("xlsx"), unfilteredSorted.value),
        }
      })();

      const coaches = (() => {
        const filters : Partial<Record<CoachColName, FilterLike>> = {
          [CoachColName.teamDesignation]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [CoachColName.division]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [CoachColName.region]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [CoachColName.name]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [CoachColName.email]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
        };

        const colDefs = ref(freshCoachColDefs("html", filters));
        const unfilteredSource = computed(() => props.report.tournamentTeams.flatMap(extractAugmentedRowsForCoachRowify))
        const filteredData = computed(() => filterRunner(unfilteredSource.value, filters, colDefs.value));

        const sortState = ref(freshSortState(colDefs.value))
        sortState.value.reconfigure([
          {colID: CoachColName.teamDesignation, dir: "asc"},
        ])

        const sortedData = computed(() => [...filteredData.value].sort(sortState.value.asSorter()))

        const tableProps = computed(() => typedBasicTableProps({
          colDefs: colDefs.value,
          noData: () => <div class="p-2">No data</div>,
          rowData: sortedData.value,
          rowKey: v => v.tournamentTeamOfficialID,
          sortState: sortState.value,
        }))

        const unfilteredSorted = computed(() => [...unfilteredSource.value].sort(sortState.value.asSorter()))

        return {
          colDefs,
          sortState,
          sortedData,
          get tableProps() { return tableProps.value },
          currentXlsxSheetDef: () => rowsToXlsxSimpleSheetDef(freshCoachColDefs("xlsx"), unfilteredSorted.value, "Coaches"),
          currentCsvData: () => rowsToCsv(freshCoachColDefs("xlsx"), unfilteredSorted.value)
        }
      })();

      const referees = (() => {
        const filters : Partial<Record<RefColName, FilterLike>> = {
          [RefColName.teamDesignation]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [RefColName.division]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [RefColName.region]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [RefColName.headCoachNames]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [RefColName.name]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [RefColName.refEmail]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
        };

        const unaffiliatedRefs = props.report.unaffiliatedRefs.map((ref) : AugmentedRefRow => ({
          teamName: "Unaffiliated",
          teamDesignation: "Unaffiliated",
          refAssignmentsByTeamByUser: props.report.refAssignmentsByUserID,
          ...ref
        }));


        const colDefs = ref(freshRefColDefs("html", null, filters));
        const unfilteredSource = computed(() => [...props.report.tournamentTeams.flatMap(row => extractAugmentedRowsForRefRowify(props.report.refAssignmentsByUserID, row)), ...unaffiliatedRefs])
        const filteredData = computed(() => filterRunner(unfilteredSource.value, filters, colDefs.value))

        const sortState = ref(freshSortState(colDefs.value))
        sortState.value.reconfigure([
          {colID: RefColName.teamDesignation, dir: "asc"},
        ])

        const sortedData = computed(() => [...filteredData.value].sort(sortState.value.asSorter()))

        const tableProps = computed(() => typedBasicTableProps({
          colDefs: colDefs.value,
          noData: () => <div class="p-2">No data</div>,
          rowData: sortedData.value,
          rowKey: v => v.tournamentTeamOfficialID,
          sortState: sortState.value,
        }))

        const unfilteredSorted = computed(() => [...unfilteredSource.value].sort(sortState.value.asSorter()))

        return {
          colDefs,
          sortState,
          sortedData,
          get tableProps() { return tableProps.value },
          currentXlsxSheetDef: () => rowsToXlsxSimpleSheetDef(freshRefColDefs("xlsx"), unfilteredSorted.value, "Refs"),
          currentCsvData: () => rowsToCsv(freshRefColDefs("xlsx"), unfilteredSorted.value),
        }
      })();

      const registrationAnswers = (() => {
        const filters : Partial<Record<TeamColName, FilterLike>> = {
          [AnswerColName.teamDesignation]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [AnswerColName.division]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [AnswerColName.region]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
          [AnswerColName.teamName]: (() => {
            const value = ref("")
            const filter = computed(() => simpleSubstringFilter(value.value.trim()))
            return reactive({value, filter, clear: () => value.value = ""})
          })(),
        };

        const colDefs = computed(() => freshAnswersColDefs("html", props.report.tournamentTeams, filters));
        const unfilteredSource = computed(() => [...props.report.tournamentTeams])
        const filteredData = computed(() => filterRunner(unfilteredSource.value, filters, colDefs.value))

        const sortState = ref(freshSortState(colDefs.value))
        sortState.value.reconfigure([
          {colID: AnswerColName.teamDesignation, dir: "asc"},
        ])

        const sortedData = computed(() => [...filteredData.value].sort(sortState.value.asSorter()))
        const tableProps = computed(() => typedBasicTableProps({
          colDefs: colDefs.value,
          noData: () => <div class="p-2">No data</div>,
          rowData: sortedData.value,
          rowKey: v => v.teamID,
          sortState: sortState.value,
        }))

        const unfilteredSorted = computed(() => [...unfilteredSource.value].sort(sortState.value.asSorter()))

        return {
          colDefs,
          sortState,
          sortedData,
          get tableProps() { return tableProps.value },
          currentXlsxSheetDef: () => rowsToXlsxSimpleSheetDef(freshAnswersColDefs("xlsx", unfilteredSorted.value), sortedData.value, "Answers"),
          currentCsvData: () => rowsToCsv(freshAnswersColDefs("xlsx", unfilteredSorted.value), unfilteredSorted.value),
        }
      })();

      return {
        teamInfo,
        coaches,
        referees,
        registrationAnswers,
      }
    })();

    const tabularTabDefs : TabDef[] = [
      {
        label: "Team Info",
        render: () => <BasicTable class="min-w-full" {...tabularLayoutTableConfigs.teamInfo.tableProps}/>
      },
      {
        label: "Coaches",
        render: () => <BasicTable class="min-w-full" {...tabularLayoutTableConfigs.coaches.tableProps}/>
      },
      {
        label: "Referees",
        render: () => <BasicTable class="min-w-full" {...tabularLayoutTableConfigs.referees.tableProps}/>
      },
      {
        label: "Registration answers",
        render: () => <BasicTable class="min-w-full" {...tabularLayoutTableConfigs.registrationAnswers.tableProps}/>
      },
    ];

    const tabular = () => {
      return (
        <div>
          <div class="w-full flex mb-2">
            <div class="flex gap-2 ml-auto">
              <Menu>
                {{
                  button: () => (
                    <Btn2 class="px-2 py-1">
                      <FontAwesomeIcon icon={faFileExport}/>
                      <span class="ml-1 text-xs">As CSV</span>
                    </Btn2>
                  ),
                  menuItems: () => (
                    <>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => downloadAsCsv.teamInfo()}>Team info</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => downloadAsCsv.coaches()}>Coaches</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => downloadAsCsv.referees()}>Refs</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => downloadAsCsv.registrationAnswers()}>Registration answers</DefaultMenuButton>
                      </MenuItem>
                    </>
                  )
                } satisfies MenuItemSlots}
              </Menu>
              <Btn2 class="px-2 py-1" onClick={downloadAsXlsx} v-tooltip={{content: "Contains 5 sheets (team info, answers, refs, coaches, unaffiliated refs)"}}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As Excel</span>
              </Btn2>
              <Menu>
                {{
                  button: () => (
                    <Btn2 class="px-2 py-1">
                      <FontAwesomeIcon icon={faClipboard}/>
                      <span class="ml-1 text-xs">Copy</span>
                    </Btn2>
                  ),
                  menuItems: () => (
                    <>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => copyToClipboardAsCsv.teamInfo()}>Team info</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => copyToClipboardAsCsv.coaches()}>Coaches</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => copyToClipboardAsCsv.referees()}>Refs</DefaultMenuButton>
                      </MenuItem>
                      <MenuItem>
                        <DefaultMenuButton onClick={() => copyToClipboardAsCsv.registrationAnswers()}>Registration answers</DefaultMenuButton>
                      </MenuItem>
                    </>
                  )
                } satisfies MenuItemSlots}
              </Menu>
            </div>
          </div>
          <div id="TournamentTeamReportImpl-tabular">
            <style>{`
              #TournamentTeamReportImpl-tabular table.il-BasicTable {
                border-collapse: collapse;
              }
              #TournamentTeamReportImpl-tabular table.il-BasicTable th,
              #TournamentTeamReportImpl-tabular table.il-BasicTable td {
                border: 1px solid #CCC;
              }
            `}
            </style>
            <Tabs tabDefs={tabularTabDefs}/>
          </div>
        </div>
      )
    }

    const tabDefs : TabDef[] = [
      {
        label: "Tabular",
        render: tabular
      },
      {
        label: "Grouped by team",
        render: groupedByTeam
      }
    ]

    return () => (
      <div id="TournamentTeamReportImpl">
        <Tabs class="max-w-screen-xl" tabDefs={tabDefs}/>
      </div>
    )
  }
})

const NestedObjLayout_RefsLayout = defineComponent({
  props: {
    teamDesignation: vReqT<string>(),
    refs: vReqT<AugmentedRefRow[]>(),
    openRefEditModal: vReqT<(_: AugmentedRefRow) => void>(),
    competitionUID: vReqT<Guid>(),
  },
  setup(props) {
    const htmlRefColDefs = computed(() => freshRefColDefs("html", {openRefEditModal: props.openRefEditModal, competitionUID: props.competitionUID}))

    const sortState = freshSortState(htmlRefColDefs.value);

    const sortedRows = computed(() => {
      return props.refs.sort(sortState.asSorter())
    })

    const itemsPerPage = ref<"ALL" | iltypes.Integerlike>("10")
    const itemsPerPageOptions : UiOption[] = [
      {label: "10", value: "10"},
      {label: "20", value: "20"},
      {label: "All", value: "ALL"},
    ]

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

    return () => {
      return (
        <div class="w-full overflow-x-auto">
          <div class="text-sm font-medium">Referees for {props.teamDesignation}</div>
          {
            props.refs.length === 0
              ? <div class="flex items-center justify-center p-4"><span>None found</span></div>
              : (
                  <>
                    <table class="w-full">
                      {
                        htmlRefColDefs.value.map(colDef => {
                          return (
                            <th class="p-1 align-top text-left">
                              <div class="flex items-center text-sm">
                                <span>{colDef.label}</span>
                                {
                                  ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                    return sorter
                                      ? (
                                        <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                          <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                        </span>
                                      )
                                      : null
                                  })(sortState.sortersByColID[colDef.id])
                                }
                              </div>
                            </th>
                          )
                        })
                      }
                      {
                        pagination
                          .value
                          .pageData
                          .itemsThisPage
                          .map((rowData, i) => {
                            const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                            return (
                              <Fragment key={rowData.tournamentTeamOfficialID}>
                                <tr style={styles}>
                                  {
                                    htmlRefColDefs.value.map((colDef) => {
                                      return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                    })
                                  }
                                </tr>
                                <tr style={styles}>
                                  <td colspan="999" class="px-1 pb-1">
                                    <div class="text-xs font-medium">Comments:</div>
                                    <div>{rowData.comments || "N/A"}</div>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })
                      }
                    </table>
                    <div>
                    {
                      pagination.value.pageData.allItems.length > 0
                        ? (
                          <div class="flex text-xs">
                            <div class="ml-auto p-2">
                              <SimplePaginationNav itemsLabel={"Refs"} mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                            </div>
                          </div>
                        )
                        : null
                    }
                    </div>
                  </>
              )
          }
        </div>
      )
    }
  }
})

const NestedObjLayout_CoachesLayout = defineComponent({
  props: {
    row: vReqT<iltournament.TournamentTeamOverviewReport_Team>(),
  },
  setup(props) {
    const htmlCoachColDefs = freshCoachColDefs("html");
    const sortState = freshSortState(htmlCoachColDefs);

    const sortedRows = computed(() => {
      return extractAugmentedRowsForCoachRowify(props.row).sort(sortState.asSorter())
    })

    const itemsPerPage = ref<"ALL" | iltypes.Integerlike>("10")
    const itemsPerPageOptions : UiOption[] = [
      {label: "10", value: "10"},
      {label: "20", value: "20"},
      {label: "All", value: "ALL"},
    ]

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

    return () => {
      return (
        <div class="w-full overflow-x-auto">
          <div class="text-sm font-medium">Coaches for {teamDesignation(props.row)}</div>
          {
            props.row.tournamentTeamOfficialCoaches.length === 0
              ? <div class="flex items-center justify-center p-4"><span>None found</span></div>
              : (
                  <>
                    <table class="w-full">
                      {
                        htmlCoachColDefs.map(colDef => {
                          return (
                            <th class="p-1 align-top text-left">
                              <div class="flex items-center text-sm">
                                <span>{colDef.label}</span>
                                {
                                  ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                    return sorter
                                      ? (
                                        <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                          <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                        </span>
                                      )
                                      : null
                                  })(sortState.sortersByColID[colDef.id])
                                }
                              </div>
                            </th>
                          )
                        })
                      }
                      {
                        pagination
                          .value
                          .pageData
                          .itemsThisPage
                          .map((rowData, i) => {
                            const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                            return (
                              <Fragment key={rowData.tournamentTeamOfficialID}>
                                <tr style={styles}>
                                  {
                                    htmlCoachColDefs.map((colDef) => {
                                      return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                    })
                                  }
                                </tr>
                                <tr style={styles}>
                                  <td colspan="999" class="px-1 pb-1">
                                    <div class="text-xs font-medium">Comments:</div>
                                    <div>{rowData.comments || "N/A"}</div>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })
                      }
                    </table>
                    <div>
                    {
                      pagination.value.pageData.allItems.length > 0
                        ? (
                          <div class="flex text-xs">
                            <div class="ml-auto p-2">
                              <SimplePaginationNav itemsLabel={"Coaches"} mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                            </div>
                          </div>
                        )
                        : null
                    }
                    </div>
                  </>
              )
          }
        </div>
      )
    }
  }
})

enum TeamColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamLetter = "teamLetter",
  teamName = "teamName",
  submitterEmail = "submitterEmail",
  submitterName = "submitterName",
  playerCount = "playerCount",
  refCount = "refCount",
  status = "status",
  points = "points",
  dateCreated = "dateCreated",
}

/**
 * Like a `Some(v)` but only with a .map method, and where `map` does not return an instance of Optional
 */
class NonNullChainer<T> {
  readonly v : T
  constructor(v: T) {
    this.v = v
  }

  map<U>(f: (_:T) => U) : U {
    return f(this.v);
  }
}

/**
 * so we can write like `f(maybeNullButMaybeNot)?.map(v => doWorkWithNonNullValue) ?? null`
 */
function getOr<T>(v: T | null | undefined) {
  if (v) {
    return new NonNullChainer(v)
  }
  else {
    return undefined
  }
}

const ClearFilterButton_ = defineComponent({
  emits: {
    click: () => true,
  },
  setup(_, ctx) {
    return () => {
      return <button type="button" onClick={() => ctx.emit("click")} class="hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]">
        <X width="1em" height="1em"/>
      </button>
    }
  }
})

function ClearFilterButton({value, clear} : FilterLike) {
  return <ClearFilterButton_ class={`ml-1 p-1 rounded-md ${!value ? "invisible" : ""}`} onClick={() => clear()}/>
}

function freshTeamColDefs(mode: "xlsx" | "html", filters?: Partial<Record<TeamColName, FilterLike>>) : ColDef<iltournament.TournamentTeamOverviewReport_Team, TeamColName>[] {
  const colDefs : ColDef<iltournament.TournamentTeamOverviewReport_Team, TeamColName>[] = [
    {
      id: TeamColName.teamDesignation,
      label: "Team designation",
      html: v => `${v.div_gender}${v.div_divNum}-${v.team_region}-${v.mungedTeamLetter.id}`,
      filterElem: () => getOr(filters?.[TeamColName.teamDesignation])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: sortByMany(
        sortBy(v => v.div_divNum),
        sortBy(v => v.div_gender),
        sortBy(v => v.region),
      ),
    },
    {
      id: TeamColName.division,
      label: "Division",
      html: v => `${v.div_gender}${v.div_divNum}`,
      filterElem: () => getOr(filters?.[TeamColName.division])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: sortBy(v => `${v.div_gender}${v.div_divNum}`)
    },
    {
      id: TeamColName.region,
      label: "Region",
      html: v => parseIntOr(v.team_region, ""),
      filterElem: () => getOr(filters?.[TeamColName.region])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: sortBy(v => parseIntOr(v.team_region, ""))
    },
    {
      id: TeamColName.teamName,
      label: "Team name",
      html: v => v.areaTeamName,
      filterElem: () => getOr(filters?.[TeamColName.teamName])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: {
        cb: sortBy(_ => _.areaTeamName),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.playerCount,
      label: "Player count",
      html: v => parseIntOr(v.playerCount, ""),
      sort: {
        cb: sortBy(_ => parseIntOr(_.playerCount, -1)),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.refCount,
      label: "Referee count",
      html: v => parseIntOr(v.tournamentTeamOfficialReferees.length, ""),
      sort: {
        cb: sortBy(_ => _.tournamentTeamOfficialReferees.length),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.submitterName,
      label: "Submitter name",
      html: v => (
        <>
          <div class="text-sm">{v.submitterFirstName} {v.submitterLastName}</div>
          <div class="text-sm">{v.submitterEmail}</div>
        </>
      ),
      filterableValue: v => `${v.submitterFirstName} ${v.submitterLastName} ${v.submitterEmail}`,
      filterElem: () => getOr(filters?.[TeamColName.submitterName])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: sortByMany(sortBy(_ => _.submitterLastName), sortBy(_ => _.submitterFirstName)),
      xlsx: v => `${v.submitterFirstName} ${v.submitterLastName}`
    },
    {
      id: TeamColName.submitterEmail,
      label: "Submitter email",
      html: "never",
      xlsx: v => v.submitterEmail,
      sort: {
        cb: sortBy(_ => _.submitterEmail),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.status,
      label: "Status",
      html: v => tournTeamStatusUiString(v),
      filterableValue: v => v.status,
      filterElem: () => getOr(filters?.[TeamColName.status])
        ?.map(v => <div class="flex items-center">
          <select class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value}>
            <option value=""></option>
            <option value={"PENDING" satisfies TournamentTeam["status"]}>Incomplete</option>
            <option value={"APPROVED" satisfies TournamentTeam["status"]}>Approved</option>
            <option value={
              (["DROPPED_BY_HOST","DROPPED_BY_SUBMITTER"] satisfies TournamentTeam["status"][]).join(",")
            }>Dropped</option>
            <option value={"PAID_AWAITING_APPROVAL" satisfies TournamentTeam["status"]}>Paid awaiting approval</option>
          </select>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: sortBy(_ => {
        switch (_.status) {
          case "APPROVED": return 1
          case "PENDING": return 2
          case "DROPPED_BY_HOST": return 3
          case "DROPPED_BY_SUBMITTER": return 4
          case "PAID_AWAITING_APPROVAL": return 5
          default: exhaustiveCaseGuard(_.status)
        }
      })
    },
    {
      id: TeamColName.points,
      label: "Points",
      html: v => parseIntOr(v.answerPointsSum, ""),
      sort: {
        cb: sortBy(_ => _.answerPointsSum),
        dir: "not-sorted",
      }
    },
    {
      id: TeamColName.dateCreated,
      label: "Registration date",
      sort: {
        cb: sortByDayJS(_ => dayjs(_.dateCreated)),
        dir: "not-sorted",
      },
      filterElem: () => getOr(filters?.[TeamColName.dateCreated])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="date"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      html: v => (
        <div>
          <div>{dayjsFormatOr(v.dateCreated, "MM/DD/YY")}</div>
          <div class="text-xs">{dayjsFormatOr(v.dateCreated, "hh:mm a")}</div>
        </div>
      ),
      xlsx: v => dayjsFormatOr(v.dateCreated, "MM/DD/YYYY"),
      filterableValue: v => v.dateCreated
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

// a little munging to repivot some things for a better dump-to-excel
export type AugmentedRefRow =
  & TournamentTeamOverviewReport_Ref
  & {
    teamName: string,
    teamDesignation: string,

    refAssignmentsByTeamByUser: TournamentTeamOverviewReport["refAssignmentsByUserID"]
    infoIfAffiliated?: {
      // reference to report that "owns" this ref row
      owningTeamReport: TournamentTeamOverviewReport_Team
    }
  }

// consider: teamDesignation might be deprecated by way of `teamDesignationComponents`
type AugmentedCoachRow =
  & TournamentTeamOverviewReport_Coach
  & {
    teamName: string,
    teamDesignation: string,
    /**
     * well, coaches are always affiliated, but it doesn't hurt here to paranoid
     */
    infoIfAffiliated?: {
      // reference to report that "owns" this ref row
      owningTeamReport: TournamentTeamOverviewReport_Team
    }
  }

function extractAugmentedRowsForRefRowify(
  refAssignmentsByTeamByUser: TournamentTeamOverviewReport["refAssignmentsByUserID"],
  row: iltournament.TournamentTeamOverviewReport_Team
) : AugmentedRefRow[] {
  return row
    .tournamentTeamOfficialReferees
    .map(v => ({
      ...v,
      teamName: row.areaTeamName,
      teamDesignation: teamDesignation(row),
      refAssignmentsByTeamByUser,
      infoIfAffiliated: {
        owningTeamReport: row,
      }
    }) satisfies AugmentedRefRow)
}

function extractAugmentedRowsForCoachRowify(row: iltournament.TournamentTeamOverviewReport_Team) : AugmentedCoachRow[] {
  return row
      .tournamentTeamOfficialCoaches
      .map(v => ({
        ...v,
        teamName: row.areaTeamName,
        teamDesignation: teamDesignation(row),
        infoIfAffiliated: {
          owningTeamReport: row
        }
      }))
}

enum RefColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamLetter = "teamLetter",
  teamName = "teamName",
  headCoachNames = "headCoachNames",
  name = "name",
  maxCR = "maxCR",
  maxAR = "maxAR",
  refCertInfo_a = "refCertInfo_a",
  refEmail = "refEmail",
  refPhone = "refPhone",
  riskStatus_status = "riskStatus_status",
  riskStatus_code = "riskStatus_code",
  riskStatus_expirationDate = "riskStatus_expirationDate",
  comments = "comments",
  dateSubmitted = "dateSubmitted",
  dateSubmitted_time = "dateSubmitted_time",
  refAssignments = "refAssignments",
  name_xlsx_first = "name_xlsx_first",
  name_xlsx_last = "name_xlsx_last",
}

type RefColDefsHtmlConfig = {
  openRefEditModal: (_: AugmentedRefRow) => void,
  competitionUID: Guid,
}

function freshRefColDefs(mode: "html", config: RefColDefsHtmlConfig | null, filters?: Partial<Record<RefColName, FilterLike>>) : ColDef<AugmentedRefRow, RefColName>[];
function freshRefColDefs(mode: "xlsx") : ColDef<AugmentedRefRow, RefColName>[];
function freshRefColDefs(mode: "xlsx" | "html", config?: RefColDefsHtmlConfig | null, filters?: Partial<Record<RefColName, FilterLike>>) : ColDef<AugmentedRefRow, RefColName>[] {
  // we probably shouldn't even get here if we don't have edit permission but we'll try to make an affordance for this
  const hasEditPermission = config ? isAuthorizedToManageTournament(config.competitionUID, User.value) : false;

  const colDefs : ColDef<AugmentedRefRow, RefColName>[] = [
    {
      id: RefColName.teamDesignation,
      label: "Team designation",
      html: v => v.teamDesignation,
      sort: sortByMany(
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_divNum : 9999),
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_gender : "ZZZ"),
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.region : Infinity),
      ),
      filterElem: () => getOr(filters?.[RefColName.teamDesignation])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: RefColName.division,
      label: "Division",
      html: v => v.infoIfAffiliated ? `${v.infoIfAffiliated.owningTeamReport.div_gender}${v.infoIfAffiliated.owningTeamReport.div_divNum}` : "",
      filterElem: () => getOr(filters?.[RefColName.division])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: "html"
    },
    {
      id: RefColName.region,
      label: "Region",
      html: v => v.infoIfAffiliated ? parseIntOr(v.infoIfAffiliated.owningTeamReport.team_region, "") : "",
      sort: "html",
      filterElem: () => getOr(filters?.[RefColName.region])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: RefColName.teamName,
      label: "Team",
      html: "never",
      xlsx: v => v.teamName
    },
    {
      id: RefColName.headCoachNames,
      label: "Head coaches",
      cellStyle: "min-width:10em;",
      html: v => (v.infoIfAffiliated?.owningTeamReport.tournamentTeamOfficialCoaches ?? [])
        .filter(_ => _.officialType === TournamentTeamOfficialType.HEAD_COACH)
        .map(v => `${v.assignment.firstName} ${v.assignment.lastName}`)
        .join(", "),
      sort: sortByMap(v => {
        // This sort is a bit underspecified -- there can be many head coaches; so which one do we sort by?
        // Currently we just pick "the first, if it exists"
        return v.infoIfAffiliated
          ?.owningTeamReport
          .tournamentTeamOfficialCoaches
          .find(_ => _.officialType === TournamentTeamOfficialType.HEAD_COACH)
          ?.assignment ?? {firstName: "", lastName: ""}
      }, sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(l.lastName, r.lastName),
        (l,r) => accentAwareCaseInsensitiveCompare(l.firstName, r.firstName),
      )),
      filterElem: () => getOr(filters?.[RefColName.headCoachNames])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null
    },
    {
      id: RefColName.name,
      label: "Name",
      html: v => {
        if (config && hasEditPermission) {
          return (
            <div class="flex gap-2 items-start">
              <span onClick={() => config.openRefEditModal(v)} class="text-sm cursor-pointer hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)] p-1 rounded-md">
                <FontAwesomeIcon icon={faPencil}/>
              </span>
              <span>{v.firstName} {v.lastName}</span>
            </div>
          );
        }
        else {
          return <span>{v.firstName} {v.lastName}</span>;
        }
      },
      filterableValue: v => `${v.firstName} ${v.lastName}`,
      filterElem: () => getOr(filters?.[RefColName.name])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      xlsx: "never",
      sort: sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(l.lastName, r.lastName),
        (l,r) => accentAwareCaseInsensitiveCompare(l.firstName, r.firstName),
      ),
    },
    {
      id: RefColName.name_xlsx_first,
      label: "First Name",
      html: "never",
      xlsx: v => v.firstName,
    },
    {
      id: RefColName.name_xlsx_last,
      label: "Last Name",
      html: "never",
      xlsx: v => v.lastName,
    },
    {
      id: RefColName.maxCR,
      label: "Max CR",
      html: v => parseIntOr(v.ref_maxCR, "N/A"),
      sort: {
        cb: sortBy(_ => _.ref_maxCR),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.maxAR,
      label: "Max AR",
      html: v => parseIntOr(v.ref_maxAR, "N/A"),
      sort: {
        cb: sortBy(_ => _.ref_maxAR),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.refCertInfo_a,
      label: "User highest ref grade",
      cellStyle: "white-space: nowrap",
      html: v => {
        const refBadgeInfo = getRefBadgeInfo({
          ref_badgeLevel_fromUserRecord: v.user?.RefereeGrade || "",
          ref_badgeLevel_fromTournTeamOfficalRecord: v.ref_badgeLevel,
        })
        if (refBadgeInfo.source === "stack") {
          return <div><div>{refBadgeInfo.value}</div><div class="text-xs">(origin: Stack)</div></div>
        }
        else if (refBadgeInfo.source === "coach") {
          return <div><div>{refBadgeInfo.value}</div><div class="text-xs">(origin: Team)</div></div>
        }
        else {
          return "N/A"
        }
      },
      xlsx: v => {
        const refBadgeInfo = getRefBadgeInfo({
          ref_badgeLevel_fromUserRecord: v.user?.RefereeGrade || "",
          ref_badgeLevel_fromTournTeamOfficalRecord: v.ref_badgeLevel,
        })
        if (refBadgeInfo.source === "stack") {
          return `${refBadgeInfo.value} (origin: Stack)`
        }
        else if (refBadgeInfo.source === "coach") {
          return `${refBadgeInfo.value} (origin: Team)`
        }
        else {
          return "N/A"
        }
      }
    },
    {
      id: RefColName.refEmail,
      label: "Email",
      html: v => v.user?.email || v.email,
      cellStyle: "min-width:15em;",
      filterElem: () => getOr(filters?.[RefColName.refEmail])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: {
        cb: sortBy(_ => _.user?.email || _.email),
        dir: "not-sorted",
      }
    },
    {
      id: RefColName.refPhone,
      label: "Phone",
      cellStyle: "min-width: 2.5in; min-width:10em;",
      html: v => maybePrettifyUSPhoneNumber(v.user?.phone || ""),
    },
    {
      id: RefColName.riskStatus_status,
      label: "Risk status",
      cellStyle: "min-width: 2.5in;",
      html: v => {
        if (v.user) {
          return (
            <div class="text-sm">
              {v.user.RiskStatus ? <div>{v.user.RiskStatus}</div> : null}
              {v.user.RiskStatusExpiration ? <div>exp. {v.user.RiskStatusExpiration}</div> : null}
            </div>
          )
        }
        else {
          return <span/>
        }
      },
      xlsx: v => v.user?.RiskStatus || ""
    },
    {
      id: RefColName.riskStatus_code,
      label: "Risk status code",
      html: "never",
      xlsx: v => v.user?.RiskStatusCode || ""
    },
    {
      id: RefColName.riskStatus_expirationDate,
      label: "Risk status expiration",
      html: "never",
      xlsx: v => v.user?.RiskStatusExpiration || ""
    },
    {
      id: RefColName.comments,
      label: "Comments",
      html: "never",
      xlsx: v => v.comments
    },
    {
      id: RefColName.refAssignments,
      label: "Ref assignments",
      html: v => {
        if (!v.infoIfAffiliated) {
          // an "unaffiliated" ref doesn't have any ref assignments
          return "";
        }
        if (!v.user?.userID) {
          // shouldn't happen, but not much we can do if we end up with no user/userID
          return ""
        }
        return (
          <div>
            {v.refAssignmentsByTeamByUser[v.user.userID]?.length ?? 0}
          </div>
        )
      },
      xlsx: v => {
        if (!v.infoIfAffiliated) {
          // an "unaffiliated ref" doesn't have any ref assignments
          return "";
        }
        if (!v.user?.userID) {
          // shouldn't happen, but not much we can do if we end up with no user/userID
          return ""
        }
        return v.refAssignmentsByTeamByUser[v.user.userID]?.length ?? 0
      },
      sort: {
        // the "non affiliated" all get grouped together (but also, affiliated/non-affiliated are on separate sheets or html divs anyway)
        cb: sortBy(_ => {
          if (!_.infoIfAffiliated) {
            // an "unaffiliated" ref doesn't have any ref assignments
            return 99999;
          }
          if (!_.user?.userID) {
            // shouldn't happen, but not much we can do if we end up with no user/userID
            return 99999;
          }
          return _.refAssignmentsByTeamByUser[_.user.userID]?.length ?? 0
        }),
        dir: "not-sorted",
      }
    },
    {
      id: RefColName.dateSubmitted,
      label: "Submitted",
      html: v => (
        <div>
          <div>{dayjsFormatOr(v.createdOn, "MM/DD/YY")}</div>
          <div>{dayjsFormatOr(v.createdOn, "hh:mm a")}</div>
        </div>
      ),
      xlsx: v => dayjsFormatOr(v.createdOn, "MM/DD/YY"),
      sort: {
        cb: sortByDayJS(_ => dayjs(_.createdOn)),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.dateSubmitted_time,
      label: "Submitted time",
      html: "never",
      xlsx: v => dayjsFormatOr(v.createdOn, "hh:mm a"),
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

enum CoachColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamLetter = "teamLetter",
  teamName = "teamName",
  name = "name",
  email = "email",
  phone = "phone",
  comments = "comments",
  riskStatus_status = "riskStatus_status",
  riskStatus_code = "riskStatus_code",
  riskStatus_expirationDate = "riskStatus_expirationDate",
  name_xlsx_first = "name_xlsx_first",
  name_xlsx_last = "name_xlsx_last",
}

function freshCoachColDefs(mode: "xlsx" | "html", filters?: Partial<Record<CoachColName, FilterLike>>) : ColDef<AugmentedCoachRow, CoachColName>[] {
  const colDefs : ColDef<AugmentedCoachRow, CoachColName>[] = [
    {
      id: CoachColName.teamDesignation,
      label: "Team designation",
      html: v => v.teamDesignation,
      sort: sortByMany(
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_divNum : 9999),
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_gender : "ZZZ"),
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.region : Infinity),
      ),
      filterElem: () => getOr(filters?.[CoachColName.teamDesignation])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: CoachColName.division,
      label: "Division",
      html: v => v.infoIfAffiliated ? `${v.infoIfAffiliated.owningTeamReport.div_gender}${v.infoIfAffiliated.owningTeamReport.div_divNum}` : "",
      sort: sortByMany(
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_divNum : 9999),
        sortBy(v => v.infoIfAffiliated ? v.infoIfAffiliated.owningTeamReport.div_gender : "ZZZ"),
      ),
      filterElem: () => getOr(filters?.[CoachColName.division])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: CoachColName.region,
      label: "Region",
      html: v => v.infoIfAffiliated ? parseIntOr(v.infoIfAffiliated.owningTeamReport.team_region, "") : "",
      sort: "html",
      filterElem: () => getOr(filters?.[CoachColName.region])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: CoachColName.teamName,
      label: "Team",
      html: "never",
      xlsx: v => v.teamName
    },
    {
      id: CoachColName.name,
      label: "Name",
      html: v => `${v.assignment.firstName} ${v.assignment.lastName}`,
      filterElem: () => getOr(filters?.[CoachColName.name])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      xlsx: "never",
      sort: sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(l.assignment.lastName, r.assignment.lastName),
        (l,r) => accentAwareCaseInsensitiveCompare(l.assignment.firstName, r.assignment.firstName),
      ),
    },
    {
      id: CoachColName.name_xlsx_first,
      label: "First Name",
      html: "never",
      xlsx: v => v.assignment.firstName,
    },
    {
      id: CoachColName.name_xlsx_last,
      label: "Last Name",
      html: "never",
      xlsx: v => v.assignment.lastName,
    },
    {
      id: CoachColName.email,
      label: "Email",
      html: v => v.assignment.email,
      filterElem: () => getOr(filters?.[CoachColName.email])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
      sort: {
        cb: sortBy(_ => _.assignment.email),
        dir: "not-sorted"
      },
    },
    {
      id: CoachColName.phone,
      label: "Phone",
      cellStyle: "min-width:10em;",
      html: v => maybePrettifyUSPhoneNumber(v.assignment.phone),
    },
    {
      id: CoachColName.comments,
      label: "Comments",
      html: "never",
      xlsx: v => v.comments
    },
    {
      id: CoachColName.riskStatus_status,
      label: "Risk status",
      html: v => {
        if (v.assignment.type === "coachAssignment") {
          return (
            <div class="text-sm">
              {v.assignment.RiskStatus ? <div>{v.assignment.RiskStatus}</div> : null}
              {v.assignment.RiskStatusExpiration ? <div>exp. {v.assignment.RiskStatusExpiration}</div> : null}
            </div>
          )
        }
        else {
          return <span/>
        }
      },
      xlsx: v => v.assignment.type === "coachAssignment" ? (v.assignment.RiskStatus || "") : ""
    },
    {
      id: CoachColName.riskStatus_code,
      label: "Risk status code",
      html: "never",
      xlsx: v => v.assignment.type === "coachAssignment" ? (v.assignment.RiskStatusCode || "") : ""
    },
    {
      id: CoachColName.riskStatus_expirationDate,
      label: "Risk status expiration",
      html: "never",
      xlsx: v => v.assignment.type === "coachAssignment" ? (v.assignment.RiskStatusExpiration || "") : ""
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

enum AnswerColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamLetter = "teamLetter",
  teamName = "teamName",
}

/**
 * It is expected that all rows share the same number of tournamentQuestionLinks, though their answers will differ.
 * Rows having no answer for some question Q should still contain a question link for question Q, because it's relevant to the whole tournament.
 */
function freshAnswersColDefs(
  _mode: "html" | "xlsx", // unused ... kept only for symmetry with other fresh*ColDefs functions
  rows: iltournament.TournamentTeamOverviewReport_Team[],
  filters?: Partial<Record<AnswerColName, FilterLike>>,
) : ColDef<iltournament.TournamentTeamOverviewReport_Team>[] {
  const colDefs : ColDef<iltournament.TournamentTeamOverviewReport_Team>[] = [
    {
      id: AnswerColName.teamDesignation,
      label: "Team designation",
      html: v => teamDesignation(v),
      sort: sortByMany(
        sortBy(v => v.div_divNum),
        sortBy(v => v.div_gender),
        sortBy(v => v.region),
      ),
      filterElem: () => getOr(filters?.[AnswerColName.teamDesignation])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null
    },
    {
      id: AnswerColName.division,
      label: "Division",
      html: v => `${v.div_gender}${v.div_divNum}`,
      sort: sortByMany(
        sortBy(v => v.div_divNum),
        sortBy(v => v.div_gender),
      ),
      filterElem: () => getOr(filters?.[AnswerColName.division])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: AnswerColName.region,
      label: "Region",
      html: v => parseIntOr(v.team_region, ""),
      sort: "html",
      filterElem: () => getOr(filters?.[AnswerColName.region])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-16 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null,
    },
    {
      id: AnswerColName.teamName,
      label: "Team name",
      html: v => v.areaTeamName,
      sort: "html",
      filterElem: () => getOr(filters?.[AnswerColName.teamName])
        ?.map(v => <div class="flex items-center">
          <input class="max-w-32 px-1 py-0 font-normal text-sm rounded-md" v-model={v.value} type="text"/>
          <ClearFilterButton {...v}/>
        </div>)
        ?? null
    },
    {
      id: "__xlsx_pointsSum",
      label: "Points sum",
      html: v => parseIntOr(v.answerPointsSum, ""),
      sort: sortBy(v => parseIntOr(v.answerPointsSum, Infinity))
    }
  ]

  // As per the comments above, use the first row as the basis for "all possible questions"
  // also, it might be empty so keep ourselves honest by required optional access
  forceCheckedIndexedAccess(rows, 0)
    ?.tournamentQuestionLinks
    .forEach(qLink => {
      colDefs.push({
        id: qLink.questionID,
        label: qLink.label,
        html: v => {
          const qForThisRow = v.tournamentQuestionLinks.find(_ => _.questionID === qLink.questionID)
          const r = qForThisRow?.answers.map(_ => _.answer).join(",") || "N/A"
          return r;
        }
      })
    })

  return colDefs;
}

enum TableSection {
  registrationAnswers = 1,
  referees,
  coaches,
  unaffiliatedRefs,
}

export const DivCounts = defineComponent({
  props: {
    report: vReqT<TournamentTeamOverviewReport>(),
  },
  setup(props) {
    const filter = ref(TournTeamDisplayFilter())
    const filterManager = FilterManager(
      filter,
      // This abstraction leaky and/or wrong -- we're not interested in the filtering behavior here,
      // just the controller-like aspect of the way it manages a <SelectMany>
      // So, we give it a "constant nothing" to filter
      {value: []}
    );
    const colDefs = freshDivCountColDefs();
    const sortState = freshSortState(colDefs)

    const divisionsByDivID = ref(new Map<DivID, Division>());
    const ready = ref(false)

    const rowData = computed(() => {
      if (!ready.value) {
        return []
      }
      else {
        const listing = buildTournTeamDivCountListing(props.report, filter.value, divisionsByDivID.value)
        return listing.sort(sortState.asSorter())
      }
    })

    onMounted(async () => {
      divisionsByDivID.value = await Client
        .getDivisions()
        .then(divs => new Map(divs.map(div => [div.divID, div])))
      ready.value = true
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <div class="p-2 border border-slate-100 shadow-md bg-white">
          <div class="w-full rounded-2xl">
            <Disclosure>
              {
                ({open}: {open: boolean}) => {
                  return (
                    <>
                      <DisclosureButton
                        class="flex items-center rounded-lg bg-green-100 p-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75"
                      >
                        <FontAwesomeIcon icon={faChevronDown}
                          class={`${open ? '' : '-rotate-90'} h-5 w-5 text-green-500 transition-all`}
                        />
                        <span class="ml-1">Aggregate counts by division</span>
                      </DisclosureButton>
                      <DisclosurePanel class="px-4 pb-2 pt-4 text-sm">
                        <div>
                          <div class="flex items-start mb-2">
                            <div class="max-w-xl" data-il-selectManyContainer>
                              <SelectManyPane {...filterManager.selectManyPropsAndHandlers}>
                                {
                                  ({isOpen, open}: SelectManySlotProps["default"]) => {
                                    return (
                                      <div class="text-sm">
                                        <span
                                          onClick={() => open()}
                                          class={`text-blue-700 cursor-pointer underline bg-white ${isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                                          style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                                        >
                                          <FontAwesomeIcon {...{style: "outline:none;"}} icon={faListCheck} v-tooltip={{content: 'Select multiple'}}/>
                                        </span>
                                        <span class="ml-1" >{filterManager.statusOptionsMessage}</span>
                                      </div>
                                    )
                                  }
                                }
                              </SelectManyPane>
                            </div>
                          </div>
                          <div class="max-h-64 overflow-y-auto">
                            <table class="w-full">
                              {
                                colDefs.map(colDef => {
                                  return (
                                    <th class="p-1 align-top text-left">
                                      <div class="flex items-center text-sm">
                                        {
                                          ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                            return sorter
                                              ? (
                                                <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                                  <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                                </span>
                                              )
                                              : null
                                          })(sortState.sortersByColID[colDef.id])
                                        }
                                        <span>{colDef.label}</span>
                                      </div>
                                    </th>
                                  )
                                })
                              }
                              {
                                rowData
                                  .value
                                  .map((rowData, i) => {
                                    const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                                    return (
                                      <Fragment key={rowData.division.divID}>
                                        <tr style={styles}>
                                          {
                                            colDefs.map((colDef) => {
                                              return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                            })
                                          }
                                        </tr>
                                      </Fragment>
                                    )
                                  })
                              }
                            </table>
                          </div>
                        </div>
                      </DisclosurePanel>
                    </>
                  )
                }
              }
            </Disclosure>
          </div>
        </div>
      )
    }
  }
})

function freshDivCountColDefs() : ColDef<{division: Division, count: number}, "name" | "descriptor" | "count">[] {
  return [
    {
      id: "descriptor",
      label: "Division",
      html: v => `${v.division.gender}${v.division.divNum}`,
      sort: {
        cb: sortByMany(sortBy(_ => parseIntOr(_.division.divNum, -1)), sortBy(_ => _.division.gender)),
        dir: "asc"
      }
    },
    {
      id: "name",
      label: "Name",
      html: v => v.division.displayName,
      sort: {
        cb: sortBy(_ => _.division.displayName),
        dir: "not-sorted"
      }
    },
    {
      id: "count",
      label: "Team Count",
      html: v => v.count,
      sort: {
        cb: sortBy(_ => _.count),
        dir: "not-sorted"
      }
    }
  ]
}

/**
 * All divisions present within the report should be present within `divisionsByDivID`
 */
function buildTournTeamDivCountListing(report: TournamentTeamOverviewReport, filter: TournTeamDisplayFilter, divisionsByDivID: Map<Guid, Division>) {
  const builder : Record<Guid, number> = {}

  for (const tournTeam of report.tournamentTeams) {
    builder[tournTeam.div_divID] ??= 0;

    const status = computeTournTeamStatusForFiltering(tournTeam)

    if (!status || !filter.status[status]) {
      continue;
    }

    builder[tournTeam.div_divID] += 1
  }

  const result : {division: Division, count: number}[] = []

  for (const divID of Object.keys(builder)) {
    result.push({
      // we expect this lookup to always succeed, why __wouldn't__ we have all the divisions we need
      division: divisionsByDivID.get(divID)!,
      count: builder[divID]
    });
  }

  return result;
}

type TournTeamStatusFilter = ReturnType<typeof FilterManager>

export const FilterRefiner = defineComponent({
  props: {
    teamOptions: vReqT<UiOption[]>(),
    mut_statusFilter: vReqT<TournTeamStatusFilter>(),
    mut_selectedTeamID: vReqT<Reflike<Integerlike | "ALL">>(),
    mut_refName: vReqT<Reflike<string>>(),
  },
  setup(props) {
    const refName = ref(props.mut_refName.value)

    const commitRefNameFilter = () => {
      props.mut_refName.value = refName.value;
    }

    return () => {
      return (
        <div class="p-2 border border-slate-100 shadow-md bg-white">
          <div class="w-full rounded-2xl">
            <Disclosure>
              {
                ({open}: {open: boolean}) => {
                  return (
                    <>
                      <DisclosureButton
                        class="flex items-center rounded-lg bg-green-100 p-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75"
                      >
                        <FontAwesomeIcon icon={faChevronDown}
                          class={`${open ? '' : '-rotate-90'} h-5 w-5 text-green-500 transition-all`}
                        />
                        <span class="ml-1">Refine results...</span>
                      </DisclosureButton>
                      <DisclosurePanel class="px-4 pb-2 pt-4 text-sm">
                        <>
                          <div class="max-w-xl my-2" data-il-selectManyContainer>
                            <SelectManyPane {...props.mut_statusFilter.selectManyPropsAndHandlers}>
                              {
                                ({isOpen, open}: SelectManySlotProps["default"]) => {
                                  return (
                                    <div>
                                      <span
                                        onClick={() => open()}
                                        class={`text-blue-700 cursor-pointer underline bg-white ${isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                                        style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                                      >
                                        <FontAwesomeIcon {...{style: "outline:none;"}} icon={faListCheck} v-tooltip={{content: 'Select multiple'}}/>
                                      </span>
                                      <span class="ml-1" >{props.mut_statusFilter.statusOptionsMessage}</span>
                                    </div>
                                  )
                                }
                              }
                            </SelectManyPane>
                          </div>
                          <FormKit type="select" options={props.teamOptions} label="Team" v-model={props.mut_selectedTeamID.value}/>
                          <div style="--fk-margin-outer:none;">
                            <div class="text-xs font-medium mb-1">Referee name</div>
                            <FormKit type="form" actions={false} onSubmit={commitRefNameFilter}>
                              <div class="flex gap-2 items-start">
                                <FormKit type="text" v-model={refName.value}/>
                                <t-btn type="submit">Ok</t-btn>
                              </div>
                            </FormKit>
                            {
                              props.mut_refName.value
                                ? <span class="il-link text-xs ml-1" onClick={() => {refName.value = ""; commitRefNameFilter();}}>Clear ref name filter</span>
                                : null
                            }
                          </div>
                        </>
                      </DisclosurePanel>
                    </>
                  )
                }
              }
            </Disclosure>
          </div>
        </div>
      )
    }
  }
})

function simpleSubstringFilter(v: string) {
  const xv = v.trim();
  if (!xv) {
    return () => true
  }
  else {
    const p = EscapedRegExp(xv, "i")
    return (row: any, colDef: ColDef<any, any>) => {
      const v = getFilterableStringForCell(row, colDef);
      if (typeof v !== "string") {
        // uh buggy or unintentional filter or something
        return true;
      }
      return p.test(v)
    }
  }
}

function simpleListExactMatchFilter(v: string) {
  const xv = v.trim();
  if (!xv) {
    return () => true
  }
  else {
    const vs = new Set(v.split(","))
    return (row: any, colDef: ColDef<any, any>) => {
      const v = getFilterableStringForCell(row, colDef);
      if (typeof v !== "string") {
        // uh buggy or unintentional filter or something
        return true;
      }
      return vs.has(v)
    }
  }
}

function simpleDatetimeFilter(v: string, unit: OpUnitType) {
  const xv = v.trim();
  if (!xv) {
    return () => true
  }
  else {
    const l = dayjsOr(v)
    if (!l) {
      return () => true
    }

    return (row: any, colDef: ColDef<any, any>) => {
      const r = dayjsOr(getFilterableStringForCell(row, colDef))

      if (!l || !r) {
        return true;
      }

      return l.isSame(r, unit)
    }
  }
}

function filterRunner(rows: any[], filters: {[k: string]: {filter: (row: any, colDef: ColDef<any,any>) => boolean}}, colDefs: ColDef<any,any>[]) {
  const colDefsByID = (() => {
    const r : {[k: string]: ColDef<any, any>} = {}
    for (const colDef of colDefs) {
      r[colDef.id] = colDef
    }
    return r;
  })()

  return rows.filter(v => {
    for (const id of Object.keys(filters)) {
      const colDef = requireNonNull(colDefsByID[id])
      const filter = filters[id as keyof typeof filters].filter;
      if (!filter(v, colDef)) {
        return false;
      }
    }
    return true;
  })
}

interface FilterLike {
  value: any,
  filter: (row: any, colDef: ColDef<any, any>) => boolean,
  clear: () => void
}
