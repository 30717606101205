import { Game } from 'src/composables/InleagueApiV1.Game'
import { RefSlotConfig } from "src/interfaces/Store/client"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { ExtractEmitsHandlers, SortDir, nonThrowingExhaustiveCaseGuard } from 'src/helpers/utils';

export const colNames = {
  "date": "date",
  "time": "time",
  "field": "field",
  "gameDivision": "gameDivision",
  "teams": "teams",
  "gameNo": "gameNo",
  "matchReport": "matchReport",
  "REF1": "REF1",
  "REF2": "REF2",
  "REF3": "REF3",
  "REF4": "REF4",
} as const;

export type ColNames_t = (typeof colNames)[keyof typeof colNames];

export interface QTableRefereeColumnDef {
  name: string
  required: boolean
  label: string
  align: string
  field: (row: AugmentedGame) => string | AugmentedGame | number
  sortable: boolean
  sort?: (l: AugmentedGame, r: AugmentedGame) => number,
  classes: string
  headerClasses?: string
  style?: string
}

export interface SortConfig {
  topLevelGroupBy: "byDateTime" | "byDiv"
  topLevelGroupSort: SortDir,
  perGameColumn: ColNames_t,
  perGameDir: SortDir,
}

export function isGameColumn(v: any) : v is ColNames_t {
  const unchecked_v = v as ColNames_t;
  switch (unchecked_v) {
    case 'date':
    case 'time':
    case 'field':
    case 'gameDivision':
    case 'teams':
    case 'gameNo':
    case 'matchReport':
    case 'REF1':
    case 'REF2':
    case 'REF3':
    case 'REF4':
      return true;
    default:
      nonThrowingExhaustiveCaseGuard(unchecked_v);
      return false;
  }
}

/**
 * e.g. v-slot:[some-name-following-this-type's-pattern] = "props"
 * These are 1-indexed
 */
export type QTableBodyCellRefereeVSlotBindingName = `body-cell-REF${number}`

/**
 * Binds a refConfig to a Game
 */
export interface AugmentedGame {
  game: Game
  refConfig: RefSlotConfig,
}

export const ALL_FIELDS_FIELDID = "";

export interface RefSlotOption {
  name: string,
  selected: boolean
}

export interface CancelRefSignupRequestEvent {
  gameID: iltypes.Guid,
  refPosition: number
}

export interface CreateRefSignupRequestEvent {
  gameID: iltypes.Guid,
  refPosition: number
}

export interface ApproveRefAssignmentEvent {
  gameID: iltypes.Guid,
  refPosition: number
}

export interface CancelRefAssignmentEvent {
  gameID: iltypes.Guid,
  refPosition: number
}


export const emitsDef = {
  getGames: () => true,
  createRefSignupRequest: (_: CreateRefSignupRequestEvent) => true,
  cancelRefSignupRequest: (_: CancelRefSignupRequestEvent) => true,
  approveRefAssignment: (_: ApproveRefAssignmentEvent) => true,
  cancelRefAssignment: (_: CancelRefAssignmentEvent) => true,
  reloadGame: (_: {gameID: iltypes.Guid}) => true,
  changeSortConfig: (_: {column: ColNames_t, dir: SortDir}) => true,
} as const

export type Emits = ExtractEmitsHandlers<typeof emitsDef>
