import { FormKit, FormKitMessages } from "@formkit/vue"
import { faSave, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { TeamCreateUpdateDeleteViewMeta } from "src/composables/InleagueApiV1.Teams"
import { vReqT, UiOption, exhaustiveCaseGuard, FormKitValidationRule } from "src/helpers/utils"
import { Guid, Integerlike, Team } from "src/interfaces/InleagueApiV1"
import { Client } from "src/store/Client"
import { CSSProperties, computed, defineComponent } from "vue"

export const TeamFormRowElement = defineComponent({
  name: "TeamFormRowElement",
  props: {
    mut_form: vReqT<TeamForm>(),
    regionOptions: vReqT<UiOption[]>(),
    doSubmit: vReqT<() => void>(),
    confirmDelete: vReqT<() => void>(),
    layoutSize: vReqT<"big" | "small">(),
  },
  setup(props) {
    const formGridStyles = computed<CSSProperties>(() => {
      if (props.layoutSize === "big") {
        return {
          gridTemplateColumns: "1fr 1fr 1fr min-content"
        }
      }
      else {
        return {
          gridTemplateColumns: "1fr",
          "--fk-max-width-input": "none",
        }
      }
    })

    const immutabilityReasonUiString = (v: CityAndRegionImmutabilityReason) : string | undefined => {
      switch (v) {
        case "not-area-play": return "Area Play is disabled. Please contact inLeague support to enable it."
        case "not-local-team": return "This team is managed by another inLeague instance"
        case undefined: return undefined;
        default: exhaustiveCaseGuard(v);
      }
    }

    /**
     * This is different than "form isn't filled out properly so don't allow submit",
     * which is subtle. We want to explicitly disable the save button in edit mode, when our
     * form hasn't been touched.
     */
    const disableSaveButton = computed(() => {
      const DISABLE = true;
      const NO_DISABLE = false;
      if (props.mut_form.type === "create") {
        return NO_DISABLE;
      }
      return isDirty(props.mut_form) ? NO_DISABLE : DISABLE;
    })

    return () => {
      return (
        <div data-test={`TeamFormElement/teamID=${props.mut_form.type === "create" ? "new" : props.mut_form.pristine.teamID}`}>
          <FormKit type="form" onSubmit={() => props.doSubmit()} actions={false}>
            <div style={{"--fk-margin-outer": "none", "--fk-bg-input": "white", "--fk-bg-decorator": "white", display: "grid", ...formGridStyles.value}}>
              <div class={`px-2 pt-1 pb-2 flex flex-col`}>
                <div>Team Designation</div>
                <div class="mt-auto">
                  <FormKit type="text" v-model={props.mut_form.teamLetter}
                    name="Team designation"
                    disabled={props.mut_form.delete} validation={[["required"], ["length", 1, MAXLEN_TEAMLETTER]]}
                    data-test="teamLetter"
                  />
                </div>
              </div>
              <div class={`px-2 pt-1 pb-2 flex flex-col`}>
                <div>Region</div>
                {
                  props.mut_form.type === "edit" && props.mut_form.pristine.clientID !== Client.value.instanceConfig.clientid
                    ? <div class="text-sm">{props.mut_form.clientMeta.regionName}</div>
                    : null
                }
                <div class="mt-auto">
                  <FormKit type="select" options={props.regionOptions} v-model={props.mut_form.region}
                    disabled={props.mut_form.immutableRegion || props.mut_form.delete} v-tooltip={{content: immutabilityReasonUiString(props.mut_form.immutableRegionReason)}}
                    validation={[["required"]]}
                    data-test="region"
                  />
                </div>
              </div>
              <div class={`px-2 pt-1 pb-2 flex flex-col`}>
                <div>City</div>
                <div class="mt-auto">
                  <FormKit type="text" v-model={props.mut_form.teamCity}
                    disabled={props.mut_form.immutableCity || props.mut_form.delete} v-tooltip={{content: immutabilityReasonUiString(props.mut_form.immutableCityReason)}}
                    // We probably want teamCity always required, but there are existing teams with nullish values, and editing them if this is required would
                    // force users to provide one, when they might not expect to have to do so.
                    validation={[
                      ...(props.mut_form.type === "create" ? [["required"]] : []) as FormKitValidationRule[],
                      ["length", props.mut_form.type === "create" ? 1 : 0, MAXLEN_TEAMCITY]
                    ]}
                    data-test="teamCity"
                  />
                </div>
              </div>
              <div class={`px-2 pt-1 pb-2 flex items-start gap-2`}>
                <div class="flex gap-2 w-full">
                  <div class="flex flex-col gap-2 items-center justify-start">
                    <div>Active</div>
                      <FormKit type="checkbox" v-model={props.mut_form.active} disabled={props.mut_form.delete} data-test="active"/>
                  </div>
                  <div class="flex flex-col gap-2 items-center justify-start">
                    <div>Save</div>
                    <button
                      type="submit"
                      class={`-mt-2 p-1 rounded-md ${disableSaveButton.value ? "text-gray-200" : "cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]"}`}
                      disabled={disableSaveButton.value}
                      data-test="submit"
                    >
                      <FontAwesomeIcon icon={faSave}/>
                    </button>
                  </div>
                  {
                    props.mut_form.type === "create"
                      ? null
                      : (
                        <div class="flex flex-col gap-2 items-center justify-start ml-auto">
                          <div>Delete</div>
                          <span
                            class={`-mt-2 p-1 rounded-md ${props.mut_form.canDelete ? "cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]" : "text-gray-200"}`}
                            v-tooltip={{content: props.mut_form.canDelete ? undefined : "Team has player assignment history and cannot be deleted"}}
                            onClick={props.confirmDelete}
                            data-test="delete"
                          >
                            <FontAwesomeIcon icon={faTrash}/>
                          </span>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
            <div class="pl-2">
              <FormKitMessages/>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

export type TeamForm =
  | CreateTeamForm
  | EditTeamForm

type CityAndRegionImmutabilityReason =
  | "not-local-team"
  | "not-area-play"
  | undefined

interface TeamForm_Base {
  readonly __vueKey: string,
  readonly type: "create" | "edit",
  readonly pristine: Team | null,
  readonly canDelete: boolean,
  readonly immutableCity: boolean,
  readonly immutableCityReason: CityAndRegionImmutabilityReason,
  readonly immutableRegion: boolean,
  readonly immutableRegionReason: CityAndRegionImmutabilityReason,
  delete: boolean,
  active: boolean,
  region: Integerlike,
  teamCity: string,
  teamLetter: string,
}

export interface CreateTeamForm extends TeamForm_Base {
  readonly type: "create",
  readonly pristine: null,
  readonly competitionUID: Guid,
  readonly divID: Guid,
}

export interface EditTeamForm extends TeamForm_Base {
  readonly type: "edit",
  readonly pristine: Team,
  readonly clientMeta: TeamCreateUpdateDeleteViewMeta["clientInfoByClientID"][Guid]
}

const MAXLEN_TEAMLETTER = 35
const MAXLEN_TEAMCITY = 50

function isDirty(form: EditTeamForm) : boolean {
  // no comparisons are strict eq here
  return form.active != !!form.pristine.active
    || form.region != form.pristine.region
    || form.teamCity != form.pristine.teamCity
    || form.teamLetter != form.pristine.teamLetter
}
