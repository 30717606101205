<template lang="pug">
div
  .grid.grid-col-1.gap-2.w-full(class='md:flex md:justify-between')
    div
      span.font-medium.mr-2 Game:
      | {{ augmentedGame.game.gameNum }}
    div
      span.font-medium.mr-2 Field:
      | {{ field }}
    div
      span.font-medium.mr-2 Time:
      | {{ formatTime(augmentedGame.game.gameStart) }}-{{ formatTime(augmentedGame.game.gameEnd) }}
    div
      span.font-medium.mr-2 Teams:
      | {{ teams }}
  div
    FormKit(
      type='text',
      name='refComment',
      data-cy='refComment',
      label='Game Referee Comments',
      v-model='refsDetails.refComment'
    )
    .grid.gap-6.gap-x-6(
      class='md:grid-rows-1 md:grid-flow-col md:gap-x-6 md:gap-y-0'
    )
      template(v-for='slot in refsDetails.slotsArray')
        //- paranoic safenav falls back to true if we get undefined;
        //- but we should always have a boolean from the lefthand side of the expr
        div(v-if="refSlotOptionsForSelectedCompetition?.[slot.slotNum - 1]?.selected ?? true")
          RefereeSlot(
            :referee='slot',
            :originalRef='originalRefs[slot.slotNum - 1]',
            :refName='refNames[slot.slotNum - 1]',
            :config='augmentedGame.refConfig',
            @input='val => $emit("input", val)'
            :data-test='`referee-slot-${slot.slotNum}`'
          )
    FormKit(
      type='text',
      name='emailText',
      label='Confirmation Email Additional Text',
      v-model='refsDetails.emailText',
      data-cy='emailText'
    )
    Btn2(
      class="w-full mt-2 px-2 py-1"
      :disabled="isSubmitting"
      :disabledClasses="btn2_disabledButLooksEnabledClasses"
      @click='updateRefsDetails',
      backgroundColor='bg-green',
      data-test='submit'
    )
      template(v-if="isSubmitting")
        div(class="flex items-center gap-2 justify-center")
          SoccerBall(:color="clientColor" width="1.375em" height="1.375em" timeForOneRotation="2s")
          div Working on it...
      template(v-else)
        div Submit
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  Ref,
  onMounted,
} from 'vue'

import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance } from 'boot/axios'
import { Game, RefInfo } from 'src/composables/InleagueApiV1.Game'
import TAutoSearchInput from 'src/components/UserInterface/t-autoSearchInput.vue'
import { formatTime } from 'src/helpers/formatDate'
import RefereeSlot from 'src/components/RefereeSchedule/RefereeSlot.vue'
import {
  SlotDetailsI,
  GameRefDetailsI,
  AssignIdDetails,
} from 'src/interfaces/referee'
import { copyViaJsonRoundTrip, __FIXME__UNSAFE_CAST } from 'src/helpers/utils'
import * as ilapi from "src/composables/InleagueApiV1"

import { propsDef, emitsDef } from "./RefereeModalShared"
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { Btn2, btn2_disabledButLooksEnabledClasses } from "src/components/UserInterface/Btn2"
import { SoccerBall } from '../SVGs'

export default defineComponent({
  name: 'RefereeScheduleModal',
  components: {
    RefereeSlot,
    TAutoSearchInput,
    Btn2,
    SoccerBall
  },
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    const refsDetails = ref({}) as Ref<GameRefDetailsI>
    const originalRefs = ref({}) as Ref<AssignIdDetails[]>
    const refNames = ref([]) as Ref<string[]>
    const closeDialog = ref(false)
    const dataCreated = ref(false)
    const refComment = ref('')
    const emailText = ref('')




    const userID = computed(() => {
      return User.value.userID
    })

    const documentRefName = (refVol: RefInfo, ref: RefInfo) => {
      if (ref) {
        refNames.value.push(ref['FirstName'] + ' ' + ref['LastName'])
      } else if (refVol) {
        refNames.value.push(refVol['FirstName'] + ' ' + refVol['LastName'])
      } else {
        refNames.value.push(refVol)
      }
    }

    const createAssignID = (refVol: RefInfo, ref: RefInfo, slotNum: number) => {
      const refObj: SlotDetailsI = {
        slotNum: slotNum,
        confirmed: true,
        locked: false,
        assignID: '',
        sendEmail: false,
      }
      if (ref) {
        refObj.assignID = ref
      } else if (refVol) {
        refObj.assignID = refVol
        refObj.confirmed = false
      } else {
        refObj.assignID = { ID: '', FirstName: '', LastName: '' }
      }
      return refObj
    }

    const addRefProperties = (refObj: SlotDetailsI) => {
      refObj.removeRef = false
      refObj.removeReason = ''
      refObj.sendCancelEmail = false
      return refObj
    }

    const toggleLock = (refObj: SlotDetailsI, slotNum: number) => {
      if (slotNum === 1 && props.augmentedGame.game.CRLock) {
        refObj.locked = true
      } else if (slotNum === 2 && props.augmentedGame.game.ARLock) {
        refObj.locked = true
      } else if (slotNum === 3 && props.augmentedGame.game.AR2Lock) {
        refObj.locked = true
      } else if (slotNum === 4 && props.augmentedGame.game.MentorLock) {
        refObj.locked = true
      }
    }

    const createSlotsArray = () => {
      const slotsArray: SlotDetailsI[] = []
      for (let i = 1; i <= props.augmentedGame.refConfig.numSlots; i++) {
        // determine whether there is a ref or refVol and add to refNames array
        const ref = props.augmentedGame.game[`ref${i}` as keyof Game] as RefInfo
        const refVol = props.augmentedGame.game[`ref${i}Vol` as keyof Game] as RefInfo

        documentRefName(refVol, ref)

        let refObj = createAssignID(refVol, ref, i)
        toggleLock(refObj, i)
        if (ref || refVol) refObj = addRefProperties(refObj)

        slotsArray.push(refObj);
      }
      return slotsArray
    }

    const createRefsDetails = (slotsArray: SlotDetailsI[]) => {
      refsDetails.value = {
        refComment: props.augmentedGame.game.refComment,
        slotsArray,
        seasonID: Client.value.instanceConfig.currentseasonid,
      }
    }

    const establishOriginalRefs = (slotsArray: SlotDetailsI[]) => {
      originalRefs.value = []
      slotsArray.forEach(slot => {
        originalRefs.value.push({ ...(slot.assignID as AssignIdDetails) })
      })
    }

    const createDataObject = () => {
      const slotsArray = createSlotsArray()
      createRefsDetails(slotsArray)
      establishOriginalRefs(slotsArray)
      dataCreated.value = true
    }

    /**
     * this is 'transform form into api submittable shape'
     * todo: clarify all the behavior in the if/else branches, where things are deleted or force-set
     */
    const formatRefDetails = () => {
      const cleanRefsDetails = copyViaJsonRoundTrip(refsDetails.value);
      for (let i = 0; i < cleanRefsDetails.slotsArray.length; i++) {
        // always required on server, if we didn't write it into the form object by now, we need to default to false
        cleanRefsDetails.slotsArray[i].confirmed = cleanRefsDetails.slotsArray[i].confirmed ?? false;
        cleanRefsDetails.slotsArray[i].sendEmail = cleanRefsDetails.slotsArray[i].sendEmail ?? false;

        if (
          !cleanRefsDetails.slotsArray[i].confirmed &&
          props.augmentedGame.game[`ref${i + 1}` as keyof Game]
        ) {
          cleanRefsDetails.slotsArray[i].removeRef = true
        }

        if (
          !(cleanRefsDetails.slotsArray[i].assignID as AssignIdDetails)?.ID &&
          cleanRefsDetails.slotsArray[i].removeRef
        ) {
          delete cleanRefsDetails.slotsArray[i].assignID
        } else if (
          !(cleanRefsDetails.slotsArray[i].assignID as AssignIdDetails)?.ID
        ) {
          delete cleanRefsDetails.slotsArray[i].assignID
        } else {
          cleanRefsDetails.slotsArray[i].assignID = (
            cleanRefsDetails.slotsArray[i].assignID as AssignIdDetails
          ).ID
        }
        if (!cleanRefsDetails.slotsArray[i].removeRef) {
          delete cleanRefsDetails.slotsArray[i].removeRef
          delete cleanRefsDetails.slotsArray[i].removeReason
          delete cleanRefsDetails.slotsArray[i].sendCancelEmail
        }
      }
      return cleanRefsDetails
    }

    const isSubmitting = ref(false)

    const updateRefsDetails = async () : Promise<void> => {
      if (isSubmitting.value) {
        return
      }

      const preparedRefDetails = formatRefDetails()

      try {
        isSubmitting.value = true
        await ilapi.updateGameRefereeAssignments(
          axiosAuthBackgroundInstance, {
            gameID: props.augmentedGame.game.gameID,
            // this cast represents what code was already doing prior to having a typed payload
            // The payload type reflects the API documentation; but the API appears to accept something slightly different
            // and that's what we were already sending. So API docs could be updated, or we need to better unify all the disparate
            // slot / game shapes, or something.
            payload: __FIXME__UNSAFE_CAST<any>(preparedRefDetails)
          }
        );
        emit('close')
        emit('reloadGame', {gameID: props.augmentedGame.game.gameID})
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
      finally {
        isSubmitting.value = false
      }
    }

    onMounted(() => {
      createDataObject()
    })

    return {
      refsDetails,
      refNames,
      userID,
      createDataObject,
      updateRefsDetails,
      closeDialog,
      formatTime,
      dataCreated,
      originalRefs,
      refComment,
      emailText,
      isSubmitting,
      clientColor: computed(() => Client.value.clientTheme.color),
      btn2_disabledButLooksEnabledClasses
    }
  },
})
</script>