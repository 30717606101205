import type { Directive, PropType, Ref, WatchOptions, WatchSource } from 'vue';
import { ref, markRaw, onUnmounted, getCurrentInstance, reactive, shallowRef, watch } from "vue"
import type { IziToast } from 'izitoast'
import * as ilapi from 'src/interfaces/InleagueApiV1'
import type { FormKitNode } from '@formkit/core';
import dayjs from 'dayjs';
import { Guid } from 'src/interfaces/InleagueApiV1';
import { ExplodedPromise } from './ExplodedPromise';
import { LocationQueryValue, RouteLocationNormalizedLoaded, Router } from 'vue-router';

export * from "./utils-backend";

export function copyViaJsonRoundTrip<T>(v: T) : T {
  return JSON.parse(JSON.stringify(v));
}

/**
 * Despite the name, asserts that something is both not `null` and not `undefined`
 */
export function assertNonNull<T>(v: T | undefined | null, msg = "AssertionFailure"): asserts v is T {
  if (v === undefined || v === null) {
    if (process.env.NODE_ENV === "development") {
      debugger;
    }
    throw Error(msg)
  }
}

export function requireNonNull<T>(v: T | undefined | null, msg = "AssertionFailure"): T {
  if (v === undefined || v === null) {
    if (process.env.NODE_ENV === "development") {
      debugger;
    }
    throw Error(msg)
  }
  return v;
}

export function assertTruthy(v: any, msg = "AssertionFailure") {
  if (!v) {
    if (process.env.NODE_ENV === "development") {
      debugger;
    }
    throw Error(msg)
  }
}

/**
 * It would be cool if `assertTruthy(x.tag === "someTag")` would somehow propagate the narrowing on `x.tag`, but it doesn't.
 * Instead, we can do `assertIs(x.tag, "someTag")`
 */
export function assertIs<L, R extends L>(l: L, r: R, msg = "AssertionFailure") : asserts l is R {
  if (l === r) {
    return;
  }
  else {
    if (process.env.NODE_ENV === "development") {
      debugger;
    }
    throw Error(msg)
  }
}

export function useIziToast() : IziToast {
  const vueInternalInstance = getCurrentInstance()
  assertNonNull(vueInternalInstance, "no current instance?");
  return vueInternalInstance.appContext.config.globalProperties.$toast;
}

/**
 * leagueComment (or permLeagueComment) arrive as `cfnull | LeagueComment`
 * If a LeagueComment arrives and is cfnull, it is "collapsed" to its `comment` property, such that in the vue templates, after receipt,
 * roughly this sequence occurs:
 * ```
 *   const somePlayerOrUserObject = {<otherproperties>..., (perm)leagueComment: cfnull | LeagueComment}
 *   somePlayerOrUserObject.(perm)leagueComment = somePlayerOrUserObject.(perm)leagueComment?.comment ?? somePlayerOrUserObject.(perm)leagueComment
 * ```
 *
 * This is currently done "in-place" at the api callsite, directly on the obj received from the api
 *
 * There should be 2 clearly distinct steps:
 *   - Receive api object from api layer
 *   - Munge into new object for use in some particular form or display in some layout
 *
 * Calling this repeatedly on the same (string | LeagueComment) object is idempotent in that once a string it will always return the string
 */
 export function __FIXME__collapseLeagueCommentPropertyToString(v: string | ilapi.LeagueComment) {
  return (typeof v === "object") ? v.comment : v;
}

export type Primitive = number | string | boolean | null | undefined;

export class DirtyPristinePrimitive<T extends Primitive = Primitive> {
  dirty: T;
  pristine: T;
  constructor(v: T) {
    this.dirty = v;
    this.pristine = v;
  }

  /**
   * Has the value changed, using strict equality
   */
  isStrictDirty() : boolean {
    return this.dirty !== this.pristine;
  }

  discardChanges() : void {
    this.dirty = this.pristine;
  }

  commitChanges() : void {
    this.pristine = this.dirty;
  }
}

/**
 * Use in a default switch case indicates that we fully expect the block to be unreachable,
 * and will throw an exception if entered at runtime.
 *
 * TS should complain if the supplied value was not narrowed to `never` by preceding case matches.
 */
export function exhaustiveCaseGuard(_v: never): never {
  if (process.env.NODE_ENV === "development") {
    // probably not close to perfect, but better than nothing;
    // some minor informational blob
    debugger;
    throw new Error("non-exhaustive EXHAUSTIVE_CASE_GUARD")
  }

  throw Error("unreachable");
}

/**
 * For assumed unreachable paths; typically `exhaustiveCaseGuard` is a better option,
 * if you can prove that some variant has had all its cases handled; but sometimes it is
 * not possible to prove exhaustivity and so this is the "proofless" version of `exhaustiveCaseGuard`.
 */
export function unreachable(msg = "unreachable") : never {
  if (process.env.NODE_ENV === "development") {
    debugger;
  }
  throw Error(msg)
}

/**
 * sometimes we want to check that we handled all "expected cases", but also
 * allow for unexpected cases, esp. in situations where data is likely to be malformed and
 * we want to return a default or something, rather than crash.
 */
export function nonThrowingExhaustiveCaseGuard(_v: never) : void {}

/**
 * Transforms a function type signature by changing it's return type to void, but leaves the argument types unchanged
 * If `T` is not a function, returns `T`
 */
type FuncButReturnVoid<T> = T extends (...args: infer Args) => unknown ? ((...args: Args) => void) : T

/**
 * Vue defines a helper type called `ExtractPropTypes` but doesn't seem to offer a similar one to extract emits handler defintions
 * This converts the `emits` definition of a component into a type suitable for use as a handler definition in a component that consumes the events
 *
 * Usage is like
 *
 * ```
 * declare const someEmitsDef : <some-static-type>;
 * export type HandlersForThisComponent = ExtractEmitsHandlers<typeof someEmitsDef>;
 * export default defineComponent({emits: someEmitsDef, ...})
 * // later
 * import { HandlersForThisComponent as HandlersForXYZ } from "..."
 * const handleAllTheEvents : HandlersForXYZ = { strongly-typed-event-handlers }
 * ```
 */
export type ExtractEmitsHandlers<T> = {[P in keyof T]: FuncButReturnVoid<T[P]>}
export type ExtractOnEmitsHandlers<T> = {[P in keyof T as P extends string ? `on${Capitalize<P>}` : never]: FuncButReturnVoid<T[P]>}

//
// help "drop" default properties when inferring prop types, for use in computing the propTypes required from a component invoker.
// That is, a propdef like {required: false, type: String, default: "x"}, is definitely a string inside the component, but
// at the callsite it is optional. We want have the above propDef to feed to vue; but, to compute the externally visible type, the propdef should
// be {required: false, type: String} --- however, we need to instantiate {required: false, type: String, default: never}, because
// the more obvious approach of {[P in keyof T]: Omit<T[P], "default">} seems to be a no-op, for reasons that aren't currently understood.
//
export type OmitDefaultsFromPropsDefs<T> = {[P in keyof T]: "default" extends keyof T[P] ? (T[P] & {default: never}) : T[P]}

/**
 * create a nodeRef for use as the following
 * ```
 * const someNodeRef = FK_nodeRef()
 * <FormKit type="..." ref={someNodeRef} /> // N.B. __NOT__ someNodeRef.value, this is different from most ref uses
 * <FormKitMessages node={someNodeRef.value?.node}
 * ```
 */
export function FK_nodeRef() {
  return ref<undefined | {node?: FormKitNode}>()
}

export function FK_validation_strlen(minInclusive: number, maxInclusive: number) : FormKitValidationRule {
  return ["length", minInclusive, maxInclusive]
}

/**
 * Formkit chooses not to re-render when the `value` prop changes; this is typically a very breaking change from how html/vue forms work.
 * So, to use a reactive 1-way binding with a value prop, you need to key the element. This formalizes the workaround.
 */
export const fkKey_reactToChangeValueLikeNormal = (name: string, value: string | number | boolean) => `${name}/${value}`

const cfTruePattern = /^\s*(yes|true)\s*$/i;
const cfFalsePattern = /^\s*(no|false)\s*$/i;

/**
 * Check if a value that the caller believes represents a cf-boolean is cf-truthy
 * This is not really a "cast-to-bool", since we're more lenient than a cf-boolean conversion,
 * because it will return false when not matching a cf-truthy value, whereas cf would throw an invalid cast exception
 */
export function isCfTruthy(v: string | number) : boolean {
  if (typeof v === "number") {
    return v !== 0;
  }
  else {
    const maybeInt = parseInt(v);
    if (!isNaN(maybeInt)) {
      return maybeInt !== 0;
    }
    return cfTruePattern.test(v);
  }
}

export function isCfFalsy(v: string | number) : boolean {
  if (typeof v === "number") {
    return v === 0;
  }
  else {
    const maybeInt = parseInt(v);
    if (!isNaN(maybeInt)) {
      return maybeInt === 0;
    }
    return cfFalsePattern.test(v);
  }
}

/**
 * do an unsafe cast but make it clear that there's weirdness about it
 */
export function __FIXME__UNSAFE_CAST<T>(v: any) : T {
  return v as T
};

const IL_MODULE_PRIVATE_MARK_RAW_TAG = Symbol();

/**
 * Help strongly type `markRaw`'d objects
 *
 * markRaw<T> yields `T & {[markRawSymbol]?: true}`, but since [markRawSymbol] is optional, T is always assignable to markRaw<T>.
 * So here we create a type that is not assignable from T unless it has been appropriately branded.
 *
 * Primarily this enforces "if a function says it returns a markRaw, then it has to be confirmably markRaw'd at compile time"
 *
 * We want to (or rather, are experimenting with "do we want to") markRaw to bundle all the state up for a single component "subfeature" when that subfeature need not be its own component,
 * but the ref/reactive unwrapping of the resulting nested properties is unergonomic if we do `ref(subfeatureState())`.
 *
 * `ref(V)` recursively unwraps nested refs in V, so we lose info about contained (ComputedRef<T> / Ref<T>)'s, and they collapse to their contained T
 * This is especially unfortunate in the case of ComputedRef<T>, which conceptually collapses to `readonly T` (can't assign to a computed ref), but the compiler sees only `T` (writeable!)
 *
 * This idea is basically "composables" in the vue3 sense, but composables appear (?) to require destructuring at use-sites,
 * so you can't keep a composable's results under a single "owning" object
 *
 * using markRaw prevents unwrapping, to enforce more "correct-by-construction" usage in the component, the tradeoff being that we need to say ".value" in the template
 *
 * @deprecated Every use of this ought to be replaceable by just using a `shallowRef(...nested refs...)`
 */
export type VueNeverUnwrappable<T extends object> = ReturnType<typeof markRaw<T>> & {[IL_MODULE_PRIVATE_MARK_RAW_TAG]: void}
/**
 * @deprecated see notes on type of same name
 */
export function VueNeverUnwrappable<T extends object>(v: T) : VueNeverUnwrappable<T> {
  return markRaw(v) as any;
}

/**
 * Provides autocomplete hints for the provided strings, but performs no checking
 * e.g. UncheckedAutocomplete<"foo" | "bar"> will provide autocomplete for "foo" and "bar", but any string is a valid value
 */
export type UncheckedAutocomplete<T extends string> = (string & {}) | T

// Not exhaustive of possible productions but slightly better than the default js engine behavior,
// where `parseFloat("4garbage.2") is `4`.
const intPattern = /^\s*-?\d+\s*$/;
const floatPattern = /^\s*-?((\d+\.\d+)|(\.?\d+))\s*$/

export function parseIntOr<T>(v: any, _default: T) : number | T {
  if (!intPattern.test(v)) { // /\d+/.test(42) === /\d+/.test("42")
    return _default;
  }
  const temp = parseInt(v);
  return isNaN(temp) ? _default : temp;
}

export function parseIntOrFail(v: any) : number {
  const ret = parseIntOr(v, null);
  if (ret === null) {
    throw Error(`Expected an integer but got '${JSON.stringify(v)}'`);
  }
  return ret;
}

export function parseFloatOr<T>(v: any, _default: T) : number | T {
  if (typeof v === "number") {
    return v;
  }
  if (!floatPattern.test(v)) {
    return _default;
  }
  const temp = parseFloat(v);
  return isNaN(temp) ? _default : temp;
}

export function parseFloatOrFail(v: any) : number {
  const f = parseFloatOr(v, null);
  if (f === null) {
    throw Error(`Expected a float but got '${JSON.stringify(v)}'`);
  }
  else {
    return f;
  }
}

/**
 * Object.keys can't safely offer exact keyof info, because an object of type T may be a subtype of type T at runtime,
 * so it naturally returns `string[]`.
 *
 * e.g.
 * ```
 * function foo(v : {foo:string}) { return Object.keys(v); } // expecting ["foo"]
 * foo({foo: "", bar: ""}) // valid, but get ["foo", "bar"]
 * ```
 *
 * It is often safe to do so in practice however, if we have more info than the typechecker that some runtime type T is "exactly T".
 * TODO: name like "checkedObjectKeys" (ala checkedObjectValues and checkedObjectEntries)
 */
export function unsafe_objectKeys<T extends object>(obj: T) : (keyof T)[] {
  return Object.keys(obj) as any;
}

// `Omit` doesn't check the keyof constraint by itself?
export type CheckedOmit<T extends object, Ks extends keyof T> = Omit<T, Ks>

/**
 * in dev mode, throws an error
 * In production mode, logs a console warning
 */
export function logOrThrow(msg: string) : void {
  if (process.env.NODE_ENV === "development") {
    throw msg;
  }
  else {
    console.warn(msg);
  }
}

//
// formkit details that we should take into account:
// If T is something other than string, then formkit will mutate all your UiOption<T> to become:
// {value: `__mask_${number}`, __original: T}
// Which is sort of reasonable in the "T is some object" case, but is slightly unintuitive,
// and makes for some occasionally difficult testing scenarios.
//
// Also, we have <Formkit options={...}/> where options is typed as non-readonly, and this typing is __correct__,
// because FormKit will write into your options values.
//
// TLDR: using T=<anything other than string> here will produce semantics different from that of the T=string case; use with caution.
//
export interface UiOption<T = string> {
  label: string,
  value: T,
  attrs?: {[key: string]: any}
}

export interface UiOptions<T = string> {
  disabled: boolean,
  options: UiOption<T>[]
}

export function noAvailableOptions(label: string = "No available options.") : UiOptions {
  return {disabled: true, options: [{label, value: ""}]}
}

/**
* An "opaque key" is opaque because it has no meaning to the outside world.
* The only guarantee is that it is always unique from previous values.
*/
export const nextOpaqueVueKey : () => string = (() => {
 let v = 1;
 return () => `${v++}`
})();

/**
 * Creates a "hidden" (still clickable) "a" element, targeting the URL, clicks it, then deletes itself from DOM
 */
export function downloadFromObjectURL(urlSource: BlobPart | Blob | string, filename: string) : void {
  let url : string;
  if (typeof urlSource === "string") {
    const blob = new Blob([urlSource], {type: "text/plain"});
    url = URL.createObjectURL(blob)
  }
  else if (urlSource instanceof Blob) {
    url = URL.createObjectURL(urlSource);
  }
  else {
    url = URL.createObjectURL(new Blob([urlSource]));
  }

  const a = document.createElement("a")
  a.href = url;
  a.download = filename;
  a.style.height = "0";
  a.style.width = "0";
  a.style.position = "fixed";
  a.style.top = "0";
  a.style.left = "0";
  document.body.appendChild(a)
  a.click();
  a.remove();
}

// A | B -> A & B
export type UnionToIntersection<T> =
  (T extends any ? (v: T) => any : never) extends ((v: infer U) => any)
  ? U
  : never;

export type Writeable<T> = {-readonly [P in keyof T]: T[P]};

/**
 * get the underlying element type T from `T[]`
 */
export type ArrayElement_t<T extends any[]> = T[number]

const regexGuidPatternNoCase = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
const regexGuidPatternUpper = /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/;
const regexGuidPatternLower = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
export function isGuid(v: any, requireCase : "either" | "upper" | "lower" = "either") : boolean {
  switch (requireCase) {
    case "either": return (typeof v === "string") && regexGuidPatternNoCase.test(v);
    case "upper": return (typeof v === "string") && regexGuidPatternUpper.test(v);
    case "lower": return (typeof v === "string") && regexGuidPatternLower.test(v);
    default: exhaustiveCaseGuard(requireCase);
  }
}

/**
 * suitable for use as an array filter predicate
 */
export function isGuidUpper(v: any) : v is Guid {
  return isGuid(v, "upper")
}

export function guidOr<T>(v: any, fallback: T) : Guid | T {
  return isGuid(v, "upper") ? v : fallback;
}

export const vueDirective_onMountClickIt : Directive = {
  mounted: el => { el.click() }
}

export type FormKitValidationRule = [rule: string, ...args: any]
export interface Reflike<T = any> {value: T}

/**
 * Has no logical runtime effect, just captures type information for use later in some callback
 * where we know the flow analysis remains valid, but TS is more conservative.
 *
 * Also useful for places where we'd like to be paranoid about state being mutated out from under us, during
 * an await.
 *
 * Saying "const x = flowCapture(y)" isn't strictly necessary, it's the same as `const x = y`, but it documents intent.
 *
 * Note that this probably discards reactivity; it's not intended to work with a top-level reactive object,
 * but rather leaf-level primitives ("this string definitely exists" and etc.).
 *
 * e.g.
 * ```
 * declare let v : {a: number} | {b: number}
 * if ("a" in v) {
 *   // we know this lambda is not stored and called "later", so the flow type from the if will always be good
 *   someArray.filter(() => v.a) // error, property a does not exist on type {b: number}
 * }
 * ```
 * can become
 * ```
 * if ("a" in v) {
 *   const a = flowCapture(v.a)
 *   someArray.filter(() => a)
 * }
 * ```
 *
 * see: https://github.com/microsoft/TypeScript/issues/11498
 */
export function flowCapture<T>(v:T) { return v; }

/**
 * Usage is like:
 * ```
 * [{a:3},{a:1},{a:2}].sort(sortBy(v => v.a)) // [{a:1},{a:2},{a:3}]
 * ```
 */
export function sortBy<T>(f: (_: T) => any, dir : "asc" | "desc" = "asc") {
  return (l: T, r: T) : /*-1 | 0 | 1*/ number => {
    const xl = f(l);
    const xr = f(r);
    return (xl < xr ? -1 : xl === xr ? 0 : 1) * (dir === "asc" ? 1 : -1);
  }
}

/**
 * zip `l` and `r` together; `l` and `r` must be exactly the same length
 */
export function zipExact<L,R>(l: L[], r: R[]) : [L,R][] {
  assertTruthy(l.length === r.length);
  const result : [L,R][] = []
  for (let i = 0; i < l.length; i++) {
    result.push([l[i], r[i]])
  }
  return result;
}

/**
 * [1,2,2,3].uniqueBy(identity) <==> [1,2,3]
 * [{a:1},{a:2},{a:2},{a:3}].uniqueBy(_ => _.a) <==> [{a:1},{a:2},{a:3}]
 */
export function uniqueBy<T>(f: (_: T) => any) : (_: T) => boolean {
  const seen = new Set()
  return (v:T) => {
    const k = f(v)
    if (seen.has(k)) {
      return false
    }
    else {
      seen.add(k)
      return true;
    }
  }
}

export function identity<T>(v:T) { return v; }

/**
 * @param f -- should return either an instance of dayjs, or something that can be converted to dayjs via a call to the dayjs constructor
 */
export function sortByDayJS<T>(f: (_:T) => any, unit: dayjs.OpUnitType = "s", dir: SortDir = "asc") {
  return (l: T, r: T) => {
    const _xl = f(l)
    const _xr = f(r)

    const xl = dayjs.isDayjs(_xl) ? _xl : dayjs(_xl);
    const xr = dayjs.isDayjs(_xr) ? _xr : dayjs(_xr);

    return (xl.isBefore(xr, unit) ? -1 : xl.isSame(xr, unit) ? 0 : 1) * (dir === "asc" ? 1 : -1);
  }
}

/**
 * Ordered sort across multiple fields (e.g. sort by X then tie break by Y then tie break by Z ...)
 * Usage is like:
 * ```
 * [].sort(sortByMany(
 *  sortBy(...),
 *  sortBy(...),
 *  sortBy(...),
 * ))
 * ```
 */
export function sortByMany<T>(...sorters: ((l:T,r:T) => number)[]) {
  return (l: T, r: T) => {
    for (const sorter of sorters) {
      const v = sorter(l,r);
      if (v === 0) {
        continue;
      }
      else {
        return v;
      }
    }
    // every sorter was equal
    return 0;
  }
}

/**
 * Sort `T`s by first mapping `T`s to `U`s and then applying a sorter on `U`s.
 * The conversion from `T` to `U` is cached by object identity semantics on `T`,
 * so `f` will be invoked exactly once per unique `T` per sort run.
 *
 * WARNING: The cache is held for the lifetime of the sorter, so this sorter has state.
 * You might not want such state to persist for a long time, where the cache may produce wrong results
 * if object identities remain the same but properties of those objects change, invalidating the cache.
 * That is, if each sort run requires a new cache, then this sorter cannot be reused.
 */
export function sortByCachedMap<T,U>(f: (v:T) => U, sorter: (l: U, r: U) => number) {
  const cache = new Map<T,U>()

  const get = (v: T) : U => {
    let xv : U | undefined = cache.get(v)
    if (xv !== undefined) {
      return xv;
    }
    else {
      if (!cache.has(v)) {
        xv = f(v)
        cache.set(v, xv)
        return xv;
      }
      else {
        // the mapping was already computed, but was mapped to literally `undefined`
        return xv as U;
      }
    }
  }

  return (l: T, r: T) => sorter(get(l), get(r));
}

/**
 * Sort `T`s by first mapping `T`s to `U`s and then applying a sorter on `U`s.
 * If conversion from `T` to `U` is expensive, consider using the cached version, but also
 * consider cache lifetime.
 */
export function sortByMap<T,U>(f: (v:T) => U, sorter: (l: U, r: U) => number) {
  return (l: T, r: T) => sorter(f(l), f(r));
}

export type DeepConst<T> = T extends (infer U)[]
  ? (readonly DeepConst<U>[])
  : T extends (((...args: any[]) => any) | BigInt | symbol | string | number | boolean | null | undefined) ? T
  : T extends object ? {readonly [P in keyof T]: DeepConst<T[P]>}
  : never;

// the default Object.assign type allows any object type for `fresh`
export function checkedObjectAssign<T extends object, U extends T>(target: T, fresh: U) : T {
  return Object.assign(target, fresh);
}

export function checkedObjectValues<T>(v: {[k: string | number | symbol]: T}) : T[] {
  return Object.values(v)
}

export function checkedObjectEntries<Ks extends string, T>(v: {[K in Ks]: T}) : [Ks,T][] {
  return Object.entries(v) as any
}

export function mapObject<T extends object, R, K extends keyof T = keyof T, V extends T[K] = T[K]>(
  o: T,
  f: (v: V, k: K) => R
) : {[P in K]: R} {
  const result : any = {}
  for (const [k,v] of checkedObjectEntries(o)) {
    result[k] = f(v, k)
  }
  return result;
}

export function arraySum(vs: number[]) : number {
  let ret = 0;
  vs.forEach(v => { ret += v; });
  return ret;
}

/**
 * Intendend to allow arrays to participate in null chaining, like it would be nice to say
 * ```
 * ([] ?? 42) === 42
 * ([] || 42) === 42
 * ```
 * But `[]` is truthy.
 * Instead we can say
 * ```
 * arrayIfSomeOr([], 42)
 * ```
 */
export function arrayIfSomeOr<T, U>(xs: T[] | null | undefined, fallback: U) : T[] | U {
  return (xs && xs.length > 0) ? xs : fallback;
}

export function arrayFindIndexOrFail<T>(vs: readonly T[], f: (_: T) => boolean) : number {
  const idx = vs.findIndex(f)
  if (idx === -1) {
    throw Error("found no matching element")
  }
  return idx;
}

export function arrayFindOrFail<T>(vs: readonly T[], f: (_: T) => boolean) : T {
  return vs[arrayFindIndexOrFail(vs, f)];
}

/**
 * Alias for `filter`, e.g. returning `true` from the callback means "retain this element"
 */
export function arrayRetainIf<T>(vs: readonly T[], f: (_: T) => boolean) : T[] {
  return vs.filter(f)
}

/**
 * The inverse of `retainIf`, e.g. returning `true` from the callback means "drop this element"
 */
export function arrayDropIf<T>(vs: readonly T[], f: (_: T) => boolean) : T[] {
  return vs.filter(v => !f(v))
}

export function arrayDeleteAt(vs: any[], idx: number) : void {
  vs.splice(idx, 1)
}

/**
 * workaround for weird formkit behavior where vue's reactivity and formkit's reactivity are different,
 * and touching vue refs inside of formkit validation handlers doesn't work in "the normal vue way".
 * Usage is like:
 * ```JSX
 * const someValidationRuleThatIsLiterallyJustAComputed =
 *  computed(() => someRef.value === 42)
 * const fk_thatRuleButInAWayFormKitCanUse =
 *   FK_useSomeNonFkBoolAsValidation(() => someValidationRuleThatIsLiterallyJustAComputed.value)
 *
 * <FormKit type="..."
 *  validation={[["fk_thatRuleButInAWayFormKitCanUse"]]}
 *  validationRules={{fk_thatRuleButInAWayFormKitCanUse}} />
 * ```
 */
export function FK_useSomeNonFkBoolAsValidation(f: () => boolean) {
  return (node: FormKitNode) => {
    (function TOUCH_NODE_BECAUSE_FORMKIT_RUNS_THIS_ASYNC_OR_SOMETHING() { node.value; })();
    return f();
  }
}
export const FK_valid = true;
export const FK_invalid = false;
/**
 * "indeterminate" rule validation is treated as valid, because typically we don't have enough
 * information to really know if it's valid, and we don't to emit an error about some validation
 * rule being invalid, if there's not enough data to know it really isn't valid.
 */
export const FK_indeterminate = true;

/**
 * If we could apply `no-unchecked-indexed-access` to individual declarations, we wouldn't need this.
 * But it seems that that feature is whole-project-or-nothing.
 */
export function forceCheckedIndexedAccess<T>(vs: readonly T[], index: number) : T | undefined {
  return vs[index]
}

/**
 * An AbortController scoped to "the current" component (as per callsite).
 * Triggers an abort on unmount.
 */
export function useAbortController() {
  const abortController = new AbortController()
  onUnmounted(() => abortController.abort())
  return abortController
}

/**
 * These should sync with actual tailwind breakpoints.
 * TODO: source these from the tailwind config (single-source-of-truth).
 */
export const TailwindBreakpoint = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export function useWindowSize() {
  const dims = reactive({width: window.innerWidth, height: window.innerHeight})
  const cb = () => {
    dims.width = window.innerWidth
    dims.height = window.innerHeight
  }

  window.addEventListener("resize", cb)
  onUnmounted(() => window.removeEventListener("resize", cb))

  return {
    get width() { return dims.width },
    get height() { return dims.height }
  }
}

/**
 * shorthand "constructor" for very common "required vue component proptype having type T"
 */
export function vReqT<T>() {
  return {
    required: true,
    type: null as any as PropType<T>
  } as const
}

/**
 * shorthand "constructor" for very common "optional vue component proptype having type T"
 */
export function vOptT<T>() : {required: false, type: PropType<T>}
export function vOptT<T>(_default?: T | (() => T)) : {required: false, type: PropType<T>, default: () => T}
export function vOptT<T>(_default?: T | (() => T)) : {required: false, type: PropType<T>, default?: () => T} {
  const r = {
    required: false,
    type: null as any as PropType<T>
  } as const

  if (_default !== undefined) {
    if (typeof _default === "function") {
      (r as any).default = (_default as any)();
    }
    else {
      (r as any).default = _default;
    }
  }

  return r;
}

/**
 * @deprecated This doesn't really offer much convenience over a loose Ref<boolean> that indicates "ready".
 */
export function useAsyncState<T extends object>(f?: () => Promise<T>) {
  const xref = ref<
      | {ready: false}
      | ({ready: true} & T)
    >({ready: false})

  if (f) {
    f().then(v => {
      xref.value = Object.assign({ready: true}, v) as any
    })
    return xref;
  }
  else {
    return xref;
  }
}

/**
 * Creates a ref to a ref.
 *
 * The following doesn't work because of "ref unwrapping":
 * ```
 * const badRefRef = ref(ref(42))
 * badRefRef.value // 42
 * badRefRef.value.value // undefined
 * ```
 *
 * But the following does not invoke ref unwrapping, which is the behavior we want here
 * ```
 * const goodRefRef = ref()
 * goodRefRef.value = ref(42)
 * goodRefRef.value // Ref
 * goodRefRef.value.value // 42
 * ```
 *
 * This like `void** x`. Sometimes you need a pointer pointer.
 */
export function refRef<T>(v: Ref<T>) : Ref<Ref<T>> {
  const r = shallowRef();
  r.value = v;
  return r;
}

export function maybePrettifyUSPhoneNumber(s: string) : string {
  if (!s) {
    return "";
  }

  const justDigits = s.replaceAll(/[^\d]/g, "")
  if (justDigits.length === 10) {
    return [
      justDigits.slice(0, 3),
      justDigits.slice(3, 6),
      justDigits.slice(6, 10)
    ].join("-")
  }
  else {
    return s;
  }
}

export function useWindowEventListener<K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | AddEventListenerOptions) : any {
  window.addEventListener(type, listener, options);
  onUnmounted(() => { window.removeEventListener(type, listener) });
};

export type VueRouterQueryParam =
  | string
  | null
  | (string | null)[]

/**
 * as per Date.prototype.getDay()
 */
export const enum JS_DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export function setIsSame<T>(l: Set<T>, r: Set<T>) {
  if (l === r) {
    return true;
  }
  if (l.size !== r.size) {
    return false;
  }
  for (const e of l) {
    if (!r.has(e)) {
      return false;
    }
  }
  return true;

}

/**
 * clamp `v` to between (inclusive) `min` and `max`
 */
export function clamp(v: number, namedArgs: {min: number, max: number}) : number {
  const {min, max} = namedArgs;
  return Math.max(min, Math.min(v, max));
}

/**
 * This avoids getting the wrong answer with `Math.max(...vs)` when the array is huge
 */
export function max(vs: number[]) {
  let r = -Infinity
  for (const v of vs) {
    if (v > r) {
      r = v;
    }
  }
  return r;
}

/**
 * n.b. this is "utils" but is pretty deeply tied to assumptions about our app DOM structure.
 *
 * Hooks onto "print" events and replaces the page's content with some target element's HTML,
 * such that the print output contains only the target element.
 *
 * @param element type includes null because most html element refs start out life null,
 * but at time of print operation, is expected to be non null.
 * If null at time of print, no HTML will be inlcuded the generated html.
 * @param args.useShadowRoot if true, mounts HTML in a shadow root to isolate it from all page styles.
 */
export function useSimplePrintIsolator(element: Ref<HTMLElement | null>, orientation: "landscape" | "portrait", args?: {id?: string, useShadowRoot: boolean}) {
  const id = args?.id || "print-isolator";

  if (!/^[a-z]/i.test(id)) {
    throw Error("ID must start with a letter to form a valid HTML class name");
  }

  const useShadowRoot = !!args?.useShadowRoot

  const freshClassname = `${id}-${Math.floor(Math.random() * 999999)}`;
  let savedBodyPosition = ""
  let savedAppRootDisplay = ""

  useWindowEventListener("beforeprint", () => {
    const appRoot = document.querySelector<HTMLElement>("#q-app")
    if (!appRoot) {
      throw Error("Unexpected: no app root element?")
    }

    savedBodyPosition = document.body.style.position
    savedAppRootDisplay = appRoot.style.display

    document.body.style.position = "relative"
    appRoot.style.display = "none";

    const style = document.createElement("style")
    const div = document.createElement("div")

    style.classList.add(freshClassname)
    style.innerHTML = `@page { size: ${orientation}; } @media print { html { zoom: 80%; } }`

    div.classList.add(freshClassname)
    div.style.width = "100%"
    div.style.height = "100%"
    div.style.position = "absolute" // fixed "works" but then repeats on every printed page, because it's fixed
    div.style.top = "0"
    div.style.left = "0"
    div.style.backgroundColor = "white"
    div.style.zIndex = "999999"
    if (useShadowRoot) {
      div.attachShadow({mode: "open"})
      div.shadowRoot!.innerHTML = element.value?.innerHTML || ""
    }
    else {
      div.innerHTML = element.value?.innerHTML || ""
    }

    document.body.prepend(div)
    document.body.prepend(style)
  })

  useWindowEventListener("afterprint", () => {
    document.body.style.position = savedBodyPosition
    document.querySelector<HTMLElement>("#q-app")!.style.display = savedAppRootDisplay
    document.querySelectorAll<HTMLElement>(`.${freshClassname}`).forEach(e => e.remove());
  })
}

/**
 * If the user saves the file on disk after having put it into the form, we get an error when trying to
 * access it again to send it across the wire.
 *
 * Conceptually the browser takes a snapshot of the file when it is put in the form, and a snapshot at later times of use,
 * and if the snapshots aren't the same (last modified time, or byte content, or whatever it chooses to use) the file
 * becomes inaccessible.
 *
 * n.b. there is a race here between caller of this function and time that same caller uses the file to push over the wire via http,
 * but that is a small window for a human-scale operation (e.g. click submit on a form having a ref to a file, and then save the file in
 * some external editor)
 */
export async function checkFileSnapshotConsistency(file: File) : Promise<boolean> {
  try {
    const reader = new FileReader()
    const p = new ExplodedPromise<void>();
    reader.addEventListener("abort", e => p.reject(e))
    reader.addEventListener("error", e => p.reject(e))
    reader.addEventListener("loadend", e => p.resolve());
    reader.readAsArrayBuffer(file);
    await p.promise;
    // if we get here, we were allowed to read the whole thing
    return true;
  }
  catch (err) {
    // couldn't read it
    return false;
  }
}

export const fileSnapshotInconsistencyWarning = "File couldn't be read, likely due to having been updated since it was last put into the form. Try reloading the file."

/**
 * Return `vs` doubled `n` times,
 * e.g. doubled once [a] -> [a,a]
 * doubled twice [a] -> [a,a,a,a]
 * etc.
 *
 * This is mostly for dev where we want to see what a large list of HTML elements would roughly look like,
 * but we only have a small list.
 */
export function arrayDouble<T>(vs: T[], n: number) : T[] {
  let r = [...vs]
  for (let i = 0; i < n; ++i) {
    r = [...r, ...r]
  }
  return r;
}


/**
 * A ref that will be initialized later. Reading `.value` prior to initialization is an error.
 * Useful anywhere where we'd like to do
 * ```
 * const foo = ref<T>(null as any) // definitely assigned in some later phase soon
 * ```
 */
export class LazyRef<T> {
  private readonly didInit_ = ref(false);
  private readonly value_ = ref<T | undefined>(undefined);

  isInit() : boolean {
    return this.didInit_.value
  }

  get value() : T {
    if (this.isInit()) {
      return this.value_.value as T;
    }
    throw Error("`lazyRef` accessed before initialization")
  }

  get maybeValue() : T | undefined {
    return this.value_.value as any;
  }

  set value(v: T) {
    this.didInit_.value = true;
    this.value_.value = v as any;
  }
}

/**
 * @see {@link LazyRef}
 *
 * Sugar over the `LazyRef<T>` constructor, just to avoid saying "new".
 */
export function lazyRef<T>() {
  return new LazyRef<T>();
}

export function routeGetQueryParamAsStringOrNull(v: LocationQueryValue | LocationQueryValue[]) : string | null {
  return typeof v === "string" ? v : null;
}

export function routerGetQueryParamAsStringOrNull(router: RouteLocationNormalizedLoaded, queryParamName: string) : string | null {
  const v = router.query[queryParamName];
  return typeof v === "string" ? v : null;
}

export function routeGetQueryParamAsStringArrayOrNull(v: LocationQueryValue | LocationQueryValue[]) : string[] | null {
  return typeof v === "string"
    ? [v]
    : Array.isArray(v)
    ? v.filter(v => typeof v === "string") as string[]
    : null;
}

export function routerGetQueryParamAsStringArrayOrNull(router: RouteLocationNormalizedLoaded, queryParamName: string) : string[] | null {
  return routeGetQueryParamAsStringArrayOrNull(router.query[queryParamName])
}

export type SortDir = "asc" | "desc"

export function isSortDir(v: any) : v is SortDir {
  return v === "asc" || v === "desc"
}

/**
 * All operations happen "in place", e.g. `setex.intersect` may delete elements from this
 */
export class SetEx<T> extends Set<T> {
  constructor(v?: Iterable<T> | null | undefined) {
    super(v)
  }

  addMany(vs: Iterable<T>) {
    for (const v of vs) {
      this.add(v)
    }
    return this
  }

  deleteMany(vs: Iterable<T>) {
    for (const v of vs) {
      this.delete(v);
    }
    return this
  }

  invert(v: T) {
    if (this.has(v)) {
      this.delete(v);
    }
    else {
      this.add(v)
    }
    return this
  }

  intersect(vs: Iterable<T>) : SetEx<T> {
    const other : Set<T> = vs instanceof Set ? vs : new Set(vs)
    const toDelete : T[] = []
    for (const e of this) {
      if (!other.has(e)) {
        toDelete.push(e)
      }
    }
    for (const deletable of toDelete) {
      this.delete(deletable)
    }
    return this;
  }

  subtract(vs: Iterable<T>) : SetEx<T> {
    for (const e of vs) {
      this.delete(e)
    }
    return this;
  }
}

export function isNumber(v: any) : v is number {
  return typeof v === "number"
}

export function isObject(v: any) : v is Record<string | number | symbol, any> {
  return typeof v === "object" && v !== null
}

/**
 * Form a "pointer"-like reference to `obj.k`
 * The result object has the following semantics:
 * ```
 * result.value; "<==same as==>"; obj[k];
 * result.value = 42; "<==same as==>"; obj[k] = 42;
 * ```
 */
export function ptr<T extends {}, K extends keyof T>(obj: T, k: K) {
  return {
    get value() : T[K] {
      return obj[k]
    },
    set value(v: T[K]) {
      obj[k] = v;
    }
  }
}

/**
 * Get the value mapped to `k` or else fail.
 */
export function mapGetOrFail<K,V,>(map: Map<K,V>, k: NoInfer<K>) : V {
  const v = map.get(k)
  if (v || map.has(k)) {
    // if we got a truthy `v`, then that's the mapping;
    // also, if the key exists, but the mapping was falsy, we
    // test for key presence and also return v (i.e. `map.has(k) === true`
    // implies a falsy `v` is what we're looking for).
    // In the (expected more common case) of a truthy mapping, this saves
    // one traversal for `has` and then another for `get`. Probably this
    // is not an important performance difference.
    return v as V;
  }
  else {
    throw new Error(`No such element (${k})`)
  }
}

const nameCollation = new Intl.Collator("en");
export const accentAwareCaseInsensitiveCompare = (l: string, r: string) => nameCollation.compare(l.toLowerCase(), r.toLowerCase());

export interface TeamNameParts {
  genderSpecifier: string,
  ageBracket: string | number,
  rest: string
}

/**
 * Parse a "base team name" (currently meaning "the name from the team's `team` column")
 * Which typically matches the pattern "<single-char-gender-code><numeric-age>-<other-freeform-text>"
 * The main usecase here is to support sorting teams.
 */
export function parseBaseTeamName(v: string) : Partial<TeamNameParts> {
  const m = /^(.*?)-(.*)$/.exec(v);
  if (!m) {
    return {}
  }

  const [_, head, rest] = m;
  if (!head || !rest) {
    return {}
  }

  const match = /^([a-z]+)([0-9]+)$/i.exec(head)
  if (!match) {
    return {};
  }

  const genderSpecifier = (match[1] || "").toUpperCase()
  // It's important that we _usually_ get an integer out of this, for later use in comparisons.
  // In the general case, we expect that comparing the resulting "ageBracket" is a numeric comparison,
  // but that it may, ocassionaly, rarely, degenerate to being a string and comparisons during sorting
  // will be not so great.
  const ageBracket = parseIntOr(match[2], match[2] || "")

  return {
    genderSpecifier,
    ageBracket,
    rest
  }
}

export function gatherByKey_manyPerKey<K, V>(vs: V[], genKey: (v: V) => K) : Map<K,V[]> {
  const result = new Map<K,V[]>()
  for (const v of vs) {
    const k = genKey(v)
    const mapping = result.get(k) ?? []
    mapping.push(v)
    result.set(k, mapping)
  }
  return result;
}

export type Optional<T extends {}> =
  | {type: "none"}
  | {type: "some", value: T}

/**
 * This is not a class because we have times where we want to copy optionals as part of copyViaJsonRoundTrip
 * and if data is not "plain ol' js objects" then that doesn't work.
 */
export const Optional = {
  /**
   * If `v` is null or undefined, produce an empty optional; otherwise, produce an engaged optional
   */
  of<T extends {}>(v: T | null | undefined) : Optional<T> {
    if (v === null || v === undefined) {
      return Optional.none()
    }
    else {
      return {type: "some", value: v}
    }
  },
  /**
   * produce an empty option
   */
  none<T extends {}>() : Optional<T> {
    return {type: "none"}
  },
  /**
   * retrieve the value out of some Optional if it exists, otherwise return a default value
   */
  getOr<T extends {}, U>(v: Optional<T>, default_: U) : T | U {
    return v.type === "some" ? v.value : default_;
  }
} as const

export function maybeParseJSON(str: string) {
  try {
    return JSON.parse(str)
  }
  catch {
    return undefined
  }
}

/**
 * Often we will compare things from html forms or non-strictly serialized backend data where values like "3" and 3 should be considered equivalent.
 * In those cases, we had been doing something like `foo /* not strict *_/ == bar` to be clear about the intent to use weak equality;
 * this is a 'non-comment' version of that pattern.
 */
export function weakEq<L, R extends L>(l: L, r: R) : boolean {
  return l /*weak equality, i.e. double equals (not triple)*/ == r;
}

/**
 * A watch that unregisters itself properly when started in an async context, like after an await in an async onMounted.
 * Note this must be used in the setup function (not within an async onMounted; this is the same as all other "use" functions but it bears repeating)
 */
export function useWatchLater<T>(watchSrc: WatchSource<T>, f: () => void, opts?: WatchOptions) {
  let stop : null | (() => void) = null
  const start = () => {
    stop = watch(watchSrc, f, opts)
  }

  onUnmounted(() => {
    stop?.()
  })

  return {
    start,
    stop: () => stop?.()
  }
}

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * return the range of integers from `from` to `toExc` (not including `toExc`)
 * e.g. rangeExc(0,4) -> [0,1,2,3]
 */
export function rangeExc(from: number, toExc: number) : number[] {
  const result : number[] = []
  for (let i = from; i < toExc; i++) {
    result.push(i)
  }
  return result;
}

/**
 * return the range of integers from `from` to `toInc` (including `toInc`)
 * e.g. rangeInc(1,4) -> [1,2,3,4]
 */
export function rangeInc(from: number, toInc: number) : number[] {
  const result : number[] = []
  for (let i = from; i <= toInc; i++) {
    result.push(i)
  }
  return result;
}
