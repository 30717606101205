import { UserLogEntry } from "src/composables/InleagueApiV1.User";
import { dayjsFormatOr } from "src/helpers/formatDate";
import { sortBy, sortByDayJS, vReqT } from "src/helpers/utils";
import { ColDef } from "src/modules/TableUtils";
import { freshSortState, BasicTable, typedBasicTableProps } from "src/modules/TableUtils";
import { defineComponent, computed } from "vue";

export const UserLogImpl = defineComponent({
  name: "UserLogImpl",
  props: {
    logEntries: vReqT<UserLogEntry[]>(),
  },
  setup(props) {
    const colDefs = computed(() => {
      return freshColDefs();
    });

    const keyedRows = computed(() => {
      return props.logEntries.map((v,i) : KeyedUserLogEntry => ({...v, __syntheticRenderKey: i}));
    })

    const sortState = freshSortState(colDefs.value)
    const sortedRows = computed(() => sortState.sort(keyedRows.value))

    return () => {
      return (
        <div data-test="UserLog">
          <div class="w-full overflow-x-auto shadow-md p-2 rounded-md border border-slate-100 bg-white">
            <BasicTable class="w-full" {...typedBasicTableProps({
              rowData: sortedRows.value,
              colDefs: colDefs.value,
              sortState: sortState,
              rowKey: (row, i) => row.__syntheticRenderKey,
            })}/>
            {
              props.logEntries.length === 0
                ? <div class="m-4 flex items-center justify-center">No log entries associated with this user.</div>
                : null
            }
          </div>
        </div>
      )
    }
  }
})

enum ColID {
  Date = 1,
  Record,
  Type,
  Category,
  Submitter,
  Text,
  OldValue,
  NewValue,
  System,
  Expires
}

// there's not a great natural key for this so we synthesize one
type KeyedUserLogEntry = UserLogEntry & {__syntheticRenderKey: number}

export type UserLogColDef = ColDef<KeyedUserLogEntry, ColID>

export function freshColDefs() : UserLogColDef[] {
  let colDefs : UserLogColDef[] = [
    {
      id: ColID.Date,
      label: "Date",
      html: v => (
        <div>
          <div>{dayjsFormatOr(v.date, "M/DD/YYYY")}</div>
          <div class="text-sm">{dayjsFormatOr(v.date, "h:mm a")}</div>
        </div>
      ),
      sort: sortByDayJS(_ => _.date)
    },
    {
      id: ColID.Record,
      label: "Record",
      html: v => v.entityName,
    },
    {
      id: ColID.Type,
      label: "Type",
      html: v => v.type,
    },
    {
      id: ColID.Category,
      label: "Category",
      html: v => v.category,
    },
    {
      id: ColID.Submitter,
      label: "Submitter",
      html: v => v.submitterName,
      sort: sortBy(_ => _.submitterName)
    },
    {
      id: ColID.Text,
      label: "Field",
      html: v => v.extraText,
      sort: sortBy(_ => _.extraText),
    },
    {
      id: ColID.OldValue,
      label: "Old value",
      html: v => v.oldValue,
      sort: sortBy(_ => _.oldValue),
    },
    {
      id: ColID.NewValue,
      label: "New value",
      html: v => v.newValue,
      sort: sortBy(_ => _.newValue),
    },
    {
      id: ColID.System,
      label: "System",
      html: v => v.system ? "Yes" : "No",
      sort: sortBy(_ => _.system ? 1 : 0),
    },
    {
      id: ColID.Expires,
      label: "Expires",
      html: v => dayjsFormatOr(v.expirationDate, "M/D/YYYY"),
      sort: sortByDayJS(_ => _.expirationDate),
    }
  ]

  return colDefs;
}
