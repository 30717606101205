import { rpc } from "src/composables/Rpc";
import { Competition, Guid, Season } from "src/interfaces/InleagueApiV1";

export interface TeamOption {
  teamID: Guid,
  teamDesignation: string,
  teamName: string,
}

export const getSeasonOptions = rpc<void, Season[]>("get", "v1/cloneTeamAssignmentsModal/getSeasonOptions")
export const getCompetitionOptions = rpc<void, Competition[]>("get", "v1/cloneTeamAssignmentsModal/getCompetitionOptions")
export const getTeamOptions = rpc<{competitionUID: Guid, divID: Guid, seasonUID: Guid}, TeamOption[]>("get", "v1/cloneTeamAssignmentsModal/getTeamOptions")

export interface CloneTeamAssignmentsArgs {
  sourceSeasonUID: Guid,
  destSeasonUID: Guid,
  sourceTeamID: Guid,
  destTeamID: Guid,
  includeCoachAssignments: boolean,
  includeVolunteerAssignments: boolean,
  deleteAllExistingDestPlayerAssignments: boolean,
  deleteAllExistingDestCoachAssignments: boolean,
  deleteAllExistingDestVolunteerAssignments: boolean,
}

export const cloneTeamAssignments = rpc<CloneTeamAssignmentsArgs, void>("post", "v1/cloneTeamAssignmentsModal/cloneTeamAssignments");
