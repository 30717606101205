import { assertTruthy } from "src/helpers/utils";
import { ref, computed } from "vue";

export function initInOnMountedRef<T>() {
  let isInit = false
  const v = ref<T>(null as any)
  return computed({
    get() : T {
      assertTruthy(isInit, "Ref was read from prior to initialization.");
      return v.value as T;
    },
    set(z: T) {
      isInit = true
      v.value = z as any
    }
  })
}
