import { Fragment, computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { getAllowedDivisions, propsDef, queryParams } from "./R_TeamAssignments.route";
import { EscapedRegExp, FK_nodeRef, Optional, CheckedOmit, SetEx, TailwindBreakpoint, UiOption, accentAwareCaseInsensitiveCompare, arrayDropIf, arrayFindIndexOrFail, arrayFindOrFail, arrayRetainIf, arraySum, assertIs, assertNonNull, assertTruthy, checkedObjectEntries, copyViaJsonRoundTrip, downloadFromObjectURL, exhaustiveCaseGuard, gatherByKey_manyPerKey, mapGetOrFail, maybeParseJSON, nextOpaqueVueKey, parseFloatOr, ptr, requireNonNull, routerGetQueryParamAsStringOrNull, sortBy, sortByMany, unreachable, unsafe_objectKeys, useWindowSize } from "src/helpers/utils";
import { TeamChooserMenu, getTeamChooserMenu } from "src/composables/InleagueApiV1.TeamChooser2";
import { BasicTeamChooserSelectionManager, TeamChooserSelection, getTeamChooserMenuNode, longestValidMenuPath, teamChooserSelection } from "src/components/UserInterface/TeamChooser/TeamChooserUtils";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { TeamSelectionButtons, useTeamSelectionButtonsPaneSizeWatcher } from "src/components/UserInterface/TeamChooser/TeamChooserSelectButtons";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { BulkCreateUpdateDeleteTeamAssignments, GetTeamSeasonPlayersResponse, PlayerForTeamAssignmentView, SuggestedTeamAssignmentsOptions, TeamForTeamAssignmentsView, bulkCreateUpdateDeleteTeamAssignments, getSuggestedTeamAssignments, getTeamAssignmentsViewTeams, listTeamSeasonPlayers, listUnassignedPlayers } from "src/composables/InleagueApiV1.Teams";
import { Guid, TeamID, WithDefinite } from "src/interfaces/InleagueApiV1";
import { getRegistrationPageItems, isRegistrationQuestion } from "src/composables/InleagueApiV1";
import { PlayerForTeamAssignmentViewEx, PlayerForTeamAssignmentViewExFilter, PlayerForTeamAssignmentViewEx_Assigned, PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved, PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned, PlayerForTeamAssignmentViewEx_TentativelyAssigned, PlayerForTeamAssignmentViewEx_Unassigned, PlayerLoanLookup, ResolvedSelection, TeamAverageRatings, assignedPlayerDragMimeType, assignedToTentativelyMoved, assignedToTentativelyUnassigned, disableAssignOrLoanDueToMissingBirthCert, getTeamName, globalTeamAssignmentsDragData, k_allTeamsAvgTeamID, k_selectedUnassignedAssignments, k_selectedUnassignedLoans, selectedQuestions_load, selectedQuestions_persist, tentativelyAssignedToUnassigned, tentativelyMovedToAssigned, unassignedPlayerDragMimeType, unassignedToTentativelyAssigned } from "./TeamAssignments.shared";

import { getRegistrationQuestionAnswers } from "src/composables/InleagueApiV1.Registration";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { freshAxiosInstance } from "src/boot/axios";
import { SelectRegistrationQuestionsModal } from "./SelectRegistrationQuestionsModal";
import { AutoModal, DefaultModalController, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { UnassignedPlayersElement } from "./UnassignedPlayersElement";
import { TeamElement, TeamElementSlots } from "./TeamElement";
import { useRouter } from "vue-router";
import { User } from "src/store/User";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDownToLine, faArrowUpToLine } from "@fortawesome/pro-solid-svg-icons";
import { TabDef, Tabs } from "src/components/UserInterface/Tabs";
import { PlayerLoanElement } from "./PlayerLoanElement";
import { DAYJS_FORMAT_HTML_DATETIME, dayjsFormatOr } from "src/helpers/formatDate";
import dayjs from "dayjs";
import { getCompetitionOrFail } from "src/store/Competitions";
import { Client } from "src/store/Client";
import { createSnapshot, loadSnapshot, snapshotSchema } from "./Snapshot";
import { Value } from "@sinclair/typebox/value"
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { vueDirective_ilDropTarget, ilDropTarget, ilDraggable } from "src/modules/ilDraggable"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { CloneTeamModal } from "./CloneTeamAssignmentsModal";
import { cloneTeamAssignments } from "./CloneTeamAssignmentsModal.io";
import { AxiosInstance } from "axios";
import { teamDesignationAndMaybeName } from "src/components/GameScheduler/calendar/GameScheduler.shared";

export default defineComponent({
  name: "R_TeamAssignments",
  props: propsDef,
  directives: {
    ilDropTarget: vueDirective_ilDropTarget
  },
  setup() {
    const menuSelection = ref<TeamChooserSelection>(teamChooserSelection())
    const menuCompetition = ReactiveReifiedPromise({
      deps: [() => menuSelection.value.competitionUID],
      f: async () => menuSelection.value.competitionUID
        ? await getCompetitionOrFail(menuSelection.value.competitionUID)
        : null
    }).map(p => {
      return p.status === "pending" ? "pending" : p.status === "resolved" ? p.data : null;
    })

    const menu = ref<TeamChooserMenu>(/*definitely assigned in onMounted*/ null as any)
    const menuSelectionManager = ref<BasicTeamChooserSelectionManager>(/*definitely assigned in onMounted*/ null as any)
    const teamChooserButtonContainer = useTeamSelectionButtonsPaneSizeWatcher()
    /**
     * If there is a modal open, typically it will be to do work against some particular team.
     * This is the teamID that modal is "focused" on. Modal open/close actions should update it appropriately.
     */
    const currentModalTargetTeamID = ref<Guid | null>(null)
    const ready = ref(false)

    const resolvedSelection = ref<null | ResolvedSelection>(null)
    const includeNeighboringDivs = ref(false)
    const isInLoadedFromSnapshotMode = computed(() => {
      // If there is a snapshotConfig, we are in "loaded from snapshot"
      // To exit this mode, do one of:
      //  - saving pending changes
      //  - discard pending changes
      // Both of which are expected to do a full refresh of player data, and rebuild `resolvedSelection` sans snapshotConfig
      return !!resolvedSelection.value?.snapshotConfig
    })

    const emptyFilter = () : PlayerForTeamAssignmentViewExFilter => {
      return {
        city: "",
        dob: "",
        grades: [],
        playerName: "",
        school: "",
        zip: [],
        street: "",
      }
    }

    const unassignedPlayersFilter = ref(emptyFilter())

    const clearUnassignedPlayersFilters = () => {
      unassignedPlayersFilter.value = emptyFilter()
    }

    const hasSomeNonEmptyUnassignedPlayersFilters = computed(() => {
      // intent here is to realias all the properties so that we can get a compile time error if we add some,
      // and remember we need to check it here, too.
      const checkedCopy : PlayerForTeamAssignmentViewExFilter = {
        city: unassignedPlayersFilter.value.city,
        dob: unassignedPlayersFilter.value.dob,
        grades: unassignedPlayersFilter.value.grades,
        playerName: unassignedPlayersFilter.value.playerName,
        school: unassignedPlayersFilter.value.school,
        zip: unassignedPlayersFilter.value.zip,
        street: unassignedPlayersFilter.value.street,
      }

      return checkedCopy.city.length > 0
        || checkedCopy.dob.length > 0
        || checkedCopy.grades.length > 0
        || checkedCopy.playerName.length > 0
        || checkedCopy.school.length > 0
        || checkedCopy.zip.length > 0
        || checkedCopy.street.length > 0
    })

    const filteredUnassignedPool = computed(() => {
      const filters : {[K in keyof PlayerForTeamAssignmentViewExFilter]: (_: PlayerForTeamAssignmentViewEx) => boolean} = {
        city: (() => {
          if (unassignedPlayersFilter.value.city.trim() === "") {
            return () => true
          }
          else {
            const pattern = EscapedRegExp(unassignedPlayersFilter.value.city.trim(), "i")
            return v => pattern.test(v.apiData.child.parent1City)
          }
        })(),
        dob: (() => {
          if (unassignedPlayersFilter.value.dob.trim() === "") {
            return () => true
          }
          else {
            const date = dayjs(unassignedPlayersFilter.value.dob)
            return v => {
              return date.isSame(v.apiData.child.playerBirthDate, "days")
            }
          }
        })(),
        grades: (() => {
          if (unassignedPlayersFilter.value.grades.length === 0) {
            return () => true
          }
          else {
            return v => {
              // defensively paranoiac calls to .toString and .toLowerCase
              return !!unassignedPlayersFilter.value.grades.find(grade => grade.toString().toLowerCase() /*not strict*/ == v.apiData.registration.grade.toString().toLowerCase())
            }
          }
        })(),
        playerName: (() => {
          if (unassignedPlayersFilter.value.playerName.trim() === "") {
            return () => true
          }
          else {
            const pattern = EscapedRegExp(unassignedPlayersFilter.value.playerName.trim(), "i")
            return v => pattern.test(`${v.apiData.child.playerFirstName} ${v.apiData.child.playerLastName}`)
          }
        })(),
        school: (() => {
          if (unassignedPlayersFilter.value.school.trim() === "") {
            return () => true
          }
          else {
            const pattern = EscapedRegExp(unassignedPlayersFilter.value.school.trim(), "i")
            return v => pattern.test(v.apiData.registration.playerSchool)
          }
        })(),
        zip: (() => {
          if (unassignedPlayersFilter.value.zip.length === 0) {
            return () => true
          }
          else {
            return v => {
              // defensively paranoiac calls to .toString and .toLowerCase
              return !!unassignedPlayersFilter.value.zip.find(zip => zip.toString().toLowerCase() /*not strict*/ == v.apiData.child.parent1Zip.toString().toLowerCase())
            }
          }
        })(),
        street: (() => {
          if (unassignedPlayersFilter.value.street.trim() === "") {
            return () => true
          }
          else {
            const pattern = EscapedRegExp(unassignedPlayersFilter.value.street.trim(), "i")
            return v => pattern.test(`${v.apiData.child.parent1Street} ${v.apiData.child.parent1Street2}`)
          }
        })()
      }

      return resolvedSelection.value?.assignments.unassignedPool.filter(v => {
        return filters.city(v)
          && filters.dob(v)
          && filters.grades(v)
          && filters.playerName(v)
          && filters.school(v)
          && filters.zip(v)
          && filters.street(v)
      }) ?? [];
    })

    const seasonName = computed(() => {
      const {seasonUID} = menuSelection.value;
      if (menu.value && seasonUID) {
        return getTeamChooserMenuNode(menu.value, "season", {seasonUID})?.name || "";
      }
      else {
        return ""
      }
    })

    const playerLoanSearchText = ref("")
    watch(() => playerLoanSearchText.value, () => {
      if (!resolvedSelection.value) {
        return;
      }
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID) {
        return;
      }
      resolvedSelection
        .value
        .loans
        .searchResults
        .debouncedDoRunSearch({seasonUID, competitionUID, divID, searchText: playerLoanSearchText.value}, () => {
          resolvedSelection.value?.selectedPlayerIDsByTeamIDish.set(k_selectedUnassignedLoans, new SetEx());
        });
    })

    const hasPendingChanges = computed(() => {
      if (!resolvedSelection.value) {
        return false;
      }

      if (isInLoadedFromSnapshotMode.value) {
        return true
      }

      for (const player of resolvedSelection.value.assignments.unassignedPool) {
        switch (player.type) {
          case "assigned-but-tentatively-unassigned":
            return true;
          case "unassigned":
            continue;
          default: unreachable()
        }
      }

      for (const teamID of unsafe_objectKeys(resolvedSelection.value.assignments.byTeam)) {
        for (const player of resolvedSelection.value.assignments.byTeam[teamID]) {
          switch (player.type) {
            case "assigned-but-tentatively-moved":
              // fallthrough
            case "tentatively-assigned":
              return true;
            case "assigned":
                continue;
            default: unreachable()
          }
        }
      }

      for (const teamID of unsafe_objectKeys(resolvedSelection.value.loans.byTeam)) {
        for (const player of resolvedSelection.value.loans.byTeam[teamID]) {
          if (isDirtyPlayerLoan(player)) {
            return true;
          }
          switch (player.type) {
            case "assigned-but-tentatively-unassigned":
              // fallthrough
            case "assigned-but-tentatively-moved":
              // fallthrough
            case "tentatively-assigned":
              return true;
            case "assigned":
                continue;
            default: unreachable()
          }
        }
      }

      return false;
    })

    watch(() => resolvedSelection.value?.selectedQuestions, (v) => {
      if (!User.value.userID || !resolvedSelection.value?.selectedQuestions) {
        return;
      }
      selectedQuestions_persist(User.value.userID, resolvedSelection.value.selectedQuestions);
    }, {deep: true})

    const teamAverageRatings = computed<TeamAverageRatings>(() => {
      const accums = {
        lifetimeByTeamID: new Map<TeamID, AvgAccum>(),
        mostRecentSeasonByTeamID: new Map<TeamID, AvgAccum>(),
      }

      Object
        .entries(resolvedSelection.value?.assignments.byTeam ?? {})
        .forEach(([teamID, players]) => {
          const lifetimeByTeamID = freshAvgAccum()
          accums.lifetimeByTeamID.set(teamID, lifetimeByTeamID)

          const mostRecentSeasonByTeamID = freshAvgAccum()
          accums.mostRecentSeasonByTeamID.set(teamID, mostRecentSeasonByTeamID)

          for (const player of players) {
            const v = parseFloatOr(player.apiData.registration.ratingAvg, null)
            if (v === null) {
              continue;
            }
            lifetimeByTeamID.count += 1;
            lifetimeByTeamID.sum += v;
          }

          for (const player of players) {
            const v = parseFloatOr(player.apiData.registration.ratingRecent, null)
            if (v === null) {
              continue;
            }
            mostRecentSeasonByTeamID.count += 1;
            mostRecentSeasonByTeamID.sum += v;
          }
        });

      return {
        lifetimeByTeamID: wrap(finalize(accums.lifetimeByTeamID)),
        mostRecentSeasonByTeamID: wrap(finalize(accums.mostRecentSeasonByTeamID))
      }

      function wrap(m: Map<TeamID | typeof k_allTeamsAvgTeamID, number | null>) : TeamAverageRatings["mostRecentSeasonByTeamID"] {
        return {
          getDisplayValue: (teamID) => m.get(teamID)?.toFixed(1) ?? "N/A",
          getRaw: (teamID) => m.get(teamID) ?? null
        }
      }

      function finalize(v: Map<TeamID, AvgAccum>) : Map<TeamID | typeof k_allTeamsAvgTeamID, number | null> {
        const avgs = new Map<TeamID, number | null>([...v.entries()].map(([teamID, {count, sum}]) => {
          return [
            teamID,
            count === 0
              ? null // a team may be present but have no associated ratings
              : sum / count
          ]
        }));

        const overallAvg = (() => {
          const allRelevant = [...v.values()].filter(v => v.count > 0)

          if (allRelevant.length === 0) {
            return null;
          }

          const overallPoints = arraySum(allRelevant.map(v => v.sum))
          const overallCount = arraySum(allRelevant.map(v => v.count))

          return overallPoints / overallCount;
        })();

        avgs.set(k_allTeamsAvgTeamID, overallAvg)

        return avgs;
      }

      type AvgAccum = {count: number, sum: number}
      function freshAvgAccum() : AvgAccum {
        return {count: 0, sum: 0}
      }
    })

    onMounted(async () => {
      menu.value = await getTeamChooserMenu(axiosInstance, "team-assignments-divisions-only")
      menuSelectionManager.value = BasicTeamChooserSelectionManager({mut_selection: menuSelection, menu: menu.value})
      maybeConfigureMenuFromQueryParams(menu.value);
      ready.value = true;
    })

    watch(() => menuSelection.value, () => {
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID) {
        resolvedSelection.value = null
        return;
      }

      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await updateRouteQuery({seasonUID, competitionUID, divID});
        await doInitFromCurrentMenuSelection()
      })
    }, {deep:true})

    const router = useRouter();

    const updateRouteQuery = async (params: {seasonUID: Guid, competitionUID: Guid, divID: Guid}) : Promise<void> => {
      await router.replace({
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          ...{
            [queryParams.seasonUID]: params.seasonUID,
            [queryParams.competitionUID]: params.competitionUID,
            [queryParams.divID]: params.divID,
          }
        }
      })
    }

    const maybeConfigureMenuFromQueryParams = (menu: TeamChooserMenu) => {
      const seasonUID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.seasonUID);
      const competitionUID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.competitionUID);
      const divID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.divID);

      const v = longestValidMenuPath(menu, {seasonUID, competitionUID, divID, teamIDs: null});

      if (v.seasonUID && v.competitionUID && v.divID) {
        menuSelection.value = v;
      }
    }

    const busyByPlayerID = ref(new SetEx<Guid>())
    const busyByTeamID = ref(new SetEx<Guid>())
    const expandedByTeamID = ref(new SetEx<Guid>())

    /**
     * Sort of a kludge -- we could be more targeted and know which team we're looking for;
     * but this is easy to do when we just want to deselect players and guarantee we did the right thing.
     */
    const setPlayerSelections = ({players, setTo} : {players: Guid[] | PlayerForTeamAssignmentViewEx[], setTo: "selected" | "not-selected"}) => {
      if (!resolvedSelection.value) {
        return;
      }

      const playerIDs = players.map(v => {
        return typeof v === "string" ? v : v.apiData.child.childID
      })

      if (setTo === "selected") {
        resolvedSelection.value.selectedPlayerIDsByTeamIDish.forEach(selectedPlayerIDs => {
          selectedPlayerIDs.addMany(playerIDs)
        })
      }
      else if (setTo === "not-selected") {
        resolvedSelection.value.selectedPlayerIDsByTeamIDish.forEach(selectedPlayerIDs => {
          selectedPlayerIDs.deleteMany(playerIDs)
        })
      }
      else {
        exhaustiveCaseGuard(setTo)
      }
    }

    const doInitFromCurrentMenuSelection = async (ax?: AxiosInstance) : Promise<void> => {
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID) {
        return;
      }

      const data = await fullRefresh({
        seasonUID,
        competitionUID,
        divID,
        currentLoanSearch: resolvedSelection.value?.loans.searchResults ?? null,
        includeNeighboringDivs: includeNeighboringDivs.value,
        ax
      });

      if (!__teamSort.value) {
        __teamSort.value = getDefaultTeamSortMapping(data.teams)
      }

      resolvedSelection.value = {
        ...data,
        mode: {
          type: "multi-assign",
        },
        version: (resolvedSelection.value?.version ?? 0) + 1
      }
    }

    const autoAssignModalController = reactive((() => {
      // load from local storage? For now these will be persitent across modal open/close but not per outer component mount
      const opts = reactive<SuggestedTeamAssignmentsOptions>({
        useWaitlist: false,
        useRecentRating: false,
        assignSiblings: false,
        assignCoachesKids: false,
        useAgeBalancing: false,
        numPerTeam: 10,
        unratedRating: 1,
      })

      return DefaultModalController<void>({
        title: () => (
          <>
            <div>Auto assignment options</div>
            <div class="border-b border-gray-200 mb-2"/>
          </>
        ),
        content: () => {
          return (
            <div style="--fk-margin-outer: none;">
              <div class="my-2 text-sm">
                The auto-assign tool is intended as a "first pass" utility to distribute players to teams according to their historical ratings and the below options. Proposed assignments are displayed in the team assignment tool and can be adjusted prior to saving.
              </div>
              <FormKit type="form" actions={false} onSubmit={() => {
                autoAssignModalController.close()
                doGetSuggestedTeamAssignments(opts)
              }}>
                <div class="my-2">
                  <FormKit type="checkbox" v-model={opts.useWaitlist} label="Include Waitlisted Players"/>
                </div>
                <div class="my-2">
                  <FormKit type="checkbox" v-model={opts.useRecentRating} label="Use most recent rating instead of lifetime rating"/>
                </div>
                <div class="my-2">
                  <FormKit type="checkbox" v-model={opts.assignSiblings} label="Always assign siblings together"/>
                </div>
                <div class="my-2">
                  <FormKit type="checkbox" v-model={opts.assignCoachesKids} label="Always place coach's players on coach's team"/>
                </div>
                <div class="my-2">
                  <FormKit type="checkbox" v-model={opts.useAgeBalancing} label="Balance two-year divisions evenly"/>
                </div>
                <div class="my-2">
                  <FormKit type="number" step="1" min="0" v-model={opts.numPerTeam} label="Desired # of players per team"/>
                </div>
                <div class="my-2">
                  <FormKit type="number" step=".1" min="0" v-model={opts.unratedRating} label="Treat unrated players as having this rating"/>
                </div>
                <div class="flex gap-2 mt-2">
                  <Btn2 class="p-2" type="submit">OK</Btn2>
                  <Btn2 class="p-2" enabledClasses={btn2_redEnabledClasses} onClick={() => autoAssignModalController.close()}>Cancel</Btn2>
                </div>
              </FormKit>
            </div>
          )
        }
      })
    })());

    const doGetSuggestedTeamAssignments = async (opts: SuggestedTeamAssignmentsOptions) : Promise<void> => {
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID || !resolvedSelection.value) {
        return;
      }

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const data = await fullRefresh({
          seasonUID,
          competitionUID,
          divID,
          currentLoanSearch: resolvedSelection.value?.loans.searchResults ?? null,
          includeNeighboringDivs: includeNeighboringDivs.value,
        });

        resolvedSelection.value  = {
          ...data,
          mode: {
            type: "multi-assign",
          },
          version: (resolvedSelection.value?.version ?? 0) + 1
        }

        const suggestedAssignments = await getSuggestedTeamAssignments(axiosInstance, {
          seasonUID,
          competitionUID,
          divID,
          ...opts
        })

        // todo: assert that the players in `suggestedAssignments.leftovers` are fully disjoint from those in `suggestedAssignments.teams`
        // todo: most of the array.find(...) should probably become arrayFindOrFail, but we're still reasoning about what cases we expect to hit
        // and what it means if we don't find particular values.
        for (const team of suggestedAssignments.teams) {
          const targetTeam = resolvedSelection.value.teams.find(v => v.team.teamID === team.id);

          if (!targetTeam) {
            // shouldn't happen? when would this happen?
            continue;
          }

          for (const player of team.players) {
            const playersCurrentTeam = maybeFindCurrentlyAssignedPlayerTeamObj(player, resolvedSelection.value.teams, resolvedSelection.value.assignments.byTeam)

            if (!playersCurrentTeam) {
              const localPlayerObj = resolvedSelection.value.assignments.unassignedPool.find(v => v.type === "unassigned" && v.apiData.child.childID === player.childID);
              if (!localPlayerObj) {
                // shouldn't happen? when would this happen?
                continue;
              }
              else {
                locallyMovePlayers({originTeam: null, targetTeam, players: [localPlayerObj]});
              }
            }
            else if (playersCurrentTeam.team.teamID === targetTeam.team.teamID) {
              // no movement required
              continue;
            }
            else {
              const localPlayerObj = resolvedSelection.value.assignments.byTeam[playersCurrentTeam.team.teamID].find(v => v.apiData.child.childID === player.childID);
              if (!localPlayerObj) {
                // shouldn't happen? when would this happen?
                continue;
              }
              else {
                locallyMovePlayers({originTeam: playersCurrentTeam, targetTeam, players: [localPlayerObj]});
              }
            }
          }
        }

        return;

        function maybeFindCurrentlyAssignedPlayerTeamObj(
          player: {childID: Guid},
          teams: TeamForTeamAssignmentsView[],
          assignedPlayers: {[teamID: Guid]: PlayerForTeamAssignmentViewEx[]}
        ) : TeamForTeamAssignmentsView | null {
          const originTeamID = Object
            .entries(assignedPlayers)
            .find(([_, v]) => {
              return v.find(assigned => assigned.apiData.child.childID === player.childID)
            })?.[0] ?? null;

          return originTeamID
            ? arrayFindOrFail(teams, v => v.team.teamID === originTeamID)
            : null;
        }
      })
    }

    const doCommitAllTentativeChanges = async () : Promise<void> => {
      if (isInLoadedFromSnapshotMode.value) {
        await commitChanges_snapshotMode()
      }
      else {
        await commitChanges()
      }

      async function commitChanges() : Promise<void> {
        try {
          assertTruthy(!isInLoadedFromSnapshotMode.value)

          const {seasonUID, competitionUID, divID} = menuSelection.value
          if (!seasonUID || !competitionUID || !divID) {
            return; // shouldn't happen
          }

          await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
            const sel = requireNonNull(resolvedSelection.value)

            const currentVersion = sel.version
            const currentMode = sel.mode

            const {assignments, loans} = getChangesForApiSubmit(sel);
            await bulkCreateUpdateDeleteTeamAssignments(axiosInstance, {
              assignments,
              loans
            })

            const data = await fullRefresh({seasonUID, competitionUID, divID, currentLoanSearch: sel.loans.searchResults, includeNeighboringDivs: includeNeighboringDivs.value})
            resolvedSelection.value = {
              ...data,
              mode: currentMode,
              version: currentVersion + 1
            }
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      async function commitChanges_snapshotMode() : Promise<void> {
        try {
          assertTruthy(isInLoadedFromSnapshotMode.value)

          const {seasonUID, competitionUID, divID} = menuSelection.value
          if (!seasonUID || !competitionUID || !divID) {
            return; // shouldn't happen
          }

          await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
            const sel = requireNonNull(resolvedSelection.value)

            const currentVersion = sel.version
            const currentMode = sel.mode

            const {assignments, loans} = getChangesForApiSubmit(sel);
            // sanity check that all we're doing is "adding" things;
            // the backend will delete all existing assignments/loans prior to doing the adds
            assertTruthy(assignments.delete.length === 0)
            assertTruthy(loans.delete.length === 0)
            assertTruthy(loans.update.length === 0)

            await bulkCreateUpdateDeleteTeamAssignments(axiosInstance, {
              restoringSnapshotFor : {
                  seasonUID: seasonUID,
                  competitionUID: competitionUID,
                  divID: divID,
                  affectedTeamIDs: [...new Set([
                    ...checkedObjectEntries(sel.assignments.byTeam).map(([teamID]) => teamID satisfies Guid),
                    ...checkedObjectEntries(sel.loans.byTeam).map(([teamID]) => teamID satisfies Guid),
                  ])]
              },
              assignments,
              loans
            })

            const data = await fullRefresh({seasonUID, competitionUID, divID, currentLoanSearch: sel.loans.searchResults, includeNeighboringDivs: includeNeighboringDivs.value})
            resolvedSelection.value = {
              ...data,
              mode: currentMode,
              version: currentVersion + 1
            }
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }
    }


    // a mapping from teamIDs to their sort ordering
    const __teamSort = ref<{[teamID: Guid]: number | undefined} | null>(null)
    // a sorter that considers the teamID->ordering mapping
    const teamSort = sortBy<TeamForTeamAssignmentsView>(_ => __teamSort.value?.[_.team.teamID] ?? -1)

    /**
     * Update team sort to move the team at `oldIdx` to `newIdx`
     */
    const doMoveOneTeam = (fromTo: {oldIdx: number, newIdx: number}) : void => {
      const reordering = new ListIndexReorderer(fromTo)
      const freshSort : {[teamID: Guid]: number} = {}
      sortedTeams.value.forEach((e,i) => {
        freshSort[e.team.teamID] = reordering.getNewOrdering(i)
      })
      __teamSort.value = freshSort
    }

    /**
     * "default" team sort is by team name, ascending. Users can customize from there (via drag and drop, maybe other means).
     * TODO: load from local storage (key=user/comp/season/div, value=guid[])
     */
    const getDefaultTeamSortMapping = (teams: readonly TeamForTeamAssignmentsView[]) => {
      const result : {[teamID: Guid]: number | undefined} = {};

      [...teams]
        .sort(sortBy(getTeamName))
        .forEach((e,i) => {
          result[e.team.teamID] = i
        })

      return result;
    }

    const sortedTeams = computed(() => [...resolvedSelection.value?.teams ?? []].sort(teamSort) ?? [])

    const selectRegistrationQuestionsModalController = ref<null | {open: () => void, close: () => void}>(null)

    const locallyMovePlayersModalController = reactive((() => {
      const k_moveToUnassigned = "move-to-unassigned";
      const selectedTeamID = ref<Guid | "" | typeof k_moveToUnassigned>("")

      const onOpenCB = () => {
        selectedTeamID.value = ""
      }

      return DefaultModalController<{action: "assign" | "loan" | "move", originTeam: null | TeamForTeamAssignmentsView, players: PlayerForTeamAssignmentViewEx[]}>({
        content: args => {
          if (!args || !resolvedSelection.value || args.players.length === 0) {
            return null;
          }

          const {originTeam, players, action} = args;

          // misc. sanity checks -- if there's no origin team, we expect that we are an "assign" or "loan" action.
          // If we are an "assign" or "loan" action, we expect that the provided players are all assignments or all loans.
          if (process.env.NODE_ENV === "development") {
            switch (action) {
              case "assign":
                // fallthrough
              case "loan": {
                assertTruthy(originTeam === null, "originTeam should be null in the 'assign'| 'loan' case")
                const partitioned = gatherByKey_manyPerKey(players, v => v.clientData.type)
                assertTruthy(partitioned.size === 1)
                const expectedPartitionKey = action === "assign" ? "assignment" : "loan";
                assertTruthy([...partitioned.keys()][0] === expectedPartitionKey)
                break;
              }
              case "move": {
                assertNonNull(originTeam, "originTeam is required in the 'move' case")
                break;
              }
            }
          }

          const opts : UiOption[] = []

          if (originTeam === null) {
            for (const opt of resolvedSelection.value.teams) {
              opts.push({
                label: getTeamName(opt),
                value: opt.team.teamID
              })
            }
          }
          else {
            opts.push({label: "No team (set as 'unassigned')", value: k_moveToUnassigned})
            for (const opt of resolvedSelection.value.teams) {
              if (opt.team.teamID === originTeam.team.teamID) {
                continue; // don't offer to move "from A to A"
              }
              opts.push({
                label: getTeamName(opt),
                value: opt.team.teamID
              })
            }
          }

          // gross render function side effects ...
          // this should stabilize after 1 round
          if (opts.length > 0 && !selectedTeamID.value) {
            selectedTeamID.value = opts[0].value
          }

          if (opts.length === 0) {
            opts.push({label: "No options available", value: "", attrs: {disabled: true}})
          }

          return (
            <div style="max-height: 80vh; overflow-y: auto; padding: 0 2px; --fk-margin-outer: none;">
              <div>
                {
                  originTeam === null
                    ? `${action === "assign" ? "Assign" : "Loan"} ${players.length} ${players.length === 1 ? "player" : "players"} to ...`
                    : `Move ${players.length} ${players.length === 1 ? "player" : "players"} from ${getTeamName(originTeam)} to ...`
                }
              </div>
              <div class="border-b border-gray-200 mb-2"></div>
              <FormKit type="form" actions={false} onSubmit={()=>{}}>
                <FormKit type="select" options={opts} v-model={selectedTeamID.value}/>
                <div class="my-2">
                  <div>{players.length === 1 ? "Player:" : "Players:"}</div>
                  {
                    [...players].sort(sortByMany(
                      (l,r) => accentAwareCaseInsensitiveCompare(l.apiData.child.playerLastName, r.apiData.child.playerLastName),
                      (l,r) => accentAwareCaseInsensitiveCompare(l.apiData.child.playerFirstName, r.apiData.child.playerFirstName)
                    )).map(player => <div class="ml-3 text-sm">{player.apiData.child.playerFirstName} {player.apiData.child.playerLastName}</div>)
                  }
                </div>
                <div class="flex gap-2">
                  <Btn2 class="p-2" disabled={!selectedTeamID.value} onClick={() => {
                    if (selectedTeamID.value === "" || !resolvedSelection.value) {
                      return; // shouldn't happen
                    }
                    else if (selectedTeamID.value === k_moveToUnassigned) {
                      locallyMovePlayers({originTeam, targetTeam: null, players})
                      locallyMovePlayersModalController.close()
                      setPlayerSelections({players: args.players, setTo: "not-selected"})
                    }
                    else {
                      const targetTeam = arrayFindOrFail(resolvedSelection.value.teams, v => v.team.teamID === selectedTeamID.value);
                      locallyMovePlayers({originTeam, targetTeam, players})
                      locallyMovePlayersModalController.close()
                      setPlayerSelections({players: args.players, setTo: "not-selected"})
                    }
                  }}>OK</Btn2>
                  <Btn2 class="p-2" enabledClasses={btn2_redEnabledClasses} onClick={() => locallyMovePlayersModalController.close()}>Cancel</Btn2>
                </div>
              </FormKit>
            </div>
          )
        }
      }, {onOpenCB})
    })())

    const cloneFromOtherTeamModalController = (() => {
      const busy = ref(false)
      const onCloseCB = (close: () => void) => {
        if (busy.value) {
          return;
        }
        else {
          close();
          currentModalTargetTeamID.value = null
        }
      }

      return DefaultModalController_r<{
        destSeasonUID: Guid,
        destTeamID: Guid
        // dest comp/div is technically inferrable from teamID,
        // but its easier to just supply it outright.
        destCompetitionUID: Guid,
        destDivID: Guid,
        cloningInto_seasonName: string,
        cloningInto_teamName: string,
      }>({
        title: () => <>
          <div>Clone a Team from Another Competition</div>
          <div class="border-b my-2"/>
        </>,
        content: args => {
          if (!args) {
            return null
          }
          return <>
            <CloneTeamModal
              class="mt-3"
              divID={args.destDivID}
              parentFocusedCompetitionUidOnMount={args.destCompetitionUID}
              parentFocusedSeasonUidOnMount={args.destSeasonUID}
              cloningInto_seasonName={args.cloningInto_seasonName}
              cloningInto_teamName={args.cloningInto_teamName}
              onSubmit={async formData => {
                if (hasPendingChanges.value) {
                  // We shouldn't ever hit this, the page should not offer the ability
                  // to open the modal if `hasPendingChanges.value` is truthy.
                  alert("Cannot clone team assignments when there are also pending changes.")
                  return;
                }
                else {
                  try {
                    try {
                      busy.value = true
                      await cloneTeamAssignments(axiosAuthBackgroundInstance, {
                        sourceSeasonUID: formData.sourceSeasonUID,
                        destSeasonUID: args.destSeasonUID,
                        sourceTeamID: formData.sourceTeamID,
                        destTeamID: args.destTeamID,
                        includeCoachAssignments: formData.includeCoachAssignments,
                        includeVolunteerAssignments: formData.includeVolunteerAssignments,
                        deleteAllExistingDestPlayerAssignments: formData.deleteAllExistingDestPlayerAssignments,
                        deleteAllExistingDestCoachAssignments: formData.deleteAllExistingDestCoachAssignments,
                        deleteAllExistingDestVolunteerAssignments: formData.deleteAllExistingDestVolunteerAssignments,
                      })
                      await doInitFromCurrentMenuSelection(axiosAuthBackgroundInstance)
                    }
                    finally {
                      busy.value = false
                    }
                    cloneFromOtherTeamModalController.close()
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err)
                  }
                }
              }}
              onCancel={() => cloneFromOtherTeamModalController.close()}
            />
            {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
          </>
        }
      }, {onCloseCB})
    })()

    const tabDefs : TabDef[] = [
      {
        label: "Unassigned Players",
        render: () => {
          if (!resolvedSelection.value) {
            return null
          }

          return (
            <UnassignedPlayersElement
              key={resolvedSelection.value.version}
              unassignedPlayers_unfiltered={resolvedSelection.value.assignments.unassignedPool}
              unassignedPlayers={filteredUnassignedPool.value}
              selectedQuestions={resolvedSelection.value.selectedQuestions}
              registrationQuestions={resolvedSelection.value.registrationQuestions}
              registrationQuestionAnswers={resolvedSelection.value.registrationQuestionAnswers}
              showTentativeAssignmentInfo={resolvedSelection.value.mode.type === "multi-assign"}
              selectedPlayerIDs={mapGetOrFail(resolvedSelection.value.selectedPlayerIDsByTeamIDish, k_selectedUnassignedAssignments)}
              filter={unassignedPlayersFilter.value}
              hasSomeNonEmptyFilters={hasSomeNonEmptyUnassignedPlayersFilters.value}
              onClearFilters={clearUnassignedPlayersFilters}
              onDidDropPlayers={({originTeam, players}) => {
                assertNonNull(resolvedSelection.value)
                setPlayerSelections({players, setTo: "not-selected"})
                if (resolvedSelection.value.mode.type === "multi-assign") {
                  locallyMovePlayers({originTeam, targetTeam: null, players})
                }
                else {
                  exhaustiveCaseGuard(resolvedSelection.value.mode.type)
                }
              }}
              onAssignSelectedPlayers={({players}) => {
                if (resolvedSelection.value?.mode.type === "multi-assign") {
                  locallyMovePlayersModalController.open({action: "assign", originTeam: null, players})
                }
                else {
                  // nothing to do
                }
              }}
              onUndeletePlayers={({players}) => {
                setPlayerSelections({players, setTo: "not-selected"})
                restoreLocallyMovedPlayers({originTeam: null, players})
              }}
              busyByPlayerID={busyByPlayerID.value}
            />
          )
        },
      },
      {
        label: "Player Loans",
        ["data-test"]: "playerLoansTab",
        render: () => {
          if (!resolvedSelection.value) {
            return null;
          }

          return (
            <PlayerLoanElement
              key="playerLoanElement"
              selectedPlayerIDs={mapGetOrFail(resolvedSelection.value.selectedPlayerIDsByTeamIDish, k_selectedUnassignedLoans)}
              searchText={playerLoanSearchText}
              lookupResults={resolvedSelection.value.loans.searchResults.currentRequest}
              selectedQuestions={resolvedSelection.value.selectedQuestions}
              registrationQuestions={resolvedSelection.value.registrationQuestions}
              // TODO: this isn't right, we need to pull them off the "current player loan search response",
              // which means answers should probably be subobjects of whatever player objects we get, in both the loan and non-loan cases.
              registrationAnswers={resolvedSelection.value.registrationQuestionAnswers}
              seasonName={seasonName.value}
              onLoanPlayers={args => locallyMovePlayersModalController.open({action: "loan", originTeam: null, players: args.players})}
            />
          )
        }
      },
    ];

    const snapshotLoadButtonLabel = ref<HTMLElement | null>(null)
    const snapshotFileElement = ref<HTMLInputElement | null>(null)

    const confirmSaveModal = reactive((() => {
      return DefaultModalController<void>({
        title: () => <>
          <div>Confirm Save</div>
          <div class="border-b my-2"></div>
        </>,
        content: () => <div>
          <div class="my-2 flex gap-2">
            <Btn2 class="px-2 py-1" data-test="save" onClick={() => {
              void doCommitAllTentativeChanges()
              confirmSaveModal.close()
            }}>Save</Btn2>
            <Btn2 class={`px-2 py-1 ${btn2_redEnabledClasses}`} data-test="cancel" onClick={() => confirmSaveModal.close()}>Cancel</Btn2>
          </div>
        </div>
      })
    })());

    const doSaveCurrentSnapshot = async () : Promise<void> => {
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID) {
        return
      }
      if (!resolvedSelection.value) {
        return
      }
      if (hasPendingChanges.value) {
        return;
      }

      const seasonName = requireNonNull(getTeamChooserMenuNode(menu.value, "season", {seasonUID})).name
      const competition = requireNonNull(getTeamChooserMenuNode(menu.value, "competition", {seasonUID, competitionUID})).name
      const division = requireNonNull(getTeamChooserMenuNode(menu.value, "division", {seasonUID, competitionUID, divID})).name

      const allKnownTeams = resolvedSelection.value.teams

      const currentAssignments = resolvedSelection.value.assignments.byTeam
      const currentLoans = resolvedSelection.value.loans.byTeam

      const {snapshot, recommendedFileName} = createSnapshot({
        seasonUID,
        seasonName,
        competitionUID,
        competition,
        divID,
        division,
        allKnownTeams,
        currentAssignments,
        currentLoans
      })

      const stringified = JSON.stringify(snapshot, null, 2) + "\n" // tidy unix trailing newline
      await downloadFromObjectURL(stringified, recommendedFileName)
    }

    /**
     * loads a snapshot from a file, and configures the view to reflect that snapshot.
     * A user must subsequently "save changes" in order to push the snapshot to the backend and persist it.
     */
    const doRestoreSnapshot = async (file: File) : Promise<void> => {
      const {seasonUID, competitionUID, divID} = menuSelection.value

      if (!seasonUID || !competitionUID || !divID) {
        return
      }

      if (!resolvedSelection.value) {
        return
      }

      const obj = maybeParseJSON(await file.text())
      if (Value.Check(snapshotSchema(), obj)) {
        const snapshot = obj

        const loaded = await loadSnapshot(axiosInstance, {
          snapshot,
          seasonUID,
          competition: await Client.getCompetitionByUidOrFail(competitionUID),
          divID,
          unassignedPool: resolvedSelection.value.assignments.unassignedPool,
          currentAssignments: resolvedSelection.value.assignments.byTeam,
          currentLoans: resolvedSelection.value.loans.byTeam
        });

        resolvedSelection.value.snapshotConfig = {
          warnings: loaded.warnings,
          savedAssignments: resolvedSelection.value.assignments,
          savedLoans: resolvedSelection.value.loans
        }

        resolvedSelection.value.assignments = {
          unassignedPool: loaded.freshUnassignedPool,
          byTeam: loaded.freshAssignments
        }
        resolvedSelection.value.loans = {
          searchResults: resolvedSelection.value.loans.searchResults, // no need to rebuild this,
          byTeam: loaded.freshLoans,
        }
      }
      else {
        if (process.env.NODE_ENV === "development") {
          debugger;
        }
        alert("Supplied snapshot wasn't a valid snapshot file.");
      }
    }

    const windowSize = useWindowSize()
    const bigMode = computed(() => windowSize.width >= TailwindBreakpoint.xl);
    const fkFormRootRef = FK_nodeRef()

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <div ref={teamChooserButtonContainer.rootRef} style="max-width:2048px;" data-test="R_TeamAssignments">
          <h2>Team Assignments -- Players</h2>
          <AutoModal controller={autoAssignModalController}/>
          <AutoModal controller={locallyMovePlayersModalController} data-test="locallyMovePlayersModal"/>
          <AutoModal data-test="confirmSaveModal" controller={confirmSaveModal}/>
          <AutoModal class="max-w-lg" data-test="cloneFromOtherTeamModal" controller={cloneFromOtherTeamModalController}/>

          <TeamSelectionButtons
            teamSelectMode="single"
            menu={menu.value}
            selectionManager={menuSelectionManager.value}
            levels={{
              season: true,
              competition: true,
              division: true
            }}
            mut_openAbsolutePosPanes={teamChooserButtonContainer.openAbsolutePosPanes}
          >{{
            competition: () => {
              const comp = menuCompetition.value
              if (!comp || comp === "pending") {
                return null
              }

              const compSupportsNeighborDivs = comp.neighborDivs
              const disabled = !compSupportsNeighborDivs || hasPendingChanges.value

              if (!compSupportsNeighborDivs) {
                return null;
              }

              return (
                <div style="--fk-margin-outer:none;" class="inline-flex items-center gap-1 relative text-sm h-8">
                  <input type="checkbox"
                    id="includeNeighboringDivs"
                    data-test="includeNeighboringDivs"
                    disabled={disabled}
                    value={comp.neighborDivs ? includeNeighboringDivs.value : false}
                    onInput={async () => {
                      if (disabled) {
                        // shouldn't get here by virtue of disabled attr, but be paranoid
                        return;
                      }
                      includeNeighboringDivs.value = !includeNeighboringDivs.value
                      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                        await doInitFromCurrentMenuSelection()
                      })
                    }}
                  />
                  <label for="includeNeighboringDivs">Include neighboring divisions</label>
                  {(() => {
                    if (!compSupportsNeighborDivs) {
                      assertTruthy(disabled)
                      return obscuringOverlayWithTooltip("Selected program does not have this option enabled")
                    }
                    else if (!hasPendingChanges.value) {
                      assertTruthy(!disabled)
                      return null;
                    }
                    else if (hasPendingChanges.value) {
                      assertTruthy(disabled)
                      return obscuringOverlayWithTooltip("Save or discard any pending changes prior to reloading with neighboring divs")
                    }
                    else {
                      unreachable()
                    }
                    function obscuringOverlayWithTooltip(msg: string) {
                      return <div class="absolute top-0 left-0 w-full h-full bg-white opacity-75" v-tooltip={{content: msg}}></div>
                    }
                  })()}
                </div>
              )}
          }}</TeamSelectionButtons>
          {
            resolvedSelection.value
              ? (
                <div key={resolvedSelection.value.mode.type}>
                  <div class="shadow-md rounded-md p-2 bg-white my-6">
                    <div class="text-sm my-1">Roster additions, removals, and loans are pending until saved.</div>
                    <div class="flex gap-2 my-2">
                      <Btn2 class="px-2 py-1"
                        data-test="saveCurrentSnapshot"
                        // TODO: don't show this tooltip, if a tooltip on a descendant is visible
                        v-tooltip={{content: hasPendingChanges.value ? "Save or discard pending changes prior to saving a snapshot." : ""}}
                        disabled={hasPendingChanges.value} onClick={() => doSaveCurrentSnapshot()}>
                        <div class="flex gap-2 items-center">
                          Save current snapshot
                          <FontAwesomeIcon icon={faCircleQuestion} v-tooltip={{content:"Download all current assignments and loans to a file on your computer, to be reloaded at a future time."}}/>
                        </div>
                      </Btn2>
                      <label ref={snapshotLoadButtonLabel}>
                        <input class="hidden" type="file" accept=".json" ref={snapshotFileElement} onChange={async () => {
                          if (!snapshotFileElement.value) {
                            return
                          }

                          const file = snapshotFileElement.value.files?.item(0)
                          if (!file) {
                            return
                          }

                          snapshotFileElement.value.value = "" // clear it out so clicking on same file again would register a change event

                          await doRestoreSnapshot(file)
                        }}/>
                        <Btn2 class="px-2 py-1"
                          data-test="restoreExistingSnapshot"
                          onClick={() => snapshotLoadButtonLabel.value?.click()}>
                            <div class="flex gap-2 items-center">
                              Load existing snapshot...
                              <FontAwesomeIcon icon={faCircleQuestion} v-tooltip={{content:"Discard all current assignments and loans, and reload from a snapshot. Resulting changes are tentative until saved."}}/>
                            </div>
                        </Btn2>
                      </label>
                    </div>
                    <div class="flex gap-2 items-start">
                      <div>
                        <div class="relative flex gap-2 items-start">
                          {
                            resolvedSelection.value.mode.type === "multi-assign"
                              ? <Btn2 class="px-2 py-1"
                                data-test="savePendingChanges" disabled={!hasPendingChanges.value}
                                onClick={() => fkFormRootRef.value?.node?.submit()}>Save pending changes</Btn2>
                              : null
                          }
                          {
                            resolvedSelection.value.mode.type === "multi-assign"
                              ? <Btn2 class="px-2 py-1" disabled={!hasPendingChanges.value} onClick={() => GlobalInteractionBlockingRequestsInFlight.withSpinner(doInitFromCurrentMenuSelection)}>Discard all pending changes</Btn2>
                              : null
                          }
                          {
                            resolvedSelection.value.mode.type === "multi-assign"
                              ? <Btn2 class="px-2 py-1" onClick={() => autoAssignModalController.open()}>Auto assign...</Btn2>
                              : null
                          }
                        </div>
                      </div>
                      <Btn2 class="px-2 py-1" onClick={() => selectRegistrationQuestionsModalController.value?.open()}>
                        Show/hide registration data...
                      </Btn2>
                      <SelectRegistrationQuestionsModal
                        customRegistrationQuestions={resolvedSelection.value.registrationQuestions}
                        selectedQuestions={resolvedSelection.value.selectedQuestions}
                        out_controller={selectRegistrationQuestionsModalController}
                      />
                    </div>
                    <div>
                      <FormKitMessages node={fkFormRootRef.value?.node}/>
                    </div>
                    {isInLoadedFromSnapshotMode.value
                      ? (
                        <div class="text-sm border rounded-md my-2 p-2 max-h-32 overflow-y-auto">
                          Current view is loaded from snapshot.
                          <div>
                            {resolvedSelection.value.snapshotConfig?.warnings.length
                              ? <div>
                                <div>There were warnings while loading this snapshot:</div>
                                <ul class="list-disc pl-6 text-red-700">
                                  {resolvedSelection.value.snapshotConfig.warnings.map(msg => <li>{msg}</li>)}
                                </ul>
                              </div>
                              : null
                            }
                          </div>
                        </div>
                      )
                      : null
                    }
                  </div>
                  <div
                    class="my-6"
                    style={`display:grid; grid-gap: 1em; grid-template-columns: ${bigMode.value ? `repeat( auto-fit, minmax(768px, 1fr) )` : `100%`}; align-items: start;`}
                  >
                    <Tabs tabDefs={tabDefs}/>
                    {
                      resolvedSelection.value.teams.length === 0
                        ? <div class="m-6 flex items-center justify-center">No available teams</div>
                        : null
                    }
                  </div>

                  {
                    resolvedSelection.value.teams.length > 1
                      ? (
                        <div>
                          <div class="flex gap-2 mt-4">
                            <div style="flex-grow: 1" class="relative">
                              <div class="absolute top-0 h-2/4 w-full border-b border-gray-300"/>
                            </div>
                            <div>Teams</div>
                            <div style="flex-grow: 20" class="relative">
                              <div class="absolute top-0 h-2/4 w-full border-b border-gray-300"/>
                            </div>
                          </div>
                          <div class="mb-2 text-sm">
                            <div class="ml-2">Lifetime avg rating, all teams: {teamAverageRatings.value.lifetimeByTeamID.getDisplayValue(k_allTeamsAvgTeamID)}</div>
                            <div class="ml-2">Current avg rating, all teams: {teamAverageRatings.value.mostRecentSeasonByTeamID.getDisplayValue(k_allTeamsAvgTeamID)}</div>
                          </div>
                          <div class="flex gap-2">
                            <Btn2 class="px-2 py-1" onClick={() => expandedByTeamID.value.addMany(resolvedSelection.value?.teams.map(v => v.team.teamID) ?? [])}>Expand all</Btn2>
                            <Btn2 class="px-2 py-1" onClick={() => expandedByTeamID.value.clear()}>Collapse all</Btn2>
                          </div>
                        </div>
                      )
                      : null
                  }

                  <FormKit ref={fkFormRootRef} type="form" actions={false} onSubmit={() => confirmSaveModal.open()}>
                  <div
                    // the drop target for "whole teams" is this entire div,
                    // because it's nice to be able drop them in-between the <TeamElement>
                    // elements (in the margins between elements)
                    v-ilDropTarget={
                      {
                        onEnter: () => {
                          return !!globalTeamAssignmentsDragData.teamBeingDragged
                        },
                        onDragOver: "sameAsOnEnter",
                        onDrop: () => {
                          globalTeamAssignmentsDragData.teamBeingDragged = null
                        }
                      } satisfies ilDropTarget
                    }
                  >
                    {
                      sortedTeams.value.map((team, teamsListing_currentIdx, teamsListing) => {
                        const teamID = team.team.teamID;
                        assertNonNull(resolvedSelection.value, "we retain the outer flow type");

                        const dragConfig : ilDraggable = {
                          dragHandleJsxFunc: () => <div class="p-2 rounded-md bg-blue-500 text-white shadow-lg">{getTeamName(team)}</div>,
                          onDragStart: dataTransfer => {
                            assertNonNull(__teamSort.value)
                            dataTransfer.effectAllowed = "move"
                            dataTransfer.dropEffect = "move"
                            globalTeamAssignmentsDragData.teamBeingDragged = {
                              teamID: team.team.teamID,
                              initialSort: copyViaJsonRoundTrip(__teamSort.value),
                              lastSwappedWithTeamID: null,
                            }
                            return true;
                          },
                          onLeaveOrEnd: () => {
                            if (!globalTeamAssignmentsDragData.teamBeingDragged) {
                              // drop already fired
                              return;
                            }

                            // restore sort prior to drag start
                            __teamSort.value = globalTeamAssignmentsDragData.teamBeingDragged.initialSort
                            globalTeamAssignmentsDragData.teamBeingDragged = null
                          },
                        };

                        // TODO: this is more of just "onDragOver" config, which, we can just use <div onDragOver={...}> right?
                        // we reorder the teams list when teams are dragged onto other teams, but the drop logic (i.e. "commit the reordering")
                        // shouldn't live here
                        const dropConfig : ilDropTarget = {
                          onEnter: () => {
                            return false;
                          },
                          onDragOver: () => {
                            if (!globalTeamAssignmentsDragData.teamBeingDragged) {
                              return false;
                            }

                            // this helps prevent jank where swapping elements of differing heights will endlessly cycle between the two
                            if (globalTeamAssignmentsDragData.teamBeingDragged.lastSwappedWithTeamID === teamID) {
                              return false;
                            }

                            globalTeamAssignmentsDragData.teamBeingDragged.lastSwappedWithTeamID = teamID

                            if (globalTeamAssignmentsDragData.teamBeingDragged.teamID === teamID) {
                              return false;
                            }

                            if (!__teamSort.value) {
                              return false; // shouldn't happen
                            }

                            const teamBeingDragged = globalTeamAssignmentsDragData.teamBeingDragged

                            // super weird behavior -- seems like newIdx should be the same as the the captured index from the `map` call we're in, but it's not.
                            // It's as if they can become out of sync, there is some kind of desync between the DOM and the directive and the array we're mapping over.
                            const oldIdx = arrayFindIndexOrFail(sortedTeams.value, v => v.team.teamID === teamBeingDragged.teamID)
                            const newIdx = arrayFindIndexOrFail(sortedTeams.value, v => v.team.teamID === team.team.teamID)
                            doMoveOneTeam({oldIdx, newIdx})

                            return true;
                          }
                        }

                        return (
                          <Fragment key={teamID}>
                            <TeamElement
                              key={teamID}
                              wholeTeamDragConfig={dragConfig}
                              v-ilDropTarget={dropConfig}
                              class={[
                                "my-6",
                                // if there is a team being dragged, and the team being dragged is NOT THIS team, make THIS team a little bit translucent
                                globalTeamAssignmentsDragData.teamBeingDragged && globalTeamAssignmentsDragData.teamBeingDragged.teamID !== teamID  ? "opacity-50" : ""
                              ]}
                              team={team}
                              players={[
                                ...resolvedSelection.value.assignments.byTeam[teamID],
                                ...resolvedSelection.value.loans.byTeam[teamID]
                              ]}
                              selectedQuestionIDs={resolvedSelection.value.selectedQuestions}
                              selectedPlayerIDs={mapGetOrFail(resolvedSelection.value.selectedPlayerIDsByTeamIDish, teamID)}
                              registrationQuestions={resolvedSelection.value.registrationQuestions}
                              registrationQuestionAnswers={resolvedSelection.value.registrationQuestionAnswers}
                              busyByPlayerID={busyByPlayerID.value}
                              busyByTeamID={busyByTeamID.value}
                              teamAverageRatings={teamAverageRatings.value}
                              isFocused={/*used to be configurable, probably can get rid of this*/ false}
                              offerFocus={/*used to be configurable, probably can get rid of this*/ false}
                              showTentativeAssignmentInfo={resolvedSelection.value.mode.type === "multi-assign"}
                              moveSelectedPlayersButtonLabel={"Move selected players"}
                              pageHasPendingChangesSomewhere={hasPendingChanges.value}
                              isExpanded={expandedByTeamID.value.has(teamID)}
                              isModalTarget={teamID === currentModalTargetTeamID.value}
                              droppable={new Set([unassignedPlayerDragMimeType, assignedPlayerDragMimeType])}
                              showDragGrip={true}
                              onRequestCloneFromOtherTeam={() => {
                                const {seasonUID, competitionUID, divID} = menuSelection.value
                                if (!seasonUID || !competitionUID || !divID) {
                                  // should always have this here
                                  return;
                                }

                                const seasonMenuNode = getTeamChooserMenuNode(menu.value, "season", {seasonUID: seasonUID})

                                if (!seasonMenuNode) {
                                  // shouldn't happen...
                                  return
                                }

                                currentModalTargetTeamID.value = teamID

                                cloneFromOtherTeamModalController.open({
                                  destCompetitionUID: competitionUID,
                                  destSeasonUID: seasonUID,
                                  destDivID: divID,
                                  destTeamID: teamID,
                                  cloningInto_seasonName: seasonMenuNode.name,
                                  cloningInto_teamName: team.team.team,
                                })
                              }}
                              onDidDropPlayers={({originTeam, players}) => {
                                assertNonNull(resolvedSelection.value)
                                setPlayerSelections({players, setTo: "not-selected"})
                                locallyMovePlayers({originTeam, targetTeam: team, players})
                              }}
                              onDidRequestMovePlayers={({players}) => {
                                assertNonNull(resolvedSelection.value)
                                locallyMovePlayersModalController.open({action: "move", originTeam: team, players})
                              }}
                              onDeleteAssignments={({players}) => {
                                restoreLocallyMovedPlayers({originTeam: team, players})
                                setPlayerSelections({players, setTo: "not-selected"})
                              }}
                              onUndeleteLoans={({players}) => {
                                players.forEach(player => {
                                  assertIs(player.type, "assigned-but-tentatively-unassigned")
                                  tentativelyMovedToAssigned(player)
                                })
                              }}
                              onToggleExpand={() => {
                                if (expandedByTeamID.value.has(teamID)) {
                                  expandedByTeamID.value.delete(teamID)
                                }
                                else {
                                  expandedByTeamID.value.add(teamID);
                                }
                              }}
                            >
                              {
                                {
                                  // any difference between this and a prop?
                                  // this still has to trigger a re-render each .map(...) iteration right? even if only to check that the resulting DOM didn't change?
                                  moveElementArrow: () => {
                                    if (teamsListing_currentIdx === 0) {
                                      return (
                                        <div>
                                          <Down1/>
                                          <ToEnd/>
                                        </div>
                                      )
                                    }
                                    else if (teamsListing_currentIdx === teamsListing.length - 1) {
                                      return (
                                        <div>
                                          <Up1/>
                                          <ToStart/>
                                        </div>
                                      )
                                    }
                                    else {
                                      return (
                                        <div>
                                          <ToStart/>
                                          <Up1/>
                                          <Down1/>
                                          <ToEnd/>
                                        </div>
                                      )
                                    }

                                    function ToStart() {
                                      return (
                                        <span
                                          class="p-1 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] rounded-md"
                                          v-tooltip={{content: "Move to first"}}
                                          onClick={()=> doMoveOneTeam({oldIdx: teamsListing_currentIdx, newIdx: 0})}
                                        >
                                          <FontAwesomeIcon icon={faArrowUpToLine}/>
                                        </span>
                                      )
                                    }

                                    function Up1() {
                                      return (
                                        <span
                                          class="p-1 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] rounded-md"
                                          v-tooltip={{content: "Move up 1"}}
                                          onClick={()=> doMoveOneTeam({oldIdx: teamsListing_currentIdx, newIdx: teamsListing_currentIdx - 1})}
                                        >
                                          <FontAwesomeIcon icon={faArrowUp}/>
                                        </span>
                                      )
                                    }

                                    function Down1() {
                                      return (
                                        <span
                                          class="p-1 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] rounded-md"
                                          v-tooltip={{content: "Move down 1"}}
                                          onClick={()=> doMoveOneTeam({oldIdx: teamsListing_currentIdx, newIdx: teamsListing_currentIdx + 1})}
                                        >
                                          <FontAwesomeIcon icon={faArrowDown}/>
                                        </span>
                                      )
                                    }

                                    function ToEnd() {
                                      return (
                                        <span
                                          class="p-1 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] rounded-md"
                                          v-tooltip={{content: "Move to last"}}
                                          onClick={()=> doMoveOneTeam({oldIdx: teamsListing_currentIdx, newIdx: teamsListing.length - 1})}
                                        >
                                          <FontAwesomeIcon icon={faArrowDownToLine}/>
                                        </span>
                                      )
                                    }
                                  }
                                } satisfies TeamElementSlots
                              }
                            </TeamElement>
                          </Fragment>
                        )
                    })}
                  </div>
                  </FormKit>
                </div>
              )
              : null
          }
        </div>
      )
    }

    /**
     * "locally" move players, as in, do work on the client, but don't immediately push changes to the backend.
     *
     * @originTeam the team (as per the UI, as opposed to 'actual db state right now') the players are being moved from
     * @targetTeam the team (as per the UI, as above) the players are being moved to
     *
     * Note that "already assigned" players who are being moved around will maintain their own
     * `playerAssignment` property, which may-or-may-not-be different from the UI's origin/target team as players are tentatively moved across teams
     * (a player's `playerAssignment` reflects "the current assignment in the DB", whereas {origin,target}Team indicate
     * possibly-tentative assignments as reflected by the UI).
     *
     * This is intended to support moving both loans and assignments at the same time.
     */
    function locallyMovePlayers({originTeam, targetTeam, players}: {
      originTeam: TeamForTeamAssignmentsView | null,
      targetTeam: TeamForTeamAssignmentsView | null,
      players: PlayerForTeamAssignmentViewEx[]
    }) {
      assertNonNull(resolvedSelection.value, "should be truthy at time of call")

      if (targetTeam) {
        assertTruthy(resolvedSelection.value.assignments.byTeam[targetTeam.team.teamID])
        assertTruthy(resolvedSelection.value.loans.byTeam[targetTeam.team.teamID])
      }

      const getChildID = (v: PlayerForTeamAssignmentViewEx) => v.apiData.child.childID

      //
      // We will perform deduplication in case callers pass in nonsense duplicates (it never makes
      // sense to have 1 childID twice on the same team); although callers shouldn't pass in nonsense duplicates,
      // it's hard to prove statically they've adhered to that.
      //
      const requestedChildIDs = new Set(players.map(getChildID))
      const childIDsInTargetTeam = new Set([
          ...targetTeam
            ? resolvedSelection.value.assignments.byTeam[targetTeam.team.teamID].map(getChildID)
            : resolvedSelection.value.assignments.unassignedPool.map(getChildID),
          ...targetTeam ? resolvedSelection.value.loans.byTeam[targetTeam.team.teamID].map(getChildID) : []
        ])

      const isMoveable = (type: "assignment" | "loan") => (v: PlayerForTeamAssignmentViewEx) => {
        if (type === "loan" && !targetTeam && v.type === "assigned") {
          // already-assigned-loans aren't physically moved on deletion ("assignment to the null team")
          return false;
        }

        return v.clientData.type === type
          && requestedChildIDs.has(getChildID(v))
          && !childIDsInTargetTeam.has(getChildID(v))
      }

      const isBeingDeleted = (type: "assignment" | "loan") => (v: PlayerForTeamAssignmentViewEx) => {
        return !targetTeam
          && v.clientData.type === type
          && requestedChildIDs.has(getChildID(v))
      }

      // In the playerAssignments case, this could be a straight-up reference to `players`,
      // but in the loans case, we make copies of the things we move, so this will hold refs to the copies.
      const mutationTargets : PlayerForTeamAssignmentViewEx[] = []

      {
        // playerAssignments
        const sourceList = originTeam
          ? ptr(resolvedSelection.value.assignments.byTeam, originTeam.team.teamID)
          : ptr(resolvedSelection.value.assignments, "unassignedPool")

        const targetList = targetTeam
          ? ptr(resolvedSelection.value.assignments.byTeam, targetTeam.team.teamID)
          : ptr(resolvedSelection.value.assignments, "unassignedPool")

        // remove from origin team's listing
        sourceList.value = arrayDropIf(sourceList.value, isMoveable("assignment"))

        // add to target team's listing
        const moved = arrayRetainIf(players, isMoveable("assignment"))
        targetList.value.push(...moved)
        mutationTargets.push(...moved)
      }

      {
        // playerLoans
        const sourceList = originTeam
          ? ptr(resolvedSelection.value.loans.byTeam, originTeam.team.teamID)
          : null

        const targetList = targetTeam
          ? ptr(resolvedSelection.value.loans.byTeam, targetTeam.team.teamID)
          : null

        if (sourceList) {
          sourceList.value = arrayDropIf(sourceList.value, isMoveable("loan"))
        }
        else {
          // no-op, sources are from player-loan search results pool, which is different from the assignments pool in that
          // plucking elements from the loan pool does not remove them (they moved from the player loan pool to every available team,
          // excepting those teams where the player is already assigned or loaned).
        }

        if (targetList) {
          // We need to copy the source player objects in this case,
          // or they will incorrectly be references to the objects in the pool.
          // Also, we clear any "marked for delete" flag because even it was marked for delete, if it is being moved from A to B,
          // where it as marked for delete on A, presumably we don't it want it to "move to B and then delete from B".
          const copied = arrayRetainIf(players, isMoveable("loan")).map(copyViaJsonRoundTrip)
          targetList.value.push(...copied)
          mutationTargets.push(...copied)
        }
        else {
          // there's no target list, which means they are being "deleted"
          // We can't move them to the "loan pool" because that's not a thing like it is with the assignments pool,
          // so they are updated in-place to mark them deleted
          arrayRetainIf(players, isBeingDeleted("loan")).forEach(v => {
              mutationTargets.push(v) // no copy here but still need a ref to it, just as in the non-loan case
          })
        }
      }

      // update moved player data as appropriate
      mutationTargets.forEach(player => {
        switch (player.type) {
          case "assigned":
            if (!targetTeam) {
              assignedToTentativelyUnassigned(player)
            }
            else if (targetTeam.team.teamID !== player.apiData.assignment.teamID) {
              assignedToTentativelyMoved(player);
            }
            else {
              tentativelyMovedToAssigned(player)
            }
            return;
          case "assigned-but-tentatively-moved":
            if (!targetTeam) {
              assignedToTentativelyUnassigned(player)
            }
            else if (targetTeam.team.teamID !== player.apiData.assignment.teamID) {
              assignedToTentativelyMoved(player);
            }
            else {
              tentativelyMovedToAssigned(player);
            }
            return;
          case "assigned-but-tentatively-unassigned":
            if (!targetTeam) {
              assignedToTentativelyUnassigned(player)
            }
            else if (targetTeam.team.teamID !== player.apiData.assignment.teamID) {
              assignedToTentativelyMoved(player)
            }
            else {
              tentativelyMovedToAssigned(player);
            }
            return;
          case "tentatively-assigned":
            if (!targetTeam) {
              tentativelyAssignedToUnassigned(player)
            }
            else {
              // no work to do
            }
            return;
          case "unassigned":
            if (!targetTeam) {
              // no work to do
            }
            else {
              unassignedToTentativelyAssigned(player);
            }
            return;
          default: exhaustiveCaseGuard(player)
        }
      })
    }

    /**
     * Scatter players back to either unassigned or their originally assigned team, as appropriate.
     * We could first partition by target team and then do one iteration per target team,
     * but the lists aren't expected to be large so a single `locallyMovePlayers` call per player should be fine.
     */
    function restoreLocallyMovedPlayers({originTeam, players}: {
      originTeam: TeamForTeamAssignmentsView | null,
      players: PlayerForTeamAssignmentViewEx[]
    }) {
      assertNonNull(resolvedSelection.value)
      for (const player of players) {
        const targetTeam = (player.type === "assigned" || player.type === "tentatively-assigned" || player.type === "unassigned")
          ? null
          : player.type === "assigned-but-tentatively-moved" || player.type === "assigned-but-tentatively-unassigned"
          ? arrayFindOrFail(resolvedSelection.value.teams, v => v.team.teamID === player.apiData.assignment.teamID)
          : exhaustiveCaseGuard(player);
        locallyMovePlayers({originTeam, targetTeam, players: [player]})
      }
    }
  }
})

function isAssigned(v: PlayerForTeamAssignmentViewEx) : v is PlayerForTeamAssignmentViewEx_Assigned {
  return v.type === "assigned"
}

function isTentativelyUnassigned(v: PlayerForTeamAssignmentViewEx) : v is PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned {
  return v.type === "assigned-but-tentatively-unassigned"
}

function isTentativelyAssigned(v: PlayerForTeamAssignmentViewEx) : v is PlayerForTeamAssignmentViewEx_TentativelyAssigned {
  return v.type === "tentatively-assigned"
}

function isTentativelyMoved(v: PlayerForTeamAssignmentViewEx) : v is PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved {
  return v.type === "assigned-but-tentatively-moved"
}

/**
 * Does `player` represent an "definitely assigned" loan that has been updated locally
 */
function isDirtyPlayerLoan(player: PlayerForTeamAssignmentViewEx) : boolean {
  assertIs(player.clientData.type, "loan");

  if (!player.apiData.assignment) {
    // no existing assignment (e.g. "loan"), so not considered dirty here.
    return false
  }

  assertIs(player.apiData.assignment.baseType, "loan");
  const expDateChanged = (() : boolean => {
    const pristine = player.apiData.assignment.expirationDate;
    const dirty = Optional.getOr(player.clientData.expirationDate, "")
    return !!(
      (pristine && !dirty)
      || (!pristine && dirty)
      || (dirty && pristine && !dayjs(dirty).isSame(pristine, "minute"))
    )
  })()

  return expDateChanged ? true : false;
}

async function fullRefresh(args: {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  currentLoanSearch: null | PlayerLoanLookup,
  includeNeighboringDivs: boolean,
  ax?: AxiosInstance
}) : Promise<CheckedOmit<ResolvedSelection, "mode" | "version">> {
  const {
    seasonUID,
    competitionUID,
    divID,
    includeNeighboringDivs,
  } = args;

  const ax = args.ax ?? axiosInstance

  const competition = await getCompetitionOrFail(competitionUID)
  const teams = (Object.values((await getTeamAssignmentsViewTeams(ax, {seasonUID, competitionUID, divID})))).sort(sortBy(getTeamName));

  const teamIDs = teams.map(v => v.team.teamID);

  // team chooser bug allows us to find divIDs for which we do not have permission,
  // and we'll get a 403 if we send out requests for that div here
  const hasDivPermission = (() => {
    const allowedDivs = getAllowedDivisions();
    return allowedDivs === "*" || allowedDivs.includes(divID)
  })();

  const [
    assignedPlayers,
    unassignedPlayers,
    registrationQuestions
  ] = await Promise.all([
    listTeamSeasonPlayers(ax, {seasonUID, teamIDs}),
    hasDivPermission
      ? listUnassignedPlayers(ax, {seasonUID, competitionUID, divID, includeNeighboringDivs})
      : ([] as PlayerForTeamAssignmentView[])
    ,
    getRegistrationPageItems(ax, {
      includeDisabled: false
    }).then(pageItems => pageItems
      .filter(isRegistrationQuestion)
      .map(v => v.pageItem)
      .sort(sortBy(_ => _.shortLabel))
    )
  ]);

  // should be naturally distinct already, but this isn't expensive to do
  const distinctPlayerIDs = [...new Set([
    ...Object.values(assignedPlayers).flatMap((v) => v.playerAssignments.map(pa => pa.child.childID)),
    ...Object.values(assignedPlayers).flatMap((v) => v.playerLoans.map(pl => pl.child.childID)),
    ...Object.values(unassignedPlayers).map((v) => v.child.childID),
  ])];

  const registrationQuestionAnswers = await getRegistrationQuestionAnswers(
    ax,
    {seasonUID, playerIDs: distinctPlayerIDs}
  )

  const mungedAssignedPlayers = (() => {
    const result : ResolvedSelection["assignments"] = {
      unassignedPool: unassignedPlayers.map((v) : PlayerForTeamAssignmentViewEx_Unassigned => {
        return {
          id: nextOpaqueVueKey(),
          type: "unassigned",
          apiData: v,
          clientData: {
            type: "assignment",
            disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, v)
          }
        }
      }),
      byTeam: {}
    };
    Object.entries(assignedPlayers).forEach(([teamID, raw]) => {
      result.byTeam[teamID] = mapTeamSeasonPlayerAssignmentsToPlayerForTeamAssignmentView(raw.playerAssignments).map((v) : PlayerForTeamAssignmentViewEx_Assigned => {
        assertNonNull(v.assignment);
        return {
          id: nextOpaqueVueKey(),
          type: "assigned",
          apiData: v as WithDefinite<PlayerForTeamAssignmentView, "assignment">,
          clientData: {
            type: "assignment",
            disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, v)
          }
        }
      })
    })
    return result;
  })()

  const mungedLoans = (() => {
    const result : ResolvedSelection["loans"] = {
      searchResults: args.currentLoanSearch ?? PlayerLoanLookup(freshAxiosInstance({useCurrentBearerToken: true, responseInterceptors:[]})),
      byTeam: {}
    };
    Object.entries(assignedPlayers).forEach(([teamID, raw]) => {
      result.byTeam[teamID] = mapTeamSeasonPlayerAssignmentsToPlayerForTeamAssignmentView(raw.playerLoans).map((v) : PlayerForTeamAssignmentViewEx_Assigned => {
          assertIs(v.assignment?.baseType, "loan");
          return {
            id: nextOpaqueVueKey(),
            type: "assigned",
            apiData: v as WithDefinite<PlayerForTeamAssignmentView, "assignment">,
            clientData: {
              type: "loan",
              disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, v),
              expirationDate: Optional.of(dayjsFormatOr(v.assignment.expirationDate, DAYJS_FORMAT_HTML_DATETIME, "") || null)
            },
          }
        })
    })
    return result;
  })()

  const selectedPlayerIDs = (() => {
    const v : ResolvedSelection["selectedPlayerIDsByTeamIDish"] = new Map()

    v.set(k_selectedUnassignedAssignments, new SetEx())
    v.set(k_selectedUnassignedLoans, new SetEx())

    teams.forEach(team => {
      v.set(team.team.teamID, new SetEx())
    })

    return v;
  })()

  return {
    teams,
    assignments: mungedAssignedPlayers,
    loans: mungedLoans,
    selectedPlayerIDsByTeamIDish: selectedPlayerIDs,
    registrationQuestions,
    selectedQuestions: (() => {
      const all = selectedQuestions_load(User.value.userID)
      // it's possible that some persisted customQuestionIDs are no longer a thing, and we need to drop them.
      // So this "keep each customQuestionID we loaded IFF that ID is present in `registrationQuestions`
      all.customQuestionIDs = all.customQuestionIDs.filter(id => !!registrationQuestions.find(v => v.id === id))
      return all;
    })(),
    registrationQuestionAnswers,
    snapshotConfig: null,
  }
}

/**
 * Recalculates list indexes based on a movement of an element from `oldIdx` to `newIdx`.
 */
class ListIndexReorderer {
  private static readonly k_towardsStartOfList = 1
  private static readonly k_towardsEndOfList = 2

  private readonly dir : number;
  private readonly oldIdx : number;
  private readonly newIdx : number;

  constructor(args: {oldIdx: number, newIdx: number}) {
    assertTruthy(args.oldIdx >= 0)
    assertTruthy(args.newIdx >= 0)

    this.oldIdx = args.oldIdx;
    this.newIdx = args.newIdx;
    this.dir = args.newIdx < args.oldIdx
      ? ListIndexReorderer.k_towardsStartOfList
      : ListIndexReorderer.k_towardsEndOfList
  }

  getNewOrdering(idx: number) : number {
    if (this.dir === ListIndexReorderer.k_towardsStartOfList) {
      if (idx === this.oldIdx) {
        return this.newIdx
      }
      else if (idx >= this.newIdx && idx <= this.oldIdx) {
        return idx + 1
      }
      else {
        return idx
      }
    }
    else {
      if (idx === this.oldIdx) {
        return this.newIdx
      }
      else if (idx < this.oldIdx || idx > this.newIdx) {
        return idx
      }
      else {
        return idx - 1
      }
    }
  }
}

export const testExports = {
  ListIndexReorderer: ListIndexReorderer
}

function mapTeamSeasonPlayerAssignmentsToPlayerForTeamAssignmentView(vs: GetTeamSeasonPlayersResponse["playerAssignments"] | GetTeamSeasonPlayersResponse["playerLoans"]) : PlayerForTeamAssignmentView[] {
  return vs.map(v => {
    return ({
      assignment: v,
      child: v.child,
      registration: v.registration,
    }) satisfies PlayerForTeamAssignmentView
  })
}

function getChangesForApiSubmit(resolvedSelection: ResolvedSelection) {
  const assignments = (() => {
    const add : BulkCreateUpdateDeleteTeamAssignments["assignments"]["add"] = Object
      .entries(resolvedSelection.assignments.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(isTentativelyAssigned)
          .map(v => {
            assertIs(v.clientData.type, "assignment")
            return {
              teamID,
              registrationID: v.apiData.registration.registrationID,
              seasonUID: v.apiData.registration.seasonUID,
            }
          })
      });

    const delete_ : BulkCreateUpdateDeleteTeamAssignments["assignments"]["delete"] = resolvedSelection
      .assignments
      .unassignedPool
      .filter(isTentativelyUnassigned)
      .map(v => {
        assertIs(v.apiData.assignment.baseType, "assignment")
        return {
          assignmentID: v.apiData.assignment.assignmentID
        }
      })

    const move = Object
      .entries(resolvedSelection.assignments.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(isTentativelyMoved)
          .map(v => {
            assertIs(v.apiData.assignment.baseType, "assignment")
            return {
              add: {
                teamID,
                registrationID: v.apiData.registration.registrationID,
                seasonUID: v.apiData.registration.seasonUID,
              } satisfies BulkCreateUpdateDeleteTeamAssignments["assignments"]["add"][number],
              delete: {
                assignmentID: v.apiData.assignment.assignmentID
              } satisfies BulkCreateUpdateDeleteTeamAssignments["assignments"]["delete"][number]
            }
          })
      });

    return {
      add: [...add, ...move.map(v => v.add)],
      delete: [...delete_, ...move.map(v => v.delete)],
    }
  })();

  const loans = (() => {
    const add : BulkCreateUpdateDeleteTeamAssignments["loans"]["add"] = Object
      .entries(resolvedSelection.loans.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(isTentativelyAssigned)
          .map(v => {
            assertIs(v.apiData.assignment?.baseType, undefined)
            assertIs(v.clientData.type, "loan")
            return {
              teamID,
              registrationID: v.apiData.registration.registrationID,
              seasonUID: v.apiData.registration.seasonUID,
              expirationDate: Optional.getOr(v.clientData.expirationDate, null),
            } satisfies BulkCreateUpdateDeleteTeamAssignments["loans"]["add"][number]
          })
      });

    const delete_ : BulkCreateUpdateDeleteTeamAssignments["loans"]["delete"] = Object
      .entries(resolvedSelection.loans.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(isTentativelyUnassigned)
          .map(v => {
            assertIs(v.apiData.assignment.baseType, "loan")
            assertIs(v.clientData.type, "loan")
            return {
              loanID: v.apiData.assignment.loanID
            } satisfies BulkCreateUpdateDeleteTeamAssignments["loans"]["delete"][number]
          })
      })

    const move = Object
      .entries(resolvedSelection.loans.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(isTentativelyMoved)
          .map(v => {
            assertIs(v.apiData.assignment.baseType, "loan")
            assertIs(v.clientData.type, "loan")
            return {
              add: {
                teamID,
                registrationID: v.apiData.registration.registrationID,
                seasonUID: v.apiData.registration.seasonUID,
                expirationDate: Optional.getOr(v.clientData.expirationDate, null),
              } satisfies BulkCreateUpdateDeleteTeamAssignments["loans"]["add"][number],
              delete: {
                loanID: v.apiData.assignment.loanID
              } satisfies BulkCreateUpdateDeleteTeamAssignments["loans"]["delete"][number]
            }
          })
      });

    const update = Object
      .entries(resolvedSelection.loans.byTeam)
      .flatMap(([teamID, players]) => {
        return players
          .filter(v => v.type === "assigned" && isDirtyPlayerLoan(v))
          .map(v => {
            assertNonNull(v.apiData.assignment)
            assertIs(v.apiData.assignment.baseType, "loan")
            assertIs(v.clientData.type, "loan")
            return {
              loanID: v.apiData.assignment.loanID,
              expirationDate: Optional.getOr(v.clientData.expirationDate, null),
            } satisfies BulkCreateUpdateDeleteTeamAssignments["loans"]["update"][number]
          })
      });

    return {
      add: [...add, ...move.map(v => v.add)],
      update,
      delete: [...delete_, ...move.map(v => v.delete)],
    }
  })()

  return {
    assignments,
    loans
  }
}
