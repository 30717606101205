/**
 * @param userHomeAddress typically from `User.value.userAddress`, empty string seems to be fine and google generates "directions from address detected on current machine"
 */
export function directionsToPlayingFieldURL(userHomeAddress: string, field: {fieldStreet: string, fieldCity: string, fieldState: string, fieldZip: string}) {
  const fieldAddress = `${field.fieldStreet} ${field.fieldCity} ${field.fieldState} ${field.fieldZip}`
  const params = [
    `api=1`,
    `origin=${encodeURIComponent(userHomeAddress)}`,
    `destination=${encodeURIComponent(fieldAddress)}`,
  ].join("&")

  return `https://www.google.com/maps/dir/?${params}`
}
