import { FormKit, FormKitMessages } from "@formkit/vue";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { FK_nodeRef, forceCheckedIndexedAccess, noAvailableOptions, parseIntOr, vReqT, weakEq } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { defineComponent, onMounted, ref } from "vue";
import { getCompetitionOptions, getSeasonOptions, getTeamOptions, TeamOption } from "./CloneTeamAssignmentsModal.io";
import { teamDesignationAndMaybeName } from "src/components/GameScheduler/calendar/GameScheduler.shared";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";

export const CloneTeamModal = defineComponent({
  props: {
    divID: vReqT<Guid>(),
    parentFocusedCompetitionUidOnMount: vReqT<Guid>(),
    parentFocusedSeasonUidOnMount: vReqT<Guid>(),
    cloningInto_seasonName: vReqT<string>(),
    cloningInto_teamName: vReqT<string>(),
  },
  emits: {
    submit: (_: CloneTeamForm) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const seasonOptions = ref(noAvailableOptions("Loading seasons..."));
    const competitionOptions = ref(noAvailableOptions("Loading programs..."))
    const teamOptions = ref(noAvailableOptions("Select a season and program..."))
    const teamOptionsResolver = ReactiveReifiedPromise<TeamOption[]>(undefined, {defaultDebounce_ms: 200})

    const form = ref(CloneTeamForm())
    const busy = ref(false)
    const fkRootNode = FK_nodeRef()

    const updateTeamOptions = async () : Promise<void> => {
      const seasonUID = form.value.sourceSeasonUID
      const competitionUID = form.value.sourceCompetitionUID
      const divID = props.divID;

      if (!seasonUID || !competitionUID || !divID) {
        teamOptions.value = noAvailableOptions("Select a season and program...")
        return;
      }

      try {
        busy.value = true
        teamOptions.value = noAvailableOptions("Loading teams...")

        const teams = await teamOptionsResolver.run(() => getTeamOptions(axiosAuthBackgroundInstance, {seasonUID, competitionUID, divID})).getResolvedOrFail()

        teamOptions.value = teams.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: teams.map(v => ({
            label: teamDesignationAndMaybeName(v),
            value: v.teamID
          }))}

        if (!teamOptions.value.options.find(v => v.value === form.value.sourceTeamID)) {
          form.value.sourceTeamID = forceCheckedIndexedAccess(teamOptions.value.options, 0)?.value ?? ""
        }
      }
      finally {
        busy.value = false
      }
    }

    const doSubmit = () : void => {
      ctx.emit("submit", form.value)
    }

    onMounted(async () => {
      try {
        busy.value = true
        const seasons = await getSeasonOptions(axiosAuthBackgroundInstance)
        const comps = await getCompetitionOptions(axiosAuthBackgroundInstance)

        seasonOptions.value = seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))};

        competitionOptions.value = comps.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: comps.map(v => ({label: v.competition, value: v.competitionUID}))}

        // Init default competitionUID
        // tries to use the parent focused competitionUID exactly
        // If that is for some reason not available, falls back to "first in list"
        if (competitionOptions.value.options.find(v => v.value === props.parentFocusedCompetitionUidOnMount)) {
          form.value.sourceCompetitionUID = props.parentFocusedCompetitionUidOnMount
        }
        else {
          form.value.sourceCompetitionUID = forceCheckedIndexedAccess(competitionOptions.value.options, 0)?.value ?? ""
        }

        // Init default seasonUID
        // tries to go to "one prior to the parent focused season",
        // and if it fails to find that (shouldn't though), it just falls back to "first in list"
        (() => {
          const parentFocusedSeasonIdx = parseIntOr(seasons.find(v => v.seasonUID === props.parentFocusedSeasonUidOnMount)?.seasonID, null)
          if (parentFocusedSeasonIdx === null) {
            form.value.sourceSeasonUID = defaultSeasonUID()
            return
          }

          const immediatelyPriorSeasonUID = seasons.find(v => weakEq(v.seasonID, parentFocusedSeasonIdx - 1))?.seasonUID
          if (!immediatelyPriorSeasonUID) {
            form.value.sourceSeasonUID = defaultSeasonUID()
            return
          }

          if (seasonOptions.value.options.find(v => v.value === immediatelyPriorSeasonUID)) {
            form.value.sourceSeasonUID = immediatelyPriorSeasonUID
          }
          else {
            form.value.sourceSeasonUID = defaultSeasonUID()
          }

          function defaultSeasonUID() {
            return forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value ?? ""
          }
        })();

        await updateTeamOptions()
      }
      finally {
        busy.value = false
      }
    })

    return () => {
      return (
        <div style="--fk-margin-outer: none; --fk-padding-input:.5em;">
          <FormKit type="form" actions={false} onSubmit={() => doSubmit()} ref={fkRootNode}>
            <div class="my-2">Cloning into {props.cloningInto_teamName} ({props.cloningInto_seasonName})</div>
            <div class="my-2 text-sm">Changes will take effect immediately (cloning skips the 'pending' state!).</div>
            <div class="my-2 text-sm">If a team season other than {props.cloningInto_seasonName} is selected, only players registered in the {props.cloningInto_seasonName} will be cloned.</div>
            <div class="rounded-md border">
              <div class="px-2 py-1 bg-green-800 text-white rounded-t-md">Clone Source</div>
              <div style="display:grid; grid-template-columns: max-content 1fr; gap:1em; align-items:start;" class="p-2">
                <div>Season</div>
                <FormKit
                  type="select"
                  disabled={seasonOptions.value.disabled}
                  options={seasonOptions.value.options}
                  v-model={form.value.sourceSeasonUID}
                  onInput={(value: any) => {
                    if (form.value.sourceSeasonUID === value) {
                      return
                    }
                    form.value.sourceSeasonUID = value
                    updateTeamOptions()
                  }}
                  validation={[["required"]]}
                  validationLabel="Season"
                  data-test="sourceSeasonUID"
                />
                <div>Program</div>
                <FormKit
                  type="select"
                  disabled={competitionOptions.value.disabled}
                  options={competitionOptions.value.options}
                  v-model={form.value.sourceCompetitionUID}
                  onInput={(value: any) => {
                    if (form.value.sourceCompetitionUID === value) {
                      return
                    }
                    form.value.sourceCompetitionUID = value
                    updateTeamOptions()
                  }}
                  validation={[["required"]]}
                  validationLabel="Program"
                  data-test="sourceCompetitionUID"
                />
                <div>Team</div>
                <FormKit
                  type="select"
                  disabled={teamOptions.value.disabled}
                  options={teamOptions.value.options}
                  v-model={form.value.sourceTeamID}
                  onInput={(value: any) => {
                    if (form.value.sourceTeamID === value) {
                      return
                    }
                    form.value.sourceTeamID = value
                  }}
                  validation={[["required"]]}
                  validationLabel="Team"
                  data-test="sourceTeamID"
                />
              </div>
            </div>
            <div class="px-2 py-1 mt-4 rounded-md border flex flex-col gap-1">
              <div class="flex items-start gap-2">
                <div class="mt-[2px]">
                  <FormKit type="checkbox" v-model={form.value.deleteAllExistingDestPlayerAssignments}/>
                </div>
                <div>Delete Existing {props.cloningInto_seasonName} Player Assignments for {props.cloningInto_teamName}</div>
              </div>
            </div>
            <div class="px-2 py-1 mt-4 rounded-md border flex flex-col gap-1">
              <div class="flex items-start gap-2">
                <div class="mt-[2px]">
                  <FormKit type="checkbox" v-model={form.value.includeCoachAssignments}/>
                </div>
                <div>Include Team Staff (Coaches & Administrators)</div>
              </div>
              <div class="flex items-start gap-2">
                <div class="mt-[2px]">
                  <FormKit type="checkbox" v-model={form.value.deleteAllExistingDestCoachAssignments}/>
                </div>
                <div>Delete Existing {props.cloningInto_seasonName} Team Staff for {props.cloningInto_teamName}</div>
              </div>
            </div>
            <div class="px-2 py-1 mt-4 rounded-md border flex flex-col gap-1">
              <div class="flex items-start gap-2">
                <div class="mt-[2px]">
                  <FormKit type="checkbox" v-model={form.value.includeVolunteerAssignments}/>
                </div>
                <div>Include Other Assigned Team-Level Volunteers</div>
              </div>
              <div class="flex items-start gap-2">
                <div class="mt-[2px]">
                  <FormKit type="checkbox" v-model={form.value.deleteAllExistingDestVolunteerAssignments}/>
                </div>
                <div>Delete Existing {props.cloningInto_seasonName} Team-Level Volunteers for {props.cloningInto_teamName}</div>
              </div>
            </div>
            <div class="mt-4 flex items-start gap-2">
              <Btn2 type="submit" disabled={busy.value} class="px-2 py-1">OK</Btn2>
              <Btn2 onClick={() => ctx.emit("cancel")} class={`${btn2_redEnabledClasses} px-2 py-1`}>Cancel</Btn2>
            </div>
            <div class="hidden">
              <FormKitMessages node={fkRootNode.value?.node}/>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

export function CloneTeamForm() {
  return {
    sourceSeasonUID: "" as "" | Guid,
    sourceCompetitionUID: "" as "" | Guid,
    sourceTeamID: "" as "" | Guid,
    includeCoachAssignments: false,
    includeVolunteerAssignments: false,
    deleteAllExistingDestPlayerAssignments: true,
    deleteAllExistingDestCoachAssignments: false,
    deleteAllExistingDestVolunteerAssignments: false,
  }
}

export type CloneTeamForm = ReturnType<typeof CloneTeamForm>
