import { PropType, computed, defineComponent, ref, watch } from "vue"

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { FormKitValidationRule, Reflike, UiOption, exhaustiveCaseGuard, parseIntOrFail, vOptT, vReqT } from "src/helpers/utils";
import { FormKit } from "@formkit/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

export type QuestionAnswer =
  | string
  | string[]
  | number
  | boolean
  | null

export type QuestionAnswerMap = {[questionID: iltypes.Guid]: QuestionAnswer}

/**
 * We probably want to do such conversions here in this file, before we emit "form answers" to some parent, prior to the parent issuing a network request.
 * Otherwise the parent needs to call this function, and basically always wants to, so it's just boilerplate.
 */
export function formAnswersToApiSubmittable(answers: QuestionAnswerMap) : {[questionID: iltypes.Guid]: string | string[] | boolean | number} {
  const ret : {[questionID: iltypes.Guid]: string | string[] | number | boolean} = {}
  for (const questionID of Object.keys(answers)) {
    const value = answers[questionID]
    if (typeof value === "string" || typeof value === "boolean" || typeof value === "number" || Array.isArray(value)) {
      ret[questionID] = value
    }
    else if (value === null) {
      // Discard
      // We could transform into empty string?
      // This is a question that had no answer to begin with, and was never interacted with.
    }
    else {
      exhaustiveCaseGuard(value)
    }
  }
  return ret;
}

function buildAnswersByQuestionID(
  questions: readonly iltypes.RegistrationQuestion[],
  answers: readonly iltournament.TournamentRegistrationAnswer[]
) : {[questionID: iltypes.Guid]: Reflike<QuestionAnswer>} {
  const questionLookup = new Map(questions.map(q => [q.id, q]));
  const result : {[questionID: iltypes.Guid]: Reflike<QuestionAnswer>} = {};

  for (const question of questions) {
    // all questions have an "initial value" of null, meaning no answer
    // if we set it to undefined, FormKit gets confused. see the player registration system for additional details.
    result[question.id] = {value: null};
  }

  for (const answer of answers) {
    const question = questionLookup.get(answer.questionID);
    if (!question) {
      // shouldn't happen ... old answer, with deleted question?
      continue;
    }
    else {
      switch (question.type) {
        case iltypes.QuestionType.RADIO:
          // fallthrough
        case iltypes.QuestionType.SELECT:
          // fallthrough
        case iltypes.QuestionType.TEXT:
          // fallthrough
        case iltypes.QuestionType.TEXTAREA:
          result[question.id] = {value: answer.answer}
          break;
        case iltypes.QuestionType.CHECKBOX:
          result[question.id].value ??= [];
          (result[question.id].value as string[]).push(answer.answer);
          break;
        default: exhaustiveCaseGuard(question.type);
      }
    }
  }

  return result;
}

function buildPointsByQuestionIdByAnswer(
  questions: readonly iltypes.RegistrationQuestion[],
  answers: readonly iltournament.TournamentRegistrationAnswer[]
) {
  const result : {[questionID: iltypes.Guid]: undefined | {[answer: string]: undefined | number}} = {}
  for (const q of questions) {
    if (q.type === iltypes.QuestionType.TEXT || q.type === iltypes.QuestionType.TEXTAREA) {
      continue;
    }
    result[q.id] = {}
    for (const option of q.questionOptions) {
      // falsy value should be turned into 0, no other non-integer values are expected
      result[q.id]![option.optionValue] = parseIntOrFail(option.tournamentTeamReg_points || 0)
    }
  }
  for (const a of answers) {
    if (a.points !== "") { // if it has associated points then its question had associated options with points
      result[a.questionID] ??= {}
      // always prefer the points already persisted for this answer, even if the point value associated with this answer has changed
      // (so, e.g. resubmitting the answers unchanged would cause the resulting point values to become different. But we always show
      // the "currently persisted" values)
      result[a.questionID]![a.answer] = parseIntOrFail(a.points); // if we got here, failure to parse an int is a bug
    }
  }
  return result;
}

function isQuestionPageItem(v: iltournament.TournTeamRegPageItem) : v is iltournament.TournTeamRegPageItem_Question {
  return v.type === iltypes.PageItemType.QUESTION
}

/**
 * An answer may itself be a collection of a few answers for a single checkbox type question.
 * This returns the answers as an array of answers:
 *  - a null answer is an array of length 0
 *  - a radio or checkbox answer will be an array of length either 0 or 1
 *  - a checkbox answer become an array of zero or more
 */
function explodeAnswer(a: QuestionAnswer) : string[] {
  if (a === null) {
    return [];
  }
  else {
    return (Array.isArray(a) ? a : [a]).map(v => v!.toString());
  }
}

/**
 * This gives the sum off all the points for the all the answers in the checkbox case, and the point value
 * for the single selected answer in the radio/select case.
 */
function pointsForAnswer(a: QuestionAnswer, pointsByAnswer: {[answer: string]: number | undefined}) {
  // coerce all forms of answers to an array of strings, dropping nulls
  const answers = explodeAnswer(a);
  let sum = 0;
  for (const a of answers) {
    sum += pointsByAnswer[a] ?? 0;
  }
  return sum;
}

const Content = defineComponent({
  props: {
    pageItem: {
      required: true,
      type: Object as PropType<iltournament.TournTeamRegPageItem_ContentChunk>
    },
  },
  setup(props) {
    return () => <div class="FORCE_DEFAULT_BROWSER_ANCHOR_STYLE_IN_ALL_DESCENDANTS" v-html={props.pageItem.containedItem.defaultText}/>
  }
})

const Question = defineComponent({
  name: "Question",
  props: {
    pageItem: {
      required: true,
      type: Object as PropType<iltournament.TournTeamRegPageItem_Question>
    },
    mut_answer: {
      required: true,
      type: null as any as PropType<Reflike<QuestionAnswer>>
    },
    readonly: {
      required: true,
      type: Boolean,
    },
    pointsDetail: {
      required: true,
      type: Object as PropType<
        | { doShowPoints: false }
        | {
            doShowPoints: true,
            pointsByAnswer: {[answer: string]: number | undefined}
          }
      >
    }
  },
  setup(props) {
    // santiy check
    watch(() => props.pointsDetail, () => {
      if (props.pointsDetail.doShowPoints && (
          containedQuestion.value.type === iltypes.QuestionType.TEXT
          || containedQuestion.value.type === iltypes.QuestionType.TEXTAREA
      )) {
        throw Error("points detail should never be provided for a text or text area question");
      }
    }, {deep: true});

    const containedQuestion = computed<iltypes.RegistrationQuestion>(() => props.pageItem.containedItem)

    const options = computed<UiOption[] | undefined>(() => {
      switch (containedQuestion.value.type) {
        case iltypes.QuestionType.SELECT:
          return [
            {label: "", value: ""},
            ...buildOptions()
          ];
        case iltypes.QuestionType.RADIO:
          // fallthrough
        case iltypes.QuestionType.CHECKBOX:
          return buildOptions();
        case iltypes.QuestionType.TEXT:
          // fallthrough
        case iltypes.QuestionType.TEXTAREA:
          return undefined;
        default: return exhaustiveCaseGuard(containedQuestion.value.type);
      }

      function buildOptions() : UiOption[] {
        const currentAnswersThatHaveNoAssociatedOption = new Set(explodeAnswer(props.mut_answer.value));
        const fromExistingOptions : UiOption[] = containedQuestion
          .value
          .questionOptions
          .map(opt => {
            // map with side-effect
            currentAnswersThatHaveNoAssociatedOption.delete(opt.optionValue);
            return {label: opt.optionText, value: opt.optionValue};
          });

        // If there were existing answers for which there are no "current" options,
        // because those options were deleted,
        // we need to synthesize the options. This can be a no-op if all the existing answers were removed from the Set
        // during the preceding map operation. Note that we won't have the labels in this case.
        const syntheticOptionsFromCurrentAnswers : UiOption[] = [...currentAnswersThatHaveNoAssociatedOption].map(answer => ({label: answer, value: answer}));

        return [...syntheticOptionsFromCurrentAnswers, ...fromExistingOptions];
      }
    })

    // In the player registration system, a lot of validation rules require knowledge about the larger universe,
    // like "does the player have any other registrations and if so are any of them active or canceled" or etc., so
    // we'll probably need to similarly suss out any data dependencies here as we discover them.
    const validation = computed<FormKitValidationRule[]>(() => {
      const ret : FormKitValidationRule[] = []
      if (containedQuestion.value.isRequired) {
        ret.push(["required"])
      }
      return ret;
    })

    // is this an html thing, or a formkit thing? disabled=false ends up with
    // an element that *looks* disabled but isn't. We have to use disabled=undefined.
    const disabled = computed(() => props.readonly ? true : undefined);

    const aggregatedPointsIfShowingPoints = computed<number | null>(() => {
      return props.pointsDetail.doShowPoints
        ? pointsForAnswer(props.mut_answer.value, props.pointsDetail.pointsByAnswer)
        : null;
    })

    return () => (
      <div class="my-2" style="max-width: var(--fk-max-width-input); --fk-border:none; --fk-margin-outer:0;">
        {
          (() => {
            switch (containedQuestion.value.type) {
              case iltypes.QuestionType.RADIO:
                return (
                  <div class="p-2">
                    <div class="border border-gray-500 rounded-md p-2">
                      <MaybeRequiredLabel q={containedQuestion.value} a={props.mut_answer.value}/>
                      <FormKit
                        type="radio"
                        options={options.value}
                        v-model={props.mut_answer.value}
                        validation={validation.value}
                        data-test={containedQuestion.value.id}
                        disabled={disabled.value}
                        name={containedQuestion.value.label}
                      />
                      <PointsDisplay/>
                    </div>
                  </div>
                )
              case iltypes.QuestionType.SELECT:
                return (
                  <div class="p-2">
                    <MaybeRequiredLabel q={containedQuestion.value} a={props.mut_answer.value}/>
                    <FormKit
                      type="select"
                      options={options.value}
                      v-model={props.mut_answer.value}
                      validation={validation.value}
                      data-test={containedQuestion.value.id}
                      disabled={disabled.value}
                      name={containedQuestion.value.label}
                    />
                    <PointsDisplay/>
                  </div>
                )
              case iltypes.QuestionType.CHECKBOX:
                return (
                  <div class="p-2">
                    <div class="border border-gray-500 rounded-md p-2">
                      <MaybeRequiredLabel q={containedQuestion.value} a={props.mut_answer.value}/>
                      <FormKit
                        type="checkbox"
                        options={options.value}
                        v-model={props.mut_answer.value}
                        validation={validation.value}
                        data-test={containedQuestion.value.id}
                        disabled={disabled.value}
                        name={containedQuestion.value.label}
                      />
                      <PointsDisplay/>
                    </div>
                  </div>
                )
              case iltypes.QuestionType.TEXT:
                return (
                  <div class="p-2">
                    <MaybeRequiredLabel q={containedQuestion.value} a={props.mut_answer.value}/>
                    <FormKit
                      type="text"
                      v-model={props.mut_answer.value}
                      validation={validation.value}
                      data-test={containedQuestion.value.id}
                      disabled={disabled.value}
                      name={containedQuestion.value.label}
                    />
                    <PointsDisplay/>
                  </div>
                )
              case iltypes.QuestionType.TEXTAREA:
                return (
                  <div class="p-2">
                    <MaybeRequiredLabel q={containedQuestion.value} a={props.mut_answer.value}/>
                    <FormKit
                      type="textarea"
                      v-model={props.mut_answer.value}
                      validation={validation.value}
                      data-test={containedQuestion.value.id}
                      disabled={disabled.value}
                      name={containedQuestion.value.label}
                    />
                    <PointsDisplay/>
                  </div>
                );
              default: exhaustiveCaseGuard(containedQuestion.value.type);
            }
          })()
        }
        {

        }
      </div>
    );

    function PointsDisplay() {
      return aggregatedPointsIfShowingPoints.value === null
        ? null
        : <div class="text-xs flex justify-end mt-2">Points: {aggregatedPointsIfShowingPoints.value}</div>
    }

    function MaybeRequiredLabel({q, a}: {q: iltypes.RegistrationQuestion, a: QuestionAnswer}) {
      return q.isRequired
        ? <RequiredLabel isSatisfied={isNonEmptyAnswer()} label={q.label}/>
        : <div class="font-medium">{q.label}</div>

      function isNonEmptyAnswer() {
        if (a === null) {
          return false;
        }
        if (Array.isArray(a)) {
          return a.length > 0
        }
        if (typeof a === "string") {
          return a.trim().length > 0
        }
        return true;
      }
    }

    function RequiredLabel({isSatisfied, label}: {isSatisfied: boolean, label: string}) {
      return (
        <div>
          <div class="flex items-center">
            <span class={`${isSatisfied ? "text-green-500" : "text-yellow-500"}`} style="font-size:.5em;">
              <FontAwesomeIcon icon={faCircle}/>
            </span>
            {/*negative margin to fix perfectly centered thing not visually looking centered*/}
            <span style="margin-top:-3px;" class="ml-2 font-medium">{label}</span>
          </div>
        </div>
      )
    }
  }
})

export const TournamentTeamRegistrationQuestionsForm = defineComponent({
  name: "TournamentRegistrationForm",
  props: {
    pageItems: {
      required: true,
      type: Array as PropType<readonly iltournament.TournTeamRegPageItem[]>
    },
    existingAnswers: {
      required: true,
      type: Array as PropType<readonly iltournament.TournamentRegistrationAnswer[]>
    },
    // Which questions are "readonly"
    // Not providing this is the same as providing an empty Set, i.e. "nothing is readonly"
    readonlyQuestionIDs: {
      required: false,
      type: null as any as PropType<Set<iltypes.Guid> | undefined>
    },
    /**
     * `undefined` is same as false
     */
    doShowPoints: {
      required: false,
      type: null as any as PropType<boolean | undefined>
    },
    /**
     * pass `true` to not show a submit button, intended for "preview" mode
     */
    doHideSubmitButton: {
      required: false,
      type: null as any as PropType<boolean | undefined>
    },
    submitLabel: {
      required: false,
      default: "Submit Team Registration"
    }
  },
  emits: {
    /**
     * n.b. emit QuestionAnswer, not Reflike<QuestionAnswer>;
     * this is a defensive copy to prevent emit handler from the result directly
     */
    submit: (_: QuestionAnswerMap) => true
  },
  setup(props, {emit}) {
    const questions = computed(() => props.pageItems.filter(isQuestionPageItem));

    // almost a QuestionAnswerMap, but values are objects holding a pointer to the answer,
    // to allow descendant forms to directly write into them
    const answers = ref<{[questionID: iltypes.Guid]: undefined | Reflike<QuestionAnswer>}>({})

    /**
     * This watcher should never run after the first (because we're immediate), right?
     * Who would be changing existing answers while the user edits the form?
     * (yeah, users can edit current answers, not existing; and this runs in response to existingAnswers being mutated)
     * Maybe a route change that recycles the component could trigger this?
     */
    watch(() => props.existingAnswers, () => {
      const fresh = buildAnswersByQuestionID(questions.value.map(v => v.containedItem), props.existingAnswers);
      for (const key of Object.keys(fresh)) {
        const revisedAnswerPriorToWatchUpdate = answers.value[key]
        if (revisedAnswerPriorToWatchUpdate === undefined) {
          // no-op, no such answer
        }
        else {
          fresh[key] = revisedAnswerPriorToWatchUpdate;
        }
      }
      answers.value = fresh;
    }, {immediate:true})

    const pointsByQuestionIdByAnswer = computed(() => buildPointsByQuestionIdByAnswer(questions.value.map(v => v.containedItem), props.existingAnswers));

    const aggregatedPointsIfShowingPoints = computed<number | null>(() => {
      if (!props.doShowPoints) {
        return null;
      }

      let sum = 0;

      for (const q of questions.value) {
        const a = answers.value[q.questionID]?.value;
        const pointsByByAnswer = pointsByQuestionIdByAnswer.value[q.questionID];
        if (a === undefined || pointsByByAnswer === undefined) {
          continue;
        }
        sum += pointsForAnswer(a, pointsByByAnswer);
      }

      return sum;
    })

    const handleSubmit = () : void => {
      const defensiveCopy = (() => {
        const result : QuestionAnswerMap = {}
        for (const key of Object.keys(answers.value)) {
          if (props.readonlyQuestionIDs?.has(key) ?? false) {
            // skip readonly questions
            continue;
          }
          result[key] = answers.value[key]!.value;
        }
        return result;
      })()

      emit("submit", defensiveCopy);
    }

    return () => (
      <div data-test="TournamentTeamRegistrationQuestions" data-test-noSubmit={props.doHideSubmitButton ? "1" : "0"}>
        <FormKit type="form" actions={false} onSubmit={handleSubmit}>
          {
            props.pageItems.length === 0
              ? <div>No custom tournament registration form content.</div>
              : null
          }
          {
            props.pageItems.map(pageItem => {
              const maybeAnswer = isQuestionPageItem(pageItem) ? answers.value[pageItem.questionID] : undefined;
              return <PageItemDisplay
                key={pageItem.id}
                pageItem={pageItem}
                mut_answerIfPageItemIsQuestion={maybeAnswer}
                pointsByQuestionIdByAnswer={pointsByQuestionIdByAnswer.value}
                readonlyQuestionIDs={props.readonlyQuestionIDs ?? new Set()}
                doShowPoints={props.doShowPoints ?? false}
              />
            })
          }
          {
            aggregatedPointsIfShowingPoints.value !== null
              ? <div class="text-sm my-2">Total points: {aggregatedPointsIfShowingPoints.value}</div>
              : null
          }
          {
            props.doHideSubmitButton
              ? null
              : <t-btn type="submit">{props.submitLabel}</t-btn>
          }
        </FormKit>
      </div>
    )
  }
})

const PageItemDisplay = defineComponent({
  props: {
    pageItem: vReqT<iltournament.TournTeamRegPageItem>(),
    mut_answerIfPageItemIsQuestion: vOptT<Reflike<QuestionAnswer>>(),
    pointsByQuestionIdByAnswer: vReqT<{[questionID: iltypes.Guid]: undefined | {[answer: string]: number | undefined}}>(),
    readonlyQuestionIDs: vReqT<Set<iltypes.Guid>>(),
    doShowPoints: vReqT<boolean>(),
  },
  setup(props) {
    return () => {
      switch (props.pageItem.type) {
        case iltypes.PageItemType.QUESTION: {
          if (!props.mut_answerIfPageItemIsQuestion) {
            throw "mut_answerIfPageItemIsQuestion must be defined if pageItem type is question";
          }
          const pointsByAnswer = props.pointsByQuestionIdByAnswer[props.pageItem.questionID]
          return <Question
            pageItem={props.pageItem}
            mut_answer={props.mut_answerIfPageItemIsQuestion}
            readonly={props.readonlyQuestionIDs.has(props.pageItem.questionID) ?? false}
            pointsDetail={props.doShowPoints && pointsByAnswer ? {doShowPoints: true, pointsByAnswer} : {doShowPoints: false}}
          />
        }
        case iltypes.PageItemType.CONTENT_CHUNK: {
          return <Content pageItem={props.pageItem} />
        }
        default: exhaustiveCaseGuard(props.pageItem);
      }
    }
  }
})
