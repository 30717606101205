<template lang="pug">
form.mt-2(v-if="ready")
  .bg-gray-200.px-2.py-1.rounded-md
    .text-lg.leading-6.font-medium.text-gray-800
      | {{ config[`pos${referee.slotNum}Name`] }}:
      span.leading-6.text-gray-900.my-2.italic.font-normal.ml-2.text-base(
        data-cy='refName'
      )
        | {{ refName ? refName : 'Open' }}
  .mt-6.z-50.autocomplete(v-if='refDetails.assignID')
    TAutoSearchInput(
      :modelValue='searchValue',
      @input='e => handleAssignID(e)',
      @assignID='setAssignID',
      :hasSearched='hasSearched',
      type='autocomplete',
      name='assignID',
      placeholder='Assign Referee',
      :options='refOptions',
      data-test='user-name-input'
    )
  .-my-2.z-10(v-if='Object.keys(refDetails)')
    FormKit(
      v-model="refDetails.confirmed"
      :disabled='disableRadio',
      type='radio',
      name='confirmed',
      :options='[{label: "Confirmed", value: true}, {label: "Pending", value: false}]'
      data-cy='confirmationStatus',
      fieldset-class ='$reset my-6',
    )
  .-my-1
    FormKit(
      v-model='refDetails.sendEmail',
      type='checkbox',
      label='Send Confirmation Email',
      name='sendEmail',
      data-cy='sendEmail'
    )
  div
    .my-1(v-if='refName')
      FormKit(
        v-model='refDetails.removeRef',
        type='checkbox',
        :label="'Remove ' + `${refName}`",
        name='removeRef',
        data-cy='removeRef'
      )
    div(v-if='refDetails.removeRef')
      .-my-1
        FormKit.z-40(
          type='select',
          name='removeReason',
          v-model='refDetails.removeReason',
          :options='cancellationReasons',
          data-cy='removeReason'
        )
      .-my-1
        FormKit(
          v-model='refDetails.sendCancelEmail',
          type='checkbox',
          label='Send Cancellation Email',
          name='sendCancelEmail',
          data-cy='sendCancelEmail'
        )
    .-my-2
      FormKit(
        v-model='refDetails.locked',
        type='checkbox',
        label='Lock Out Slot',
        name='locked',
        data-cy='locked'
      )
</template>
  <script lang="ts">
  import {
    axiosAuthBackgroundInstance,
    axiosBackgroundInstance,
  } from 'src/boot/axios'
  import { AssignIdDetails } from 'src/interfaces/referee'
  import { RefSlotConfig } from 'src/interfaces/Store/client'
  import {
    defineComponent,
    ref,
    PropType,
    watch,
    onMounted,
    Ref,
    computed,
  } from 'vue'
  interface SlotDetails {
    slotNum: number
    assignID: { FirstName: string; LastName: string; ID?: string }
    confirmed?: boolean
    locked: boolean
    sendEmail?: boolean
    sendCancelEmail?: boolean
    removeRef?: boolean
  }
  import TAutoSearchInput from '../UserInterface/t-autoSearchInput.vue'
import { copyViaJsonRoundTrip, isObject } from 'src/helpers/utils'
  export default defineComponent({
    name: "RefereeSlot",
    props: {
        referee: { type: Object as PropType<SlotDetails> },
        originalRef: {
            type: Object as PropType<{
                FirstName: string;
                LastName: string;
                ID?: string;
            }>,
        },
        refName: {
            type: String,
        },
        config: {
            type: Object as PropType<RefSlotConfig>,
        },
    },
    emits: ["input"],
    setup(props) {
        const ready = ref(false);
        const cancellationReasons = ref({
            0: "Reported as No Show",
            1: "Requested by Referee",
            2: "Removed by Scheduler",
        });
        const refDetails = ref({}) as Ref<SlotDetails>;
        const refOptions = ref([]) as Ref<string[]>;
        const oldAssignID = ref({
            FirstName: "",
            LastName: "",
            ID: "",
        });
        const hasSearched = ref(false);
        const searchValue = ref({}) as Ref<{
            displayValue: string;
            ID: string;
        }>;
        const focused = ref(false);
        const updatedSearchValue = ref({ displayValue: "", ID: "" });
        const disableRadio = computed(() => {
            if (typeof refDetails.value.assignID === "string" &&
                (refDetails.value.assignID as string).length > 1) {
                return undefined;
            }
            else if (typeof refDetails.value.assignID === "object" &&
                (refDetails.value.assignID as {
                    FirstName: string;
                    LastName: string;
                    ID: string;
                }).ID.length > 1) {
                return undefined;
            }
            else {
                return true;
            }
        });
        const search = async (searchParam: string) => {
            // console.log('searchParam', searchParam)
            if (searchParam && searchParam.length >= 3) {
                try {
                    const response = await axiosAuthBackgroundInstance.get(`v1/volunteers/search?search=${searchParam}&activeOnly=false&activeRiskStatus=true`);
                    return response.data.data;
                }
                catch (err) {
                    // console.error(err)
                }
            }
            else {
                return [];
            }
        };
        const updateRefName = (userInput: {
            displayValue: string | string[];
            ID?: string;
        }) => {
            // console.log('in updateRefName', userInput);
            ;
            (userInput.displayValue as string[]) = (userInput.displayValue as string).split(" ");
            if (userInput.displayValue.length > 1 && userInput.ID) {
                refDetails.value.assignID.FirstName = userInput.displayValue[0];
                refDetails.value.assignID.LastName = (userInput.displayValue.slice(1) as string[]).join(" ");
                refDetails.value.assignID.ID = userInput?.ID;
            }
            else if (userInput.displayValue.length > 1) {
                refDetails.value.assignID.FirstName = userInput.displayValue[0];
                refDetails.value.assignID.LastName = (userInput.displayValue.slice(1) as string[]).join(" ");
            }
            else if (userInput.displayValue.length === 1) {
                refDetails.value.assignID.FirstName = userInput.displayValue[0];
                refDetails.value.assignID.LastName = "";
            }
            else {
                refDetails.value.assignID.FirstName = "";
                refDetails.value.assignID.LastName = "";
            }
        };
        const toggleHasSearched = (bool: boolean) => {
            hasSearched.value = bool;
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleAssignID = async (userInput: any) => {
            // console.log('handleAssignID', userInput)
            toggleHasSearched(false);
            if (userInput.ID) {
                searchValue.value.displayValue = userInput.displayValue;
                searchValue.value.ID = userInput.ID;
            }
            else if (typeof userInput === "object") {
                searchValue.value.displayValue = userInput.displayValue;
                refOptions.value = await search(userInput.displayValue);
            }
            else if (!userInput.ID) {
                searchValue.value.displayValue = userInput;
                // console.log('string')
                refOptions.value = await search(userInput);
            }
            updateRefName(userInput);
            toggleHasSearched(true);
        };
        const setAssignID = (ID: string) => {
            ;
            (refDetails.value.assignID as {
                FirstName: string;
                LastName: string;
                ID: string;
            }).ID = ID;
            searchValue.value.ID = ID;
        };
        watch(() => refDetails.value.removeRef, (newVal: boolean | undefined, oldVal: boolean | undefined) => {
            if (newVal &&
                !oldVal &&
                refDetails.value.assignID?.ID === props?.originalRef?.ID) {
                refDetails.value.assignID = { FirstName: "", LastName: "", ID: "" };
            }
        });
        watch(() => refDetails.value.assignID?.ID, val => {
            // console.log('watcher for assignID triggered')
            if (props.originalRef?.ID && props.originalRef?.ID != val)
                refDetails.value.removeRef = true;
            // console.log('removeRef', refDetails.value.removeRef)
        });

        watch(() => isObject(refDetails.value.assignID) ? copyViaJsonRoundTrip(refDetails.value.assignID) : null, (newVal, oldVal) => {
            if (newVal &&
                oldVal &&
                newVal.ID &&
                oldVal.ID &&
                (newVal.FirstName != oldVal.FirstName ||
                    newVal.LastName != oldVal.LastName)) {
                refDetails.value.assignID.ID = "";
                searchValue.value.ID = "";
                // console.log('cleared refDetails.assingID.ID')
            }
        }, { deep: true });
        watch(updatedSearchValue, async (newVal: any) => {
            toggleHasSearched(false);
            let name: string[] = [];
            if (typeof newVal === "object") {
                searchValue.value.displayValue = newVal;
                name = newVal.split(" ");
                // console.log('event')
                refOptions.value = await search(newVal);
            }
            else {
                searchValue.value.displayValue = newVal;
                name = newVal.split(" ");
                // console.log('string')
                refOptions.value = await search(newVal);
            }
            toggleHasSearched(true);
            updateRefName({ displayValue: name });
        });
        onMounted(() => {
            if (props.referee) {
                refDetails.value = props.referee;
            }
            if (refDetails.value.assignID.FirstName) {
                searchValue.value.displayValue = `${refDetails.value.assignID.FirstName} ${refDetails.value.assignID.LastName}`;
            }
            else {
                searchValue.value.displayValue = "";
            }
            refDetails.value["sendEmail"] = false;
            ready.value = true;
        });
        return {
            ready,
            cancellationReasons,
            refDetails,
            refOptions,
            disableRadio,
            oldAssignID,
            handleAssignID,
            setAssignID,
            hasSearched,
            searchValue,
            focused,
        };
    },
    components: { TAutoSearchInput }
})
  </script>
  <style scoped>
  .autocomplete:focus-within {
    position: relative;
  }
  </style>
